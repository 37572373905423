import React, { useState, useEffect, useRef } from "react";
import "../Assets/components.css";
import { DatePicker } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import Selects from "react-select";
// import { Table } from 'antd';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Button,
} from "@mui/material";

import axios from "axios";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import customSearchIcon from "../Assets/icon.png";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined"; // Import the ExpandMore icon
import Navbar from "./Navbar";
import Dashboard from "./Dashboard";
import { Link } from "react-router-dom";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import chart from "../Assets/chart.png";
import FilterListIcon from "@mui/icons-material/FilterList";
import {
  DialogTitle,
  DialogContent,
  Dialog,
  DialogActions,
} from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import Leftarrow from "../Assets/Arrow 1.png";
import closeIcon from "../Assets/Closeicons.png";
import { useParams } from "react-router-dom";

import Pagination from "@mui/material/Pagination";
function Devicestatusreport() {
  const menuRef = useRef(null);
  const [menuLoaded, setMenuLoaded] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isDataEmpty, setIsDataEmpty] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [dealerSelect, setDealerSelect] = useState(null);
  const [regionnamestwo, setRegionNamestwo] = useState([]);
  const [zoneNamestwo, setZoneNamestwo] = useState([]);

  // for mui popup
  useEffect(() => {
    if (menuRef.current && !menuLoaded) {
      // Scroll to the top only if the menu is loaded for the first time
      menuRef.current.scrollTop = 0;
      setMenuLoaded(true);
    }
  }, [menuLoaded]);

  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const location = useLocation();

  const [totalPages, setTotalPages] = useState();

  // Calculate the total number of pages whenever tableData changes

  const typeNames = [
    "3D Configurator",
    "Kiosk",
    "Centralized Waiting Period Display",
  ];

  const [showapplybtn, setShowApplyBtn] = useState(false);

  const allContentnames = ["Select All", "Pending", "Updated"];
  const allContentnamesTwo = [
    "Select All",
  
    "3D Configurator",
    "Kiosk",
    "Centralized Waiting Period Display",
  ];

  const [selectedContentwo, setContentStatustwo] = useState(
    allContentnamesTwo.slice(1)
  );
  const [selecteedContentFinal, setSelectedFinal] = useState(
    allContentnamesTwo.slice(1)
  ); // Select all options except "Select All"
  const [formattedSelectedContentOptions, setFormattedSelectedContentOptions] =
    useState([]);
  const [
    formattedSelectedContentOptionstwo,
    setFormattedSelectedContentOptionstwo,
  ] = useState([]);

  const ActivityStatus = ["Select All", "Online", "Offline"];
  const contentupdate = ["Select All", "pending", "up to date"];
  const appUpdate = ["Select All", "pending", "up to date"];

  const allStatusnames = ["Select All", "Online", "Offline"];
  const [selectedStatus, setSelectedStatus] = useState(allStatusnames.slice(1));
  const [formattedSelectedStatusOptions, setFormattedSelectedStatusOptions] =
    useState(["online", "offline"]);
  const [selectedStatusForFiltering, setSelectedStatusForFiltering] =
    useState(selectedStatus);


  const [fetchingDetails, setFetchingDetails] = useState(false);

  const [isMobile, setIsMobile] = useState(false);
  const [internalSelectedType, setInternalSelectedType] =
    useState("3D Configurator");
  const [contentsync, setcontentsync] = useState(contentupdate.slice(0));
  const [appupdatesync, setappUpdatesync] = useState(appUpdate.slice(0));
  const [searchValue, setSearchValue] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [applyClicked, setApplyClicked] = useState(false);

  const [tableData, setTableData] = useState(
    location.state?.dealerTableData || []
  );
  const [orginalData, setOrginalData] = useState([]);
  const [pageSize, setPageSize] = useState(10);

  // Default number of items per page
  const [totalItems, setTotalItems] = useState(0);

  //  new one

  const [allregionZoneData, setAllRegionZoneData] = useState([]);
  const [allregionnames, setAllRegionNames] = useState([]);
  const [allzonenames, setAllZoneNames] = useState([]);
  const [selectedRegions, setSelectedRegions] = useState([]);
  const [selectedRegionsTwo, setSelectedRegionTwo] = useState([]);
  const [selectedZone, setSelectedZone] = useState([]);
  const [selectedZoneTwo, setSelecteedZoneTwo] = useState([]);
  const [showZoneSelect, setShowZoneSelect] = useState(true); // New state
  const [responseData, setResponseData] = useState(null);
  const [fetchdetaile, setfetchDetails] = useState(true);
  const [cardsection, setCardsection] = useState(false);
  const [dealerCodes, setDealerCodes] = useState([]);

  const allAppnames = ["Select All", "Updated", "Outdated"];
  const [selectedAppUpdate, setAppStatusUpdate] = useState(
    allAppnames.slice(1)
  ); // Select all options except "Select All"
  const [formattedSelectedAppOptions, setFormattedSelectedAppOptions] =
    useState([]);
  const [apiResponse, setApiResponse] = useState(null);
  const [allZones, setAllZones] = useState([]);
  const [newResponsetwo, setNewResppnseTwo] = useState(null);

  // paginaation
  const [currentPage, setCurrentPage] = useState(1);

  const [recordsPerPage, setrecordsPerPage] = useState(); // Set the number of records per page
  const [totalRecords, setTotalRecords] = useState();

  const [deviceType, setdeviceType] = useState();

  const downloaadreport = "True";
  const currentPagetwo = 2;

  // for region

  const [accordionExpandeds, setAccordionExpanded] = useState(false);
  const [ZoneAccordianExpanded, setZoneAccordianExpanded] = useState(false);
  const [regionAccordionExpanded, setRegionAccordionExpanded] = useState(false);
  const [StatusAccordionExpanded, setStatusAccordionExpanded] = useState(false);
  const [ContentAccordionExpanded, setContentAccordionExpanded] =
    useState(false);
  const [AppAccordionExpanded, setAppAccordionExpanded] = useState(false);

  const [sset, setSeT] = useState(
    allStatusnames.slice(1).map((option) => option.toLowerCase())
  );

  const [Cset, setCset] = useState(
    allContentnames.slice(1).map((option) => option.toLowerCase())
  );
  const [Aset, setAset] = useState(
    allAppnames.slice(1).map((option) => option.toLowerCase())
  );

  const { deviceTypeTwo } = useParams();
  const { field } = useParams();
  const [selectedContent, setContentStatus] = useState(
    allContentnames.slice(1)
  );
  const { deviceTypeThree } = useParams();

  console.log("dtypetwo", deviceTypeTwo);
  console.log("dtypethree", deviceTypeThree);
  const zones = localStorage.getItem("selectedZone");

  useEffect(() => {
    const dealerApi = `https://digilens.myhyundai.co.in/digi_lens/dashboard_api/get_all_dealer/?username=${storedUsername}&permission=${JSON.stringify(
      storedPermission
    )}&device_type_array=${JSON.stringify(selectedContentwo)}`;
    console.log("Dealer API URL:", dealerApi);

    axios
      .get(dealerApi)
      .then((response) => {
        console.log("dealerResponsefordata", response);
        const codes = response.data.map((dealer) => ({
          value: dealer.dealer_code,
          label: `${dealer.dealer_code} (${dealer.dealer_name})`,
        }));
        setDealerCodes(codes);
        console.log("codes", codes);
      })
      .catch((error) => {
        console.error("Error fetching dealer codes:", error);
      });
  }, []);


  useEffect(() => {
    console.log("deviceTypeTwo from useParams:", deviceTypeTwo); // Log the value to the console

    // Check if field is not defined
    if (!field) {
      if (deviceTypeTwo) {
        console.log("Setting selectedStatus to:", [deviceTypeTwo]);
        setSelectedStatus([deviceTypeTwo]);
        setSelectedStatusForFiltering([deviceTypeTwo]);
        setFormattedSelectedStatusOptions([deviceTypeTwo.toLowerCase()]);
        setFormattedSelectedContentOptions(
          allContentnames.slice(1).map((option) => option.toLowerCase())
        );
        setFormattedSelectedAppOptions(
          allAppnames.slice(1).map((option) => option.toLowerCase())
        );
      } else {
        console.log("deviceTypeTwo is undefined or empty");
        setFormattedSelectedStatusOptions(
          allStatusnames.slice(1).map((option) => option.toLowerCase())
        );
        setFormattedSelectedContentOptions(
          allContentnames.slice(1).map((option) => option.toLowerCase())
        );
        setFormattedSelectedAppOptions(
          allAppnames.slice(1).map((option) => option.toLowerCase())
        );
      }
    } else {
      // Existing logic for when field is defined
      if (field === "status") {
        if (deviceTypeTwo) {
          console.log("Setting selectedStatus to:", [deviceTypeTwo]);
          setSelectedStatus([deviceTypeTwo]);
          setSelectedStatusForFiltering([deviceTypeTwo]);

          setFormattedSelectedStatusOptions([deviceTypeTwo.toLowerCase()]);
          setFormattedSelectedContentOptions(
            allContentnames.slice(1).map((option) => option.toLowerCase())
          );
          setFormattedSelectedAppOptions(
            allAppnames.slice(1).map((option) => option.toLowerCase())
          );
        } else {
          console.log("deviceTypeTwo is undefined or empty");
          setFormattedSelectedStatusOptions(
            allStatusnames.slice(1).map((option) => option.toLowerCase())
          );
        }
      } else if (field === "contentStatus") {
        if (deviceTypeTwo) {
          setFormattedSelectedContentOptions([deviceTypeTwo.toLowerCase()]);
          setContentStatus([deviceTypeTwo]);
          console.log("Pending state updated");
          setFormattedSelectedStatusOptions(
            allStatusnames.slice(1).map((option) => option.toLowerCase())
          );
          setFormattedSelectedAppOptions(
            allAppnames.slice(1).map((option) => option.toLowerCase())
          );
        } else {
          console.log("deviceTypeTwo is undefined or empty for content");
          setFormattedSelectedContentOptions(
            allContentnames.slice(1).map((option) => option.toLowerCase())
          );
        }
      } else {
        if (deviceTypeTwo) {
          setAppStatusUpdate([deviceTypeTwo]);
          setFormattedSelectedAppOptions([deviceTypeTwo.toLowerCase()]);
          setFormattedSelectedContentOptions(
            allContentnames.slice(1).map((option) => option.toLowerCase())
          );
          console.log("app status updated");
        } else {
          console.log("deviceTypeTwo is undefined or empty");
          setFormattedSelectedAppOptions(
            allAppnames.slice(1).map((option) => option.toLowerCase())
          );
        }
      }
    }
  }, []);

  console.log("FormattedSelectedStatusOptions", formattedSelectedStatusOptions);

 





  // Function to toggle accordion state
  const toggleRegionAccordion = () => {
    setRegionAccordionExpanded(!regionAccordionExpanded);
    setZoneAccordianExpanded(false);
    setAccordionExpanded(false);
    setStatusAccordionExpanded(false);
    setContentAccordionExpanded(false);
  };

  const handleDeviceAccordianChange = () => {
    setSearchValue("");
    setAccordionExpanded(!accordionExpandeds);
    setRegionAccordionExpanded(false);
    setZoneAccordianExpanded(false);
    setStatusAccordionExpanded(false);
    setContentAccordionExpanded(false);
  };

  const handlezoneAccordianChange = () => {
    setSearchValue("");
    setZoneAccordianExpanded(!ZoneAccordianExpanded);
    setAccordionExpanded(false);
    setRegionAccordionExpanded(false);
    setStatusAccordionExpanded(false);
    setContentAccordionExpanded(false);
  };

  const handleStatusAccordianChange = () => {
    setSearchValue("");
    setStatusAccordionExpanded(!StatusAccordionExpanded);
    setZoneAccordianExpanded(false);
    setAccordionExpanded(false);
    setRegionAccordionExpanded(false);
    setContentAccordionExpanded(false);
  };

  const handleContentAccordianChange = () => {
    setSearchValue("");
    setStatusAccordionExpanded(false);
    setZoneAccordianExpanded(false);
    setAccordionExpanded(false);
    setRegionAccordionExpanded(false);
    setContentAccordionExpanded(!ContentAccordionExpanded);
  };

  const handleAppAccordianChange = () => {
    setSearchValue("");
    setStatusAccordionExpanded(false);
    setZoneAccordianExpanded(false);
    setAccordionExpanded(false);
    setRegionAccordionExpanded(false);
    setContentAccordionExpanded(false);
    setAppAccordionExpanded(!AppAccordionExpanded);
  };

  const handleFilterClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 769);
    };

    // Initial check for screen size
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const storedPermission = JSON.parse(localStorage.getItem("permission"));
  const storedUsername = localStorage.getItem("username");

  useEffect(() => {
    const zones = JSON.parse(localStorage.getItem("selectedZone"));
if(zones){
  setAllZones(zones)
}
    callRegionApi();
  }, []);

  const callRegionApi = () => {
    const zones = JSON.parse(localStorage.getItem("selectedZone"));
    const regions = JSON.parse(localStorage.getItem("selectedRegions"));
    const content = JSON.parse(localStorage.getItem("selectedContent"));
    if (content) {
      setContentStatustwo(content);
    } else setContentStatustwo(allContentnamesTwo.slice(1));
    const regionApi = `https://digilens.myhyundai.co.in/digi_lens/dashboard_api/get_region_zone_list/?username=${storedUsername}&permission=${JSON.stringify(
      storedPermission
    )}`;
    console.log("newRegionApi", regionApi);

    axios
      .get(regionApi)
      .then((response) => {
        console.log("line 379 triggered");
        console.log("Response from device status API:", response);
        setApiResponse(response.data);
        if (Array.isArray(response.data)) {
          // Handle response with array structure
          console.log("hi");
          const regionNames = response.data.map(
            (singleRegion) => Object.keys(singleRegion)[0]
          );
          setAllRegionNames(regionNames);
          setAllRegionZoneData(response.data);
          if (regions) {
            setSelectedRegions(regions);
          } else setSelectedRegions(regionNames);
          setRegionNamestwo(regionNames);

          // Filter zones for selected regions and flatten the array
          let newZoness = response.data.filter((ele) => 
            regions?.some(region => ele[region])
          );
          console.log(newZoness)
          const extractValues = (data) => {
            return data.flatMap(zone => Object.values(zone).flat());
          };
          let newZone = extractValues(newZoness)
          console.log(newZone)
          const allZones = response.data.flatMap(
            (regionData) => Object.values(regionData)[0]
          );
          console.log(allZones)

          if (zones) {
            setSelectedZone(zones);
            setAllZones(newZone)
          } else{ setSelectedZone(allZones);
           setAllZones(allZones);}
          setZoneNamestwo(allZones);
        } else if (typeof response.data === "object") {
          // Handle response with object structure
          const regionNames = Object.keys(response.data);
          setAllRegionNames(regionNames);
          setAllRegionZoneData(
            regionNames.map((regionName) => ({
              [regionName]: response.data[regionName],
            }))
          );

          // Set selected regions and corresponding zones
          
          setSelectedRegions(regions?regions:regionNames);
          setRegionNamestwo(regionNames);

          // Filter zones for selected regions and flatten the array
          const allZones = regionNames.flatMap(
            (regionName) => response.data[regionName]
          );
          console.log(allZones)
          setSelectedZone(zones?zones:allZones);
          setAllZones(allZones);
          setZoneNamestwo(allZones);
        } else {
          // Handle unexpected response structure
          console.error("Unexpected response structure:", response);
        }
      })
      .catch((error) => {
        console.error("Error fetching region and zone data:", error);
      });
  };


  console.log("currentpage", currentPage);
  console.log("record per page", recordsPerPage);

  // useeffect to fetch current data
  useEffect(() => {
    console.log("Line 490", formattedSelectedContentOptionstwo,);
    const datas = `https://digilens.myhyundai.co.in/digi_lens/dashboard_api/device_status_table_data/?username=${storedUsername}&permission=${JSON.stringify(
      storedPermission
    )}&device_type_array=${JSON.stringify(
      formattedSelectedContentOptionstwo
    )}&zone=${JSON.stringify(selectedRegions)}&region=${JSON.stringify(
      selectedZone
    )}&device_status=${JSON.stringify(
      formattedSelectedStatusOptions
    )}&content_update_status=${JSON.stringify(
      formattedSelectedContentOptions
    )}&app_update_status=${JSON.stringify(formattedSelectedAppOptions)}`;
    console.log("fetchdataa", datas);
    if(allZones?.length>0){
      axios
      .get(datas)
      .then((response) => {
        // setTableData(response.data.data);
        console.log("devicereport", response.data.data);
        console.log("record limit", response.data.meta);
        setrecordsPerPage(response.data.meta.record_limit_per_page);
        setCurrentPage(response.data.meta.current_page);
        setTotalPages(response.data.meta.total_pages);
        console.log("total_records", response.data.meta.total_records);
        console.log("totalrecordone", response.data.meta.record_limit_per_page);
        console.log("setdeviceTypeone", response.data.data);
        // setTotalRecords(response.data.meta.totalRecords); // Assuming the response contains the total number of records
      })
      .catch((error) => {
        console.error("Error device status report:", error);
      });
    }
   
  }, [storedUsername, allZones]);

  const fetchDatanew = () => {
    setLoading(true);
    const deviceTypeFromStorage = JSON.parse(
      localStorage.getItem("selectedContent")
    );
    console.log(
      "line 431",
      typeNames,
      formattedSelectedStatusOptions,
      formattedSelectedContentOptions,
      formattedSelectedAppOptions,
      deviceTypeFromStorage
    );
   
    const statusreportApi = `https://digilens.myhyundai.co.in/digi_lens/dashboard_api/device_status_table_data/?username=${storedUsername}&permission=${JSON.stringify(
      storedPermission
    )}&device_type_array=${JSON.stringify(
      deviceTypeFromStorage ? deviceTypeFromStorage : typeNames
    )}&zone=${JSON.stringify(selectedRegions)}&region=${JSON.stringify(
      selectedZone
    )}&device_status=${deviceTypeTwo==="Offline"?JSON.stringify([deviceTypeTwo.toLowerCase()]):JSON.stringify([
      "online",
      "offline",
    ])}&content_update_status=${JSON.stringify(
      formattedSelectedContentOptions
    )}&app_update_status=${JSON.stringify(
      formattedSelectedAppOptions
    )}&records_per_page=${recordsPerPage}&current_page=${currentPage}`;

    console.log("statusreportApi", statusreportApi);
    axios
      .get(statusreportApi)

      .then((response) => {
        console.log("devicereport", response.data.data);
        console.log("metadata", response.data.meta.total_records);
        console.log(
          "record_limit_per_page",
          response.data.meta.record_limit_per_page
        );
        setTableData(response.data.data);
        setOrginalData(response.data.data);
        setTotalRecords(response.data.meta.total_records);
        console.log("total_records", response.data.meta.total_records);
        // setrecordsPerPage(response.data.meta.record_limit_per_page)
        setTotalPages(response.data.meta.total_pages);
        console.log("tabledata", tableData);
        console.log("totalrecordtwo", response.data.meta.record_limit_per_page);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error device status report :", error);
      });
  };

  const fetchDtanewTwo = (newPage) => {
    const deviceTypeFromStorage = JSON.parse(
      localStorage.getItem("selectedContent")
    );

    const statusreportApi = `https://digilens.myhyundai.co.in/digi_lens/dashboard_api/device_status_table_data/?username=${storedUsername}&permission=${JSON.stringify(
      storedPermission
    )}&device_type_array=${JSON.stringify(
      deviceTypeFromStorage
        ? deviceTypeFromStorage
        : formattedSelectedContentOptionstwo
    )}&zone=${JSON.stringify(selectedRegions)}&region=${JSON.stringify(
      selectedZone
    )}&device_status=${JSON.stringify(
      formattedSelectedStatusOptions
    )}&content_update_status=${JSON.stringify(
      formattedSelectedContentOptions
    )}&app_update_status=${JSON.stringify(
      formattedSelectedAppOptions
    )}&records_per_page=${recordsPerPage}&current_page=${newPage?newPage:currentPage}`;

    console.log("statusreportApiTwo", statusreportApi);
    axios
      .get(statusreportApi)

      .then((response) => {
        console.log("devicereport", response.data.data);
        console.log("metadata", response.data.meta.total_records);
        console.log(
          "record_limit_per_page",
          response.data.meta.record_limit_per_page
        );
        setTableData(response.data.data);
        setOrginalData(response.data.data);
        setTotalRecords(response.data.meta.total_records);
        console.log("total_records", response.data.meta.total_records);
        // setrecordsPerPage(response.data.meta.record_limit_per_page)
        setTotalPages(response.data.meta.total_pages);
        console.log("tabledata", tableData);
        console.log("totalrecordtwo", response.data.meta.record_limit_per_page);
      })
      .catch((error) => {
        console.error("Error device status report two :", error);
      });
  };

  useEffect(() => {
    if (!showapplybtn) {
     if(allZones?.length>0 && recordsPerPage!==undefined){
      fetchDatanew();
     }
    }
  }, [
    formattedSelectedAppOptions,
    selectedRegions,
    selectedZone,
    formattedSelectedStatusOptions,
    formattedSelectedContentOptions,
    currentPage,
    recordsPerPage,
  ]);

//   useEffect(() => {
// if(allZones?.length>0 && recordsPerPage!==undefined)
//   fetchDtanewTwo();
//   }, [currentPage, recordsPerPage, allZones]);





  const handleTypeChange = (event) => {
    setSearchValue("");
    setfetchDetails(true);
    setCardsection(false);
    setFetchingDetails(false);
    setShowApplyBtn(true);
    setApplyClicked(false);

    const selectedType = event.target.value;
    if (selectedType === "Select All") {
      setInternalSelectedType(typeNames.slice(0));
    } else {
      setInternalSelectedType(selectedType);
    }
  };

  const handleTypeChanges = (value) => {
    setSearchValue("");
    setfetchDetails(true);
    setCardsection(false);
    setFetchingDetails(false);
    setShowApplyBtn(true);
    setApplyClicked(false);

    // Check if the clicked value is already selected
    const isSelected = internalSelectedType.includes(value);

    // Update the internalSelectedType state based on whether the value was selected or not
    // if (isSelected) {
    //   // Remove the value from the array if it was already selected
    //   setInternalSelectedType(internalSelectedType.filter(val => val !== value));
    // } else {
    // Add the value to the array if it was not selected
    setInternalSelectedType(value);
    setAccordionExpanded(false);
    // }
  };

  const handleRegionChange = (event, regionName) => {
    setSearchValue("");
    setfetchDetails(true);
    setShowZoneSelect(true);
    setCardsection(false);
    setShowApplyBtn(true);
    setApplyClicked(false);

    const { checked } = event.target;
    let updatedSelectedRegions = [...selectedRegions];

    console.log("line 443", regionName);

    // Initialize zoneArray and newRegions
    let zoneArray = [];
    let newRegions = [];

    if (regionName === "select-all") {
      // Handle "Select All" logic
      if (checked) {
        // Select all regions
        newRegions = allregionnames;
        if (Array.isArray(apiResponse)) {
          zoneArray = apiResponse.flatMap((obj) => Object.values(obj)[0]);
        } else if (typeof apiResponse === "object" && apiResponse !== null) {
          zoneArray = Object.values(apiResponse).flat();
        }
      } else {
        // Deselect all regions
        newRegions = [];
        zoneArray = [];
      }
    } else {
      // Handle individual region logic
      if (checked) {
        // Add the region
        newRegions = [...updatedSelectedRegions, regionName];
      } else {
        // Remove the region
        newRegions = updatedSelectedRegions.filter(
          (region) => region !== regionName
        );
      }

      if (Array.isArray(apiResponse)) {
        const filteredData = apiResponse.filter((obj) =>
          newRegions.includes(Object.keys(obj)[0])
        );
        zoneArray = filteredData.flatMap((obj) => Object.values(obj)[0]);
      } else if (typeof apiResponse === "object" && apiResponse !== null) {
        const filteredData = Object.keys(apiResponse).filter((key) =>
          newRegions.includes(key)
        );
        zoneArray = filteredData.flatMap((key) => apiResponse[key]);
      }
    }

    setSelectedRegions(newRegions);
    setSelectedZone(zoneArray);
    setAllZones(zoneArray); // Update allZones to contain only the zones of the selected regions
    setfetchDetails(false);

    console.log("Updated selection:", regionName, zoneArray, newRegions);
  };

  const handleSelectAll = () => {
    setSearchValue("");
    setfetchDetails(true);
    setCardsection(false);
    setFetchingDetails(false);
    setShowApplyBtn(true);
    setApplyClicked(false);

    if (selectedRegions.length === allregionnames.length) {
      setSelectedRegions([]);
      setSelectedZone([]);
    } else {
      setSelectedRegions(allregionnames);
      const allZones = allregionZoneData.flatMap(
        (regionData) => Object.values(regionData)[0]
      );
      setSelectedZone(allZones.flat());
    }
  };

  const handleRegionChanges = (event, regionName) => {
    setSearchValue("");
    setfetchDetails(true);
    setShowZoneSelect(true);
    setCardsection(false);
    setShowApplyBtn(true);
    setApplyClicked(false);

    setFetchingDetails(false);

    const { checked, value } = event.target;
    console.log("value in line 340", value);

    let zoneArray = [];

    if (Array.isArray(apiResponse)) {
      // Handle case when apiResponse is an array
      if (value.includes("select-all")) {
        // If 'select-all' is checked, include all zones
        zoneArray = apiResponse.flatMap((obj) => Object.values(obj)[0]).flat();
      } else {
        // If individual regions are selected, filter the data accordingly
        const filteredData = apiResponse.filter((obj) => {
          const key = Object.keys(obj)[0];
          return value.includes(key);
        });
        // Extract values (zones) associated with each selected region
        zoneArray = filteredData.flatMap((obj) => Object.values(obj)[0]);
      }
    } else if (typeof apiResponse === "object" && apiResponse !== null) {
      // Handle case when apiResponse is an object
      if (value.includes("select-all")) {
        // If 'select-all' is checked, include all zones
        zoneArray = Object.values(apiResponse).flat();
      } else {
        // If individual regions are selected, filter the data accordingly
        const filteredData = Object.keys(apiResponse).filter((key) =>
          value.includes(key)
        );
        // Extract values (zones) associated with each selected region
        zoneArray = filteredData.flatMap((key) => apiResponse[key]);
      }
    } else {
      // Handle unexpected response structure
      console.error("Unexpected apiResponse structure:", apiResponse);
      zoneArray = [];
    }

    console.log("line 338", value, zoneArray, selectedZone);

    // Update states accordingly
    setAllZones(zoneArray);
    setSelectedZone(zoneArray);

    // added menu props in select and added bottom 2 lines to remove scroll

    // const scrollTop = menuRef.current.scrollTop;
    // menuRef.current.scrollTop = scrollTop;

    const newSelectedRegions =
      typeof value === "string" ? value.split(",") : value;

    if (newSelectedRegions.includes("select-all")) {
      setSelectedRegions(
        selectedRegions.length === allregionnames.length ? [] : allregionnames
      );
    } else {
      setSelectedRegions(newSelectedRegions);
    }

    setShowZoneSelect(newSelectedRegions.length > 0);
  };

  const handleZoneChanges = (event) => {
    console.log("handle zone chaange triggered");
    setfetchDetails(true);
    setSearchValue("");
    setCardsection(false);
    setApplyClicked(false);
    console.log("line 406", event.target.value);
    setSelectedZone(event.target.value);

    setFetchingDetails(false);
    setShowApplyBtn(true);

    console.log("handle zone chaange triggered");
    setfetchDetails(true);
    setCardsection(false);
    console.log("line 406", event.target.value);
    setSelectedZone(event.target.value);

    setFetchingDetails(false);
    setShowApplyBtn(true);

    const { value } = event.target;
    const newSelectedZones =
      typeof value === "string" ? value.split(",") : value;

    // Filter zones based on selectedRegions
    const filteredZones = selectedRegions.flatMap((region) =>
      allregionZoneData.flatMap((regionData) => regionData[region] || [])
    );

    if (newSelectedZones.includes("select-all")) {
      console.log(
        value,
        selectedZone,
        filteredZones,
        selectedRegions,
        "line 597"
      );
      setSelectedZone(
        selectedZone.length === filteredZones.length ? [] : filteredZones
      );
    } else {
      setSelectedZone(newSelectedZones);
    }
  };

  const handleZoneChange = (event, zone) => {
    console.log("handle zone change triggered");
    setfetchDetails(true);
    setCardsection(false);
    setSearchValue("");
    setApplyClicked(false);

    const { value } = event.target;
    let newSelectedZones;

    const filteredZones = selectedRegions.flatMap((region) =>
      allregionZoneData.flatMap((regionData) => regionData[region] || [])
    );

    console.log("line 576", value, filteredZones, selectedZone);

    if (value === "select-all") {
      const allZones = allregionZoneData.flatMap((regionData) =>
        Object.values(regionData).flat()
      );
      newSelectedZones =
        selectedZone.length === filteredZones.length ? [] : filteredZones;
    } else {
      newSelectedZones = selectedZone.includes(zone)
        ? selectedZone.filter((selected) => selected !== zone)
        : [...selectedZone, zone];
    }

    setSelectedZone(newSelectedZones);
    setFetchingDetails(false);
    setShowApplyBtn(true);
  };

  const handlecontentChange = (event) => {
    setfetchDetails(true);
    setCardsection(false);
    setFetchingDetails(false);
    setShowApplyBtn(true);
    setApplyClicked(false);

    const selectedType = event.target.value;
    // setcontentsync(event.target.value);
    if (selectedType === "Select All") {
      // If "Select All" is selected, set all types to be selected
      setcontentsync(contentupdate.slice(0));
    } else {
      // If any other type is selected, update the selected type
      setcontentsync(selectedType);
    }
  };

  const handleupdateChange = (event) => {
    setFetchingDetails(false);
    setShowApplyBtn(true);
    setApplyClicked(false);

    const selectedType = event.target.value;
    // setcontentsync(event.target.value);
    if (selectedType === "Select All") {
      // If "Select All" is selected, set all types to be selected
      setappUpdatesync(appUpdate.slice(0));
    } else {
      // If any other type is selected, update the selected type
      setappUpdatesync(selectedType);
    }
  };

  

  // ----start of activity status code------

  const handleStatusChange = (event) => {
    setfetchDetails(true);
    setCardsection(false);
    setFetchingDetails(false);
    setSearchValue("");
    setShowApplyBtn(true);
    setApplyClicked(false);

    const { value } = event.target;

    if (value.includes("Select All")) {
      handleSelectStatus();
    } else {
      const statusValue = value.filter((val) => val !== "Select All");
      setSelectedStatus(statusValue);
      console.log("statusvalue", statusValue);
      setFormattedSelectedStatusOptions(
        statusValue.map((option) => option.toLowerCase())
      );
    }
  };

  const handleStatusChanges = (event, statusName) => {
    const isChecked = event.target.checked;
    let updatedSelectedStatus = [...selectedStatus];
    setSearchValue("");
    if (statusName === "Select All") {
      if (isChecked) {
        updatedSelectedStatus = allStatusnames.filter(
          (name) => name !== "Select All"
        );
      } else {
        updatedSelectedStatus = [];
      }
    } else {
      if (isChecked) {
        updatedSelectedStatus.push(statusName);
      } else {
        updatedSelectedStatus = updatedSelectedStatus.filter(
          (name) => name !== statusName
        );
      }
    }

    setSelectedStatus(updatedSelectedStatus);
    const statusValue = updatedSelectedStatus.filter(
      (val) => val !== "Select All"
    );
    const formattedStatusOptions = statusValue.map((option) =>
      option.toLowerCase()
    );
    setFormattedSelectedStatusOptions(formattedStatusOptions);

    setfetchDetails(true);
    setCardsection(false);
    setFetchingDetails(false);
    setShowApplyBtn(true);
    setApplyClicked(false);
   
  };

  const handleSelectStatus = () => {
    setfetchDetails(true);
    setCardsection(false);
    setFetchingDetails(false);
    setShowApplyBtn(true);
    setSearchValue("");
    setApplyClicked(false);

    if (selectedStatus.length === allStatusnames.length - 1) {
      setSelectedStatus([]);
      setFormattedSelectedStatusOptions([]);
    } else {
      const updatedStatusValue = allStatusnames.slice(1);
      setSelectedStatus(updatedStatusValue);
      setFormattedSelectedStatusOptions(
        updatedStatusValue.map((option) => option.toLowerCase())
      ); // Exclude "Select All"
    }
  };

  const areAllOptionsSelected = () => {
    return selectedStatus.length === allStatusnames.length - 1;
  };



  // Run only once when component mounts
  console.log("selectedStatus", selectedStatus);

  console.log(
    "formaattedSelectedStatusOptions",
    formattedSelectedStatusOptions
  );

  // --------end of activty status code------
  // ----start of content update code----
  const handleContentChange = (event) => {
    // Ensure that event.target.value contains the correct selected values
    const { value } = event.target;

    // Update the fetchDetails and cardsection states
    setfetchDetails(true);
    setCardsection(false);
    setFetchingDetails(false);
    setShowApplyBtn(true);
    setSearchValue("");
    setApplyClicked(false);

    // Check if 'Select All' is included in the selected values
    if (value.includes("Select All")) {
      // If 'Select All' is included, call handleSelectContent to handle the selection
      handleSelectContent();
    } else {
      // If 'Select All' is not included, update the selected content state and options
      const contentUpdatedValue = value.filter((val) => val !== "Select All");

      setContentStatus(contentUpdatedValue);
      setFormattedSelectedContentOptions(
        contentUpdatedValue.map((option) => option.toLowerCase())
      );
    }
  };

  const handleContentChangeTwo = (event) => {
    setfetchDetails(true);
    setCardsection(false);
    setFetchingDetails(false);
    setShowApplyBtn(true);
    setApplyClicked(false);

    const { value } = event.target;

    if (value.includes("Select All")) {
      handleSelectContents();
    } else {
      const contentupdatedValue = value.filter((val) => val !== "Select All");
      const lowercaseOptions = contentupdatedValue.map((option) =>
        option.toLowerCase()
      );
      setContentStatustwo(contentupdatedValue);
      setFormattedSelectedContentOptionstwo(lowercaseOptions);
    }
  };

  const handleSelectContents = () => {
    setfetchDetails(true);
    setCardsection(false);
    setFetchingDetails(false);
    setShowApplyBtn(true);
    setApplyClicked(false);

    if (selectedContentwo.length === allContentnamesTwo.length - 1) {
      setContentStatustwo([]);
      const lowercaseOptions = [];
      setFormattedSelectedContentOptionstwo(lowercaseOptions);
    } else {
      const ContupdatedValue = allContentnamesTwo.slice(1);
      const lowercaseOptions = ContupdatedValue.map((option) =>
        option.toLowerCase()
      ); // Convert to lowercase
      setContentStatustwo(ContupdatedValue);
      setFormattedSelectedContentOptionstwo(lowercaseOptions); // Set lowercase options
    }
  };

  const handleContentChanges = (event, statusName) => {
    const isChecked = event.target.checked;
    let updatedSelectedContent = [...selectedContent];
    setSearchValue("");

    if (statusName === "Select All") {
      if (isChecked) {
        // Include all content names except 'Select All'
        updatedSelectedContent = allContentnames.filter(
          (name) => name !== "Select All"
        );
      } else {
        // Uncheck 'Select All' and clear other selections
        updatedSelectedContent = [];
      }
    } else {
      if (isChecked) {
        // Add the selected content status
        updatedSelectedContent.push(statusName);
      } else {
        // Remove the deselected content status
        updatedSelectedContent = updatedSelectedContent.filter(
          (name) => name !== statusName
        );

        // If 'Select All' is unchecked, ensure all other selections are cleared
        if (updatedSelectedContent.includes("Select All")) {
          updatedSelectedContent = [];
        }
      }
    }

    setContentStatus(updatedSelectedContent);

    // Update formattedSelectedContentOptions based on the updated selected content
    const contentValue = updatedSelectedContent.filter(
      (val) => val !== "Select All"
    );
    const formattedContentOptions = contentValue.map((option) =>
      option.toLowerCase()
    );
    setFormattedSelectedContentOptions(formattedContentOptions);

    // Update other state variables as needed
    setfetchDetails(true);
    setCardsection(false);
    setFetchingDetails(false);
    setShowApplyBtn(true);
    setApplyClicked(false);
  };

  const handleContentChangesTwo = (event) => {
    setfetchDetails(true);
    setCardsection(false);
    setFetchingDetails(false);
    setShowApplyBtn(true);
    setApplyClicked(false);

    const { value, checked } = event.target;

    if (value === "Select All") {
      handleSelectContents(checked);
    } else {
      // If "Select All" is checked, uncheck it
      const contentupdatedValue = selectedContentwo.includes("Select All")
        ? selectedContentwo.filter((val) => val !== "Select All")
        : selectedContentwo;

      // Update the selected content based on the checkbox status
      const updatedContent = checked
        ? [...contentupdatedValue, value]
        : contentupdatedValue.filter((val) => val !== value);

      // Convert updatedContent to lowercase
      const lowercaseOptions = updatedContent.map((option) =>
        option.toLowerCase()
      );

      // Update the state with the new selected content
      setContentStatustwo(updatedContent);
      setFormattedSelectedContentOptionstwo(lowercaseOptions);
    }
  };

  const handleSelectContent = () => {
    setfetchDetails(true);
    setCardsection(false);
    setFetchingDetails(false);
    setSearchValue("");
    setApplyClicked(false);

    if (selectedContent.length === allContentnames.length - 1) {
      setContentStatus([]);
      setFormattedSelectedContentOptions([]);
    } else {
      const ContupdatedValue = allContentnames.slice(1);
      setContentStatus(ContupdatedValue);

      // Convert to lowercase
      const lowerCaseContentOptions = ContupdatedValue.map((option) =>
        option.toLowerCase()
      );
      setFormattedSelectedContentOptions(lowerCaseContentOptions); // Exclude "Select All"
    }
  };

  const contentareAllOptionsSelected = () => {
    return selectedContent.length === allContentnames.length - 1;
  };



  useEffect(() => {
    const contentFromStorage = JSON.parse(localStorage.getItem("selectedContent"))
    if(contentFromStorage){
      setFormattedSelectedContentOptionstwo(contentFromStorage)   }
    else{
      setFormattedSelectedContentOptionstwo(
        allContentnamesTwo.slice(1).map((option) => option.toLowerCase())
      );
    }
        console.log("useefectcontent", formattedSelectedContentOptionstwo);
  }, []);

  console.log("selectedContent", formattedSelectedContentOptions);


  const handlAppChange = (event) => {
    setfetchDetails(true);
    setCardsection(false);
    setFetchingDetails(false);
    setShowApplyBtn(true);

    const { value } = event.target;

    if (value.includes("Select All")) {
      handleSelectApp();
    } else {
      const updatedValue = value.filter((val) => val !== "Select All");
      setAppStatusUpdate(updatedValue);
      setFormattedSelectedAppOptions(
        updatedValue.map((option) => option.toLowerCase())
      );
    }
  };

  const handlAppChanges = (event, statusName) => {
    const isChecked = event.target.checked;
    let updatedSelectedAppUpdate = [...selectedAppUpdate];

    if (statusName === "Select All") {
      if (isChecked) {
        // Include all app names except 'Select All'
        updatedSelectedAppUpdate = allAppnames.filter(
          (name) => name !== "Select All"
        );
      } else {
        // Uncheck 'Select All' and clear other selections
        updatedSelectedAppUpdate = [];
      }
    } else {
      if (isChecked) {
        // Add the selected app status
        updatedSelectedAppUpdate.push(statusName);
      } else {
        // Remove the deselected app status
        updatedSelectedAppUpdate = updatedSelectedAppUpdate.filter(
          (name) => name !== statusName
        );

        // If 'Select All' is unchecked, ensure all other selections are cleared
        if (updatedSelectedAppUpdate.includes("Select All")) {
          updatedSelectedAppUpdate = [];
        }
      }
    }

    setAppStatusUpdate(updatedSelectedAppUpdate);
    // Update formattedSelectedAppOptions based on the updated selected app update
    const appValue = updatedSelectedAppUpdate.filter(
      (val) => val !== "Select All"
    );
    const formattedAppOptions = appValue.map((option) => option.toLowerCase());
    setFormattedSelectedAppOptions(formattedAppOptions);

    // Update other state variables as needed
    setfetchDetails(true);
    setCardsection(false);
    setFetchingDetails(false);
    setShowApplyBtn(true);
  };

  const handleSelectApp = () => {
    setfetchDetails(true);
    setCardsection(false);
    setFetchingDetails(false);
    setShowApplyBtn(true);
    setSearchValue("");
    setApplyClicked(false);
    if (selectedAppUpdate.length === allAppnames.length - 1) {
      setAppStatusUpdate([]);
      setFormattedSelectedAppOptions([]);
    } else {
      const updatedValue = allAppnames.slice(1); // Exclude "Select All"
      setAppStatusUpdate(updatedValue);
      setFormattedSelectedAppOptions(
        updatedValue.map((option) => option.toLowerCase())
      );
    }
  };

  const AppareAllOptionsSelected = () => {
    return selectedAppUpdate.length === allAppnames.length - 1;
  };


  console.log("selectedAppUpdate", selectedAppUpdate);
  console.log("formattedSelectedAppOptions", formattedSelectedAppOptions);

  // ----end of app update------
  // ---console log for api data----

  console.log("devicestype", internalSelectedType);
  console.log("selectedRegions", selectedRegions);
  console.log("selectedZone", selectedZone);
  console.log("formattedSelectedStatusOptions", formattedSelectedStatusOptions);
  console.log(
    "formattedSelectedContentOptions",
    formattedSelectedContentOptions
  );
  console.log("formattedSelectedAppOptions", formattedSelectedAppOptions);
  // dummy table data

  //   // Add more data here if needed
  // ];

  const handleSearch = (e) => {
    const searchInput = e.target.value;
    setSearchValue(searchInput);

    // Check if the search input is not empty
    if (searchInput.trim() !== "") {
      axios
        .get(
          `https://digilens.myhyundai.co.in/digi_lens/dashboard_api/device_status_table_data/?username=${storedUsername}&permission=${JSON.stringify(
            storedPermission
          )}&device_type=${formattedSelectedContentOptionstwo}&zone=${JSON.stringify(
            selectedRegions
          )}&region=${JSON.stringify(
            selectedZone
          )}&device_status=${JSON.stringify(
            formattedSelectedStatusOptions
          )}&content_update_status=${JSON.stringify(
            formattedSelectedContentOptions
          )}&app_update_status=${JSON.stringify(
            formattedSelectedAppOptions
          )}&dealer_code=${searchInput}`
        )
        .then((response) => {
          console.log("new search", response.data);
          console.log("search data", response.data.data);
          const searchData = response.data.data;
          setIsDataEmpty(searchData.length === 0);
          // Update your state with the search results
          setTableData(response.data.data);
        })
        .catch((error) => {
          console.error("Error device status report:", error);
        });
    } else {
      // Handle the case where the search input is empty
      console.log("Search input is empty");
      // Reset your table data to the original data if needed
      setTableData(orginalData);
    }
  };

  const columns = [
    { title: "ID", dataIndex: "id", key: "id" },
    { title: "Dealer Code", dataIndex: "Dealercode", key: "Dealercode" },
    { title: "Dealer Name", dataIndex: "DealerName", key: "DealerName" },
    { title: "Device Name", dataIndex: "Device Name", key: "DeviceName" },
    { title: "Device No", dataIndex: "DeviceNo", key: "DeviceNo" },
    { title: "Status", dataIndex: "status", key: "status" },
    {
      title: "Content Update",
      dataIndex: "ContentUpdate",
      key: "ContentUpdate",
    },
    { title: "App Update", dataIndex: "AppUpdate", key: "AppUpdate" },
  ];

  // custom pagination

  const handleFetchdetails = () => {
    setTableData([])
    
    const contentFromStorage = JSON.parse(
      localStorage.getItem("selectedContent")
    );
    const regionFromStorage = JSON.parse(localStorage.getItem("selectedZone"));
    const zoneFromStorage = JSON.parse(localStorage.getItem("selectedRegions"));

    if (contentFromStorage && zoneFromStorage && regionFromStorage) {
      console.log(
        "line 1439",
        formattedSelectedContentOptionstwo,
        selectedRegions,
        selectedZone
      );
      localStorage.setItem(
        "selectedContent",
        JSON.stringify(formattedSelectedContentOptionstwo)
      );
      localStorage.setItem("selectedZone", JSON.stringify(selectedZone));
      localStorage.setItem("selectedRegions", JSON.stringify(selectedRegions));
    }
    setCurrentPage(1);
    setSelectedStatusForFiltering(selectedStatus);
    setfetchDetails(false);
    setCardsection(true);
    setFetchingDetails(true);
    setShowApplyBtn(true);
    fetchDtanewTwo(1);
    setApplyClicked(true);
  };

  const handleClearFilters = () => {
    setApplyClicked(false);
    setShowApplyBtn(false);
    callRegionApi();

    setContentStatustwo(allContentnamesTwo.slice(1));
    setSelectedRegions(allregionnames);
    setSelectedRegionTwo(allregionnames);
    setSelectedZone(allZones);
    setSelecteedZoneTwo(allZones);

    console.log("allContentnamesReport:", allContentnamesTwo.slice(1));
    console.log("allregionnamesReport:", allregionnames);
    console.log("allZonesReport:", allZones);
    console.log("setContentStatustwos", selectedContentwo);

    // setSelectedStatus
    setSelectedStatus(allStatusnames.slice(1));
    setContentStatus(allContentnames.slice(1));
    setAppStatusUpdate(allAppnames.slice(1));
    // setFormattedSelectedStatusOptionsTwo(
    //   allStatusnames.slice(1).map((option) => option.toLowerCase())
    // );
    setFormattedSelectedContentOptions(
      allContentnames.slice(1).map((option) => option.toLowerCase())
    );
    setFormattedSelectedAppOptions(
      allAppnames.slice(1).map((option) => option.toLowerCase())
    );
    setFormattedSelectedContentOptionstwo(
      typeNames.map((ele) => ele.toLowerCase())
    );
    console.log(
      "line 431",
      typeNames.map((ele) => ele.toLowerCase())
    );
    setSeT(allStatusnames.slice(1).map((option) => option.toLowerCase()));
    // setFormattedSelectedContentOptions(allContentnames.slice(1));
    // setFormattedSelectedAppOptions(allContentnames.slice(1));

    console.log("allStatusnames", Cset);
    setTableData([])
    const statusreportApi = `https://digilens.myhyundai.co.in/digi_lens/dashboard_api/device_status_table_data/?username=${storedUsername}&permission=${JSON.stringify(
      storedPermission
    )}&device_type_array=${JSON.stringify(
      selecteedContentFinal
    )}&zone=${JSON.stringify(regionnamestwo)}&region=${JSON.stringify(
      zoneNamestwo
    )}&device_status=${JSON.stringify(
      sset
    )}&content_update_status=${JSON.stringify(
      Cset
    )}&app_update_status=${JSON.stringify(
      Aset
    )}&records_per_page=${recordsPerPage}&current_page=${currentPage}`;

    console.log("clearFilterapi", statusreportApi);
    axios
      .get(statusreportApi)

      .then((response) => {
        console.log("devicereport", response.data.data);
        console.log("metadata", response.data.meta.total_records);
        console.log(
          "record_limit_per_page",
          response.data.meta.record_limit_per_page
        );
        setTableData(response.data.data);
        setOrginalData(response.data.data);
        setTotalRecords(response.data.meta.total_records);
        console.log("total_records", response.data.meta.total_records);
        // setrecordsPerPage(response.data.meta.record_limit_per_page)
        setTotalPages(response.data.meta.total_pages);
        console.log("tabledata", tableData);
        console.log("totalrecordtwo", response.data.meta.record_limit_per_page);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error device status report :", error);
      });
  };

  // pagination
  const handlePageChange = (event, newPage) => {
    console.log("cuerrentpa", currentPage);
    setCurrentPage(newPage)
       setTableData([])
      fetchDtanewTwo(newPage)
    // Increment current page by one when the next arrow is clicked
    // if (newPage === currentPage + 1) {
    //   setCurrentPage(currentPage + 1);
    //   setTableData([])
    //   fetchDtanewTwo()
    // } else {
    //   setCurrentPage(newPage);
      // setTableData([])

      // fetchDtanewTwo()
    }
  

  const handleFilterClicks = () => {
    setIsFilterOpen(true);
  };

  const handleCloseFilter = () => {
    setIsFilterOpen(false);
  };

 

  const DownloadReport = () => {
    // window.alert("downloaded")
    // Construct the API URL

    // Make the API call using Axios
    const deviceTypeFromStorage = JSON.parse(
      localStorage.getItem("selectedContent")
    )?.map((ele) => ele.toLowerCase());
    // http://hyundaianalytics.apprikart.com/digi_lens/dashboard_api/device_status_table_data/?username=${storedUsername}&permission=${JSON.stringify(storedPermission)}&device_type_array=${JSON.stringify(formattedSelectedContentOptionstwo)}&zone=${JSON.stringify(selectedRegions)}&region=${JSON.stringify(selectedZone)}&device_status=${JSON.stringify(formattedSelectedStatusOptions)}&content_update_status=${JSON.stringify(formattedSelectedContentOptions)}&app_update_status=${JSON.stringify(formattedSelectedAppOptions)}&records_per_page=${recordsPerPage}&current_page=${currentPage}
    const apiUrl = `https://digilens.myhyundai.co.in/digi_lens/dashboard_api/device_status_table_data/?username=${storedUsername}&permission=${JSON.stringify(
      storedPermission
    )}&device_type_array=${JSON.stringify(
      deviceTypeFromStorage
        ? deviceTypeFromStorage
        : formattedSelectedContentOptionstwo
    )}&zone=${JSON.stringify(selectedRegions)}&region=${JSON.stringify(
      selectedZone
    )}&device_status=${JSON.stringify(
      formattedSelectedStatusOptions
    )}&content_update_status=${JSON.stringify(
      formattedSelectedContentOptions
    )}&app_update_status=${JSON.stringify(
      formattedSelectedAppOptions
    )}&records_per_page=${recordsPerPage}&current_page=${currentPage}&download=${downloaadreport}`;
    axios
      .get(apiUrl, { responseType: "blob" }) // Set responseType to 'blob'
      .then((response) => {
        // Create a Blob from the response data
        const blob = new Blob([response.data], {
          type: response.headers["content-type"],
        });

        // Create a temporary URL for the Blob
        const url = window.URL.createObjectURL(blob);

        // Create an anchor element to trigger the download
        const link = document.createElement("a");
        link.href = url;
        link.download = "report.csv"; // Set the desired filename
        document.body.appendChild(link);

        // Trigger the download
        link.click();

        // Cleanup
        window.URL.revokeObjectURL(url);
        document.body.removeChild(link);
      })
      .catch((error) => {
        // Handle errors if any
        console.error("Error downloading report:", error);
      });
  };

  const [apiNewData, setApiNewData] = useState(null);
  const [openPopup, setOpenPopup] = useState(false);

  const handleOpenPopup = () => {
    setOpenPopup(true);
  };

  // Function to handle closing the popup
  const handleClosePopup = () => {
    setOpenPopup(false);
  };

  const [selectedDealerCode, setSelectedDealerCode] = useState();

  // for device type saaving

  const [deviceTypeSavingData, setDeviceTypeSavingData] = useState();

  function handleClick(device, dealerCode, deviceType) {
    // window.alert("changed")
    console.log("deviceclickedtype", device);
    console.log("DEALER CODE", dealerCode);
    setSelectedDealerCode(dealerCode);
    let storageValue;
    // Store the clicked dealer code in local storage
    if (device === "vertical") {
      storageValue = "Digital Kiosk";
    } else if (device === "horizontal") {
      storageValue = "3D Configurator";
    } else if (device === "central display") {
      storageValue = "Centralized Waiting Period Display";
    } else {
      // Default value if the device type is neither "vertical" nor "central display"
      storageValue = device;
    }
    // have to pass storage value
    console.log("storagevalue", storageValue);

    setDeviceTypeSavingData(storageValue);
    console.log("Dtype", storageValue);

    localStorage.setItem("DeviceType", storageValue);
    handleOpenPopup();
    fetchDataFromAPI(
      dealerCode,
      storageValue,
      storedUsername,
      storedPermission,
      deviceType
    );
  }

  console.log("selectedDealerCodeone", selectedDealerCode);
  console.log("deviceTypeSavingDataone", deviceTypeSavingData);



  const fetchDataFromAPI = async (
    dealerCode,
    deviceTypeSavingData,
    storedUsername,
    storedPermission,
    deviceType
  ) => {
    console.log(deviceType, dealerCode)
    let newDeviceType = "";
    if(deviceType==="Digital kiosk")
      newDeviceType = "Kiosk"
    else if (deviceType === "Centralized Display Monitor") newDeviceType = "Centralized Waiting Period Display";
    else
    newDeviceType = deviceType
    // if (deviceType === "central display")
    //   newDeviceType = "Centralized Waiting Period Display";
    // else if (deviceType === "horizontal") newDeviceType = "3d Configurator";
    // else newDeviceType = "Kiosk";

    try {
      const delaerUrl = `https://digilens.myhyundai.co.in/digi_lens/dashboard_api/get_device_details/?username=${storedUsername}&permission=${JSON.stringify(
        storedPermission
      )}&device_type=${newDeviceType}&dealer_code=${dealerCode}`;

      console.log("dealerUrl", delaerUrl);
      const response = await axios.get(delaerUrl);
      console.log("API Response:", response.data);
      console.log("detailpage response:", response.data);
      console.log("devicedataofdeatilpage", response.data.device_data);
      setDealersDetailsPage(response.data.device_data);
      console.log("dealersDetailPage", dealersDetailPage);
      console.log("deviceid", response.data.device_data.device);
      setDeviceid(response.data.device_data.device);
      console.log("dataResp", response.data.app_update_table);
      setNewAppupdate(response.data.app_update_table);
      setNewContentUpdate(response.data.content_update_table);
      setActivesta(response.data.device_data.device_status);
      console.log("status value", response.data.device_data.device_status);

      setDealerDetailId(response.data.device_data.dealer_code);
      setShowroomNamed(response.data.device_data.dealer_name);
      setLastcontDealerupdate(response.data.device_data.last_app_update);
      setDealercontupdate(response.data.device_data.content_update_status);
      setDealeZone(response.data.device_data.zone);
      setDealeRegion(response.data.device_data.region);
      setLastDealerAppUdate(response.data.device_data.last_app_update);
      setDealerAppUpda(response.data.device_data.app_update_status);

      setApiNewData(response.data);
    } catch (error) {
      console.error("Error fetching data from API:", error);
    }
  };


  const [activesta, setActivesta] = useState();
  const [DetailDealerId, setDealerDetailId] = useState();
  const [ShowroomName, setShowroomNamed] = useState();
  const [LastcontDealerupdate, setLastcontDealerupdate] = useState();
  const [Dealercontupdate, setDealercontupdate] = useState();
  const [DealeZone, setDealeZone] = useState();
  const [DealeRegion, setDealeRegion] = useState();
  const [LastDealerAppUdate, setLastDealerAppUdate] = useState();
  const [DealerAppUpda, setDealerAppUpda] = useState();

  const [setNewactiveTabs, setNewActiveTabs] = useState("tabone");

  const [dealersDetailPage, setDealersDetailsPage] = useState([]);
  const [deviceId, setDeviceid] = useState();
  const [NewappUpdate, setNewAppupdate] = useState([]);
  const [Newcontentupdate, setNewContentUpdate] = useState([]);

  const tabsection = [
    { id: "tabone", name: "Content update" },
    { id: "tabtwo", name: "App update" },
  ];

  const handleTabChange = (tab) => {
    setNewActiveTabs(tab);
  };

  const handleBack = () => {
    setOpenPopup(false);
  };

  const contentareAllOptionsSelectedtwo = () => {
    return selectedContentwo.length === allContentnamesTwo.length - 1;
  };
  const navigate = useNavigate();
  const handleBackClick = () => {
    navigate("/analytics");
  };

  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    setTableData([]);
    const selectedValue = selectedOption ? selectedOption.value : "";
    setDealerSelect(selectedValue);

    console.log("Option selected:", selectedOption);
    console.log("selectedDealers", selectedValue);

    const apiUrl = `https://digilens.myhyundai.co.in/digi_lens/dashboard_api/device_status_table_data/?username=${storedUsername}&permission=${JSON.stringify(
      storedPermission
    )}&device_type_array=${JSON.stringify(
      formattedSelectedContentOptionstwo
    )}&zone=${JSON.stringify(selectedRegions)}&region=${JSON.stringify(
      selectedZone
    )}&device_status=${JSON.stringify(
      formattedSelectedStatusOptions
    )}&content_update_status=${JSON.stringify(
      formattedSelectedContentOptions
    )}&app_update_status=${JSON.stringify(formattedSelectedAppOptions)}`;
    if (selectedValue !== "") {
      const searchApiUrl = `${apiUrl}&dealer_code=${selectedValue}`;
      console.log("API URLsearch:", searchApiUrl);

      axios
        .get(searchApiUrl)
        .then((response) => {
          console.log("devicereport", response.data.data);
          console.log("metadata", response.data.meta.total_records);
          console.log(
            "record_limit_per_page",
            response.data.meta.record_limit_per_page
          );
          setTableData(response.data.data);
          setOrginalData(response.data.data);
          setTotalRecords(response.data.meta.total_records);
          console.log("total_records", response.data.meta.total_records);
          // setrecordsPerPage(response.data.meta.record_limit_per_page)
          setTotalPages(response.data.meta.total_pages);
          console.log("tabledata", tableData);
          console.log(
            "totalrecordtwo",
            response.data.meta.record_limit_per_page
          );
          setLoading(false);
        })

        .catch((error) => {
          console.error("Error fetching usage dashboard data:", error);
        });
    } else {
      console.log("Fetching data without dealer filter");

      fetchDatanew();
      // axios.get(apiUrl)
      //   .then((response) => {
      //     console.log("responseFilter",response)
      //     setTableData(response.data.data);
      //     // updateStateWithData(response.data);
      //   })
      //   .catch((error) => {
      //     console.error('Error fetching original usage dashboard data:', error);
      //   });
    }
  };


  const customStyles = {
    control: (provided) => ({
      ...provided,
      cursor: 'pointer',
    }),
    option: (provided) => ({
      ...provided,
      cursor: 'pointer',
    }),
    singleValue: (provided) => ({
      ...provided,
      cursor: 'pointer',
    }),
    placeholder: (provided) => ({
      ...provided,
      cursor: 'pointer',
    }),
    clearIndicator: (provided) => ({
      ...provided,
      cursor: 'pointer',
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      cursor: 'pointer',
    }),
  };
  

  return (
    <>
      <Navbar />
      <Dashboard />

      {!isMobile ? (
        // desktop ui
        <>
          <div className="device-status-full-contain-section-statusreport">
            <div className="hyundai-content-section-statusreport">
              {/* ----------------------device type---------------- */}

              <div className="zone-flex-container-report">
                <div
                  className="zone-div-report"
                  style={{ marginBottom: "10px" }}
                >
                  Device Type
                </div>
                <FormControl>
                  <Select
                    style={{
                      fontFamily: "Hyundai Sans Head Office regular",
                      textAlign: "center",
                      fontSize: "15.711px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "normal",
                      backgroundColor: "white",
                      height: "40px",
                      overflow: "hidden", // Hide overflow
                      textOverflow: "ellipsis", // Truncate text with ellipsis
                      whiteSpace: "nowrap", // Prevent wrapping
                      width: "200px", // Set fixed width
                    }}
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    value={selectedContentwo}
                    onChange={handleContentChangeTwo}
                    renderValue={(selected) => {
                      if (contentareAllOptionsSelectedtwo()) {
                        return "Select All";
                      } else {
                        console.log("line 1104", selected);
                        let newSelected = selected.map((ele) => {
                          if (ele === "Kiosk") {
                            return "Digital Kiosk";
                          }
                          else if (ele === "Centralized Waiting Period Display") {
                            return "Centralized Display Monitor";
                          } 
                           else return ele;
                        });
                        return newSelected.join(", ");
                      }
                    }}
                  >
                    {allContentnamesTwo.map((regionName) => (
                      <MenuItem key={regionName} value={regionName}>
                        <Checkbox
                          checked={
                            selectedContentwo.includes(regionName) ||
                            (regionName === "Select All" &&
                              contentareAllOptionsSelectedtwo())
                          }
                        />
                        <ListItemText
                          primary={
                            regionName === "Kiosk"
                              ? "Digital Kiosk"
                                    : regionName === "Centralized Waiting Period Display"
                  ? "Centralized Display Monitor"

                              : regionName
                          }
                        />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>

              {/* ---------virtual kiosk */}
              {/* ----------------zone div -------- */}

              <div className="zone-flex-container">
                <div className="zone-div" style={{ marginBottom: "10px" }}>
                  Zone
                </div>
                <FormControl>
                  <Select
                    style={{
                      fontFamily: "Hyundai Sans Head Office regular",
                      textAlign: "center",
                      fontSize: "15.711px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "normal",
                      backgroundColor: "white",
                      height: "40px",
                      overflow: "hidden", // Hide overflow
                      textOverflow: "ellipsis", // Truncate text with ellipsis
                      whiteSpace: "nowrap", // Prevent wrapping
                      width: "200px", // Set fixed width
                    }}
                    multiple
                    value={selectedRegions}
                    onChange={handleRegionChanges}
                    MenuProps={{
                      MenuListProps: {
                        autoFocusItem: false,
                        ref: menuRef,
                      },
                      PaperProps: {
                        style: {
                          maxHeight: 300,
                          overflowY: "auto",
                        },
                      },
                    }}
                    renderValue={(selected) => {
                      if (
                        selected.length === 1 &&
                        selected[0] !== "select-all"
                      ) {
                        return selected[0]; // Render the single selected region name
                      } else if (selected.includes("select-all")) {
                        return "Select All";
                      } else if (
                        selected.length === allregionnames.length &&
                        allregionnames.length > 1
                      ) {
                        return "Select All";
                      } else {
                        return selected.join(", ");
                      }
                    }}
                  >
                    {allregionnames.length > 1 && (
                      <MenuItem key="select-all" value="select-all">
                        <Checkbox
                          checked={
                            selectedRegions.length === allregionnames.length
                          }
                        />
                        <ListItemText primary="Select All" />
                      </MenuItem>
                    )}

                    {allregionnames.map((regionName) => (
                      <MenuItem key={regionName} value={regionName}>
                        <Checkbox
                          checked={selectedRegions.includes(regionName)}
                        />
                        <ListItemText primary={regionName} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              {/* -------------------------------------------------------------------------------- */}
              {showZoneSelect &&
                (selectedRegions.length ||
                  selectedRegions.includes("select-all") ||
                  selectedRegions?.length === allregionnames.length) &&
                selectedRegions.length > 0 &&
                allregionZoneData.length > 0 && (
                  <div className="zone-flex-container">
                    <div className="zone-div" style={{ marginBottom: "10px" }}>
                      Region
                    </div>
                    {/* // Render zone select only if showZoneSelect is true */}
                    <FormControl>
                      <Select
                        multiple
                        value={selectedZone}
                        onChange={handleZoneChanges}
                        MenuProps={{
                          MenuListProps: {
                            autoFocusItem: false,
                            ref: menuRef,
                          },
                          PaperProps: {
                            style: {
                              maxHeight: 300,
                              overflowY: "auto",
                            },
                          },
                        }}
                        renderValue={(selected) => {
                          console.log("line 634", selected);
                          // const allZones = allregionZoneData.flatMap(regionData =>
                          //   Object.values(regionData).flat()
                          // );
                          // const allSelected = selected.length === allZones.length;
                          // return allSelected ? "Select All" : selected.join(", ");
                          if (
                            selected.length === 1 &&
                            selected[0] !== "select-all"
                          ) {
                            return selected[0]; // Render the single selected region name
                          } else if (selected.includes("select-all")) {
                            return "Select All";
                          } else if (
                            selected.length >= allZones.length &&
                            allZones.length > 1
                          ) {
                            return "Select All";
                          } else {
                            return selected.join(", ");
                          }
                        }}
                        style={{
                          fontFamily: "Hyundai Sans Head Office regular",
                          textAlign: "center",
                          fontSize: "15.711px",
                          fontStyle: "normal",
                          fontWeight: 400,
                          lineHeight: "normal",
                          backgroundColor: "white",
                          height: "40px",
                          overflow: "hidden", // Hide overflow
                          textOverflow: "ellipsis", // Truncate text with ellipsis
                          whiteSpace: "nowrap", // Prevent wrapping
                          width: "200px", // Set fixed width
                        }}
                      >
                        {allZones?.length > 1 && (
                          <MenuItem key="select-all" value="select-all">
                            <Checkbox
                              checked={selectedZone.length >= allZones?.length}
                            />
                            <ListItemText primary="Select All" />
                          </MenuItem>
                        )}
                        {/* {allregionZoneData.map((regionData) =>
                  Object.keys(regionData).map((regionName) =>
                    selectedRegions.includes(regionName) && (
                      regionData[regionName].map((zone) => (
                        <MenuItem key={zone} value={zone}>
                          <Checkbox checked={selectedZone.includes(zone)} />
                          <ListItemText primary={zone} />
                        </MenuItem>
                      ))
                    )
                  )
                )} */}
                        {allZones?.map((zone) => (
                          <MenuItem key={zone} value={zone}>
                            <Checkbox checked={selectedZone.includes(zone)} />
                            <ListItemText primary={zone} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                )}
              {/* --- end of region div div */}

              {/* ----------------activity status---------------------- */}

              <div className="zone-flex-container-report">
                <div
                  className="zone-div-report"
                  style={{ marginBottom: "10px" }}
                >
                  Status
                </div>
                <FormControl>
                  <Select
                    style={{
                      fontFamily: "Hyundai Sans Head Office regular",
                      textAlign: "center",
                      fontSize: "15.711px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "normal",
                      backgroundColor: "white",
                      height: "40px",
                      overflow: "hidden", // Hide overflow
                      textOverflow: "ellipsis", // Truncate text with ellipsis
                      whiteSpace: "nowrap", // Prevent wrapping
                      width: "200px", // Set fixed width
                    }}
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    value={selectedStatus}
                    onChange={handleStatusChange}
                    renderValue={(selected) => {
                      if (areAllOptionsSelected()) {
                        return "Select All";
                      } else {
                        return selected.join(", ");
                      }
                    }}
                  >
                    {allStatusnames.map((regionName) => (
                      <MenuItem key={regionName} value={regionName}>
                        <Checkbox
                          checked={
                            selectedStatus.includes(regionName) ||
                            (regionName === "Select All" &&
                              areAllOptionsSelected())
                          }
                        />
                        <ListItemText primary={regionName} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>

              {/* ----------content update---- */}

              <div className="zone-flex-container-report">
                <div
                  className="zone-div-report"
                  style={{ marginBottom: "10px" }}
                >
                  Content Update
                </div>
                <FormControl>
                  <Select
                    style={{
                      fontFamily: "Hyundai Sans Head Office regular",
                      textAlign: "center",
                      fontSize: "15.711px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "normal",
                      backgroundColor: "white",
                      height: "40px",
                      overflow: "hidden", // Hide overflow
                      textOverflow: "ellipsis", // Truncate text with ellipsis
                      whiteSpace: "nowrap", // Prevent wrapping
                      width: "200px", // Set fixed width
                    }}
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    value={selectedContent}
                    onChange={handleContentChange}
                    renderValue={(selected) => {
                      if (contentareAllOptionsSelected()) {
                        return "Select All";
                      } else {
                        return selected.join(", ");
                      }
                    }}
                  >
                    {allContentnames.map((regionName) => (
                      <MenuItem key={regionName} value={regionName}>
                        <Checkbox
                          checked={
                            selectedContent.includes(regionName) ||
                            (regionName === "Select All" &&
                              contentareAllOptionsSelected())
                          }
                        />
                        <ListItemText primary={regionName} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              {/* ------------------app update------------ */}

              <div className="zone-flex-container-report">
                <div className="zone-div" style={{ marginBottom: "10px" }}>
                  App Update
                </div>
                <FormControl>
                  <Select
                    style={{
                      fontFamily: "Hyundai Sans Head Office regular",
                      textAlign: "center",
                      fontSize: "15.711px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "normal",
                      backgroundColor: "white",
                      height: "40px",
                      overflow: "hidden", // Hide overflow
                      textOverflow: "ellipsis", // Truncate text with ellipsis
                      whiteSpace: "nowrap", // Prevent wrapping
                      width: "200px", // Set fixed width
                    }}
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    value={selectedAppUpdate}
                    onChange={handlAppChange}
                    renderValue={(selected) => {
                      if (AppareAllOptionsSelected()) {
                        return "Select All";
                      } else {
                        return selected.join(", ");
                      }
                    }}
                  >
                    {allAppnames.map((regionName) => (
                      <MenuItem key={regionName} value={regionName}>
                        <Checkbox
                          checked={
                            selectedAppUpdate.includes(regionName) ||
                            (regionName === "Select All" &&
                              AppareAllOptionsSelected())
                          }
                        />
                        <ListItemText primary={regionName} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>

              {/* filterr */}

              <div className="date-range-text-fexbox-container">
                <div className="zone-div" style={{ marginBottom: "10px" }}>
                  Dealer Filter
                </div>
                <div style={{ width: "300px", margin: "0 auto",cursor:"pointer" }}>
                  <Selects
                  styles={customStyles}
                    value={selectedOption}
                    onChange={handleChange}
                    options={dealerCodes}
                    isSearchable
                    placeholder="Select a dealer code"
                    isClearable
                  />
                </div>
              </div>

              {showapplybtn && (
                <div className="zone-flex-container">
                  <div
                    className="zone-div"
                    style={{ marginBottom: "10px", visibility: "hidden" }}
                  >
                    Button
                  </div>

                  {applyClicked ? (
                    <button
                      onClick={handleClearFilters}
                      className={`fetch-detailsbtntwo`}
                    >
                      Clear
                    </button>
                  ) : (
                    <button
                      onClick={handleFetchdetails}
                      className={`fetch-detailsbtn ${
                        fetchingDetails ? "fade-out" : "fade-in"
                      }`}
                    >
                      Apply
                    </button>
                  )}
                </div>
              )}

              {/* --------------date picker-------- */}
            </div>
            {zones && (
              <button
                onClick={handleBackClick}
                style={{
                  marginTop: "20px",
                  border: "1px solid black",
                  backgroundColor: "black",
                  color: "white",
                  borderRadius: "8px",
                  padding: "10px",
                  cursor: "pointer",
                }}
              >
                Back
              </button>
            )}
          </div>
        </>
      ) : (
        <>
          <div className="zone-flex-container" onClick={handleFilterClicks}>
            {/* <div className='zone-div' style={{marginBottom:"10px",visibility:"hidden"}}>Date Range</div> */}
            <div className="filters-div">
              <div>
                {" "}
                <FilterListIcon />
              </div>
              <div> Filter</div>
            </div>
          </div>



          { zones &&  <button onClick={handleBackClick} style={{marginTop:"20px",  border: '1px solid black',
  backgroundColor: 'black',
  color: 'white',
  borderRadius: '8px',
  padding:'10px',
  cursor:'pointer',
  marginRight:"10px",
  marginLeft:"10px"
}}>Back</button>}


          {/* add fetchdetaile to show  apply bth           */}
          
          {showapplybtn && (
                <div className="zone-flex-container">
                 

                   {applyClicked ? (
                  <div   className={`fetch-detailsbtnmobile ${
                    fetchingDetails ? "fade-in" : "fade-in"
                  }`} onClick={handleClearFilters} >Clear</div>
                ) : (
                  <div   className={`fetch-detailsbtnmobile ${
                    fetchingDetails ? "fade-in" : "fade-in"
                  }`} onClick={handleFetchdetails} >Apply</div>
                )}
                 
                </div>
              )}

          {/* Filter Dialog */}
          <Dialog
            open={isFilterOpen}
            onClose={handleCloseFilter}
            className="mui-filter-modaal"
            PaperProps={{
              style: {
                backgroundColor: "#ffffff",
                boxShadow: "none",

                paddingBottom: "30px",

                width: "calc(100vw)",

                WebkitOverflowScrolling: "touch", // Enable smooth scrolling in iOS
                scrollbarWidth: "none", // Hide scrollbar for Firefox
                msOverflowStyle: "none", // Hide scrollbar for IE and Edge
                "&::-webkit-scrollbar": {
                  display: "none", // Hide scrollbar for WebKit browsers
                },
                // Adjust the width by subtracting the desired left and right spacing
                // marginLeft: "10px", // Left spacing
                // marginRight: "10px", // Right spacing
              },
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                paddingTop: "10px",
                paddingBottom: "40px",
                paddingLeft: "10px",
                paddingRight: "10px",
                position: "fixed",
                left: "10px",
                right: "10px",
                border: "1px solid grey",
                borderTop: "none",
                borderLeft: "none",
                borderRight: "none",
                backgroundColor: "#FFFFFF",
                borderTopRightRadius: "20px",
                borderTopLeftRadius: "20px",

                width: "100%",

                height: "20px",
                zIndex: "999",
              }}
              className="device-status-full-contain-sects"
            >
              <div style={{ marginTop: "10px" }}> Change Filters</div>
              <div
                onClick={handleCloseFilter}
                style={{ textAlign: "right" }}
                className="close-btns"
              >
                <div style={{ width: "35px", height: "35px" }}>
                  <img
                    src={closeIcon}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                    }}
                  />
                </div>
              </div>{" "}
              {/* Add onClick handler */}
            </div>

            <div
              className="device-status-full-contain-section"
              style={{ marginTop: "60px" }}
            >
              <div className="hyundai-content-sections">
                {/* ---------------------------------------------------------- */}
                <Accordion
                  expanded={accordionExpandeds}
                  onChange={handleDeviceAccordianChange}
                  className={`accordian-container ${
                    accordionExpandeds ? "expanded" : ""
                  }`}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    className={`accordian-summary ${
                      accordionExpandeds ? "expanded" : ""
                    }`}
                  >
                    <Typography>Device Type</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      {/* Render device type options */}
                      {allContentnamesTwo.map((regionName) => (
                        <div
                          key={regionName}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            paddingTop: "5px",
                          }}
                        >
                          <Checkbox
                            checked={
                              selectedContentwo.includes(regionName) ||
                              (regionName === "Select All" &&
                                contentareAllOptionsSelectedtwo())
                            }
                            onChange={(event) => handleContentChangesTwo(event)} // Pass the event object
                            value={regionName}
                          />
                          <div>
                            {regionName === "Kiosk"
                              ? "Digital Kiosk"
                                   : regionName === "Centralized Waiting Period Display"
                  ? "Centralized Display Monitor"

                : regionName}
                          </div>
                        </div>
                      ))}
                    </div>
                  </AccordionDetails>
                </Accordion>

                <Accordion
                  expanded={ZoneAccordianExpanded}
                  onChange={handlezoneAccordianChange}
                  className={`accordian-container ${
                    ZoneAccordianExpanded ? "expanded" : ""
                  }`}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="zone-panel-content"
                    id="zone-panel-header"
                    className={`accordian-summary ${
                      ZoneAccordianExpanded ? "expanded" : ""
                    }`}
                  >
                    <Typography>Zone</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        overflowY: "scroll",
                        minHeight: "10px",
                      }}
                      className="heightprop-check"
                    >
                      {/* Conditionally render "Select All" checkbox */}
                      {allregionnames.length > 1 && (
                        <MenuItem key="select-all" value="select-all">
                          <Checkbox
                            checked={
                              selectedRegions.length === allregionnames.length
                            }
                            onChange={(event) =>
                              handleRegionChange(event, "select-all")
                            }
                            value="select-all"
                          />
                          <ListItemText primary="Select All" />
                        </MenuItem>
                      )}
                      {/* Individual region checkboxes */}
                      {allregionnames.map((regionName) => (
                        <MenuItem key={regionName} value={regionName}>
                          <Checkbox
                            checked={selectedRegions.includes(regionName)}
                            onChange={(event) =>
                              handleRegionChange(event, regionName)
                            }
                            value={regionName}
                          />
                          <ListItemText primary={regionName} />
                        </MenuItem>
                      ))}
                    </div>
                  </AccordionDetails>
                </Accordion>

                <Accordion
                  expanded={regionAccordionExpanded}
                  onChange={toggleRegionAccordion}
                  className={`accordian-container ${
                    regionAccordionExpanded ? "expanded" : ""
                  }`}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="region-panel-content"
                    id="region-panel-header"
                    className={`accordian-summary ${
                      regionAccordionExpanded ? "expanded" : ""
                    }`}
                  >
                    <Typography>Region</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="zone-flex-container">
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        {/* Conditionally render "Select All" checkbox */}
                        {Array.isArray(allZones) && allZones.length > 1 && (
                          <MenuItem key="select-all" value="select-all">
                            <Checkbox
                              checked={
                                Array.isArray(selectedZone) &&
                                selectedZone.length >= allZones.length
                              }
                              onChange={handleZoneChange}
                              value="select-all"
                            />
                            <ListItemText primary="Select All" />
                          </MenuItem>
                        )}
                        {allZones &&
                          allZones.map((zone) => (
                            <MenuItem key={zone} value={zone}>
                              <Checkbox
                                checked={selectedZone.includes(zone)}
                                onChange={(event) =>
                                  handleZoneChange(event, zone)
                                }
                                value={zone}
                              />
                              <ListItemText primary={zone} />
                            </MenuItem>
                          ))}
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>

                <Accordion
                  expanded={StatusAccordionExpanded}
                  onChange={handleStatusAccordianChange}
                  className={`accordian-container ${
                    StatusAccordionExpanded ? "expanded" : ""
                  }`}
                >
                  <AccordionSummary
                    className={`accordian-summary ${
                      StatusAccordionExpanded ? "expanded" : ""
                    }`}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>Status</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="zone-flex-container">
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        {allStatusnames.map((statusName) => (
                          <MenuItem key={statusName} value={statusName}>
                            <Checkbox
                              checked={
                                selectedStatus.includes(statusName) ||
                                (statusName === "Select All" &&
                                  areAllOptionsSelected())
                              }
                              onChange={(event) =>
                                handleStatusChanges(event, statusName)
                              }
                            />
                            <ListItemText primary={statusName} />
                          </MenuItem>
                        ))}
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>

                <Accordion
                  expanded={ContentAccordionExpanded}
                  onChange={handleContentAccordianChange}
                  className={`accordian-container ${
                    ContentAccordionExpanded ? "expanded" : ""
                  }`}
                >
                  <AccordionSummary
                    className={`accordian-summary ${
                      ContentAccordionExpanded ? "expanded" : ""
                    }`}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>Content Update</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="zone-flex-container">
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        {allContentnames.map((statusName) => (
                          <MenuItem key={statusName} value={statusName}>
                            <Checkbox
                              checked={
                                selectedContent.includes(statusName) ||
                                (statusName === "Select All" &&
                                  contentareAllOptionsSelected())
                              }
                              onChange={(event) =>
                                handleContentChanges(event, statusName)
                              }
                            />
                            <ListItemText primary={statusName} />
                          </MenuItem>
                        ))}
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>

                <Accordion
                  expanded={AppAccordionExpanded}
                  onChange={handleAppAccordianChange}
                  className={`accordian-container ${
                    AppAccordionExpanded ? "expanded" : ""
                  }`}
                >
                  <AccordionSummary
                    className={`accordian-summary ${
                      AppAccordionExpanded ? "expanded" : ""
                    }`}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>App Update</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="zone-flex-container">
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        {allAppnames.map((statusName) => (
                          <MenuItem key={statusName} value={statusName}>
                            <Checkbox
                              checked={
                                selectedAppUpdate.includes(statusName) ||
                                (statusName === "Select All" &&
                                  AppareAllOptionsSelected())
                              }
                              onChange={(event) =>
                                handlAppChanges(event, statusName)
                              }
                            />
                            <ListItemText primary={statusName} />
                          </MenuItem>
                        ))}
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>



                {/* delaer dropdown */}


                
                <div className='date-range-text-fexbox-containerss'>
{/* <div className='zone-div' style={{marginBottom:"10px"}}>Dealer Filter</div> */}
<div style={{ width: '100%',height:"40px",margin: '0 auto'}}>
<Selects
      value={selectedOption}
      onChange={handleChange}
      options={dealerCodes}
      isSearchable
      placeholder="Select a dealer code"
      isClearable
     
    />
    </div>


</div>

                {/* -------------------------------------------------------------- */}

                {/* ------------------------------------------------------------------- */}

                {/* -------------------------------------------------------------------------------- */}

                {/* ------------------------------------------------------------------------------------------- */}

                {/* date range  */}
              </div>
              {/* --------------------------------------------------------- */}
            </div>
            <DialogContent></DialogContent>
          </Dialog>

          {/* --2nd accordian-- */}
        </>
      )}

      {/*-------- fetch deatials start------- */}

      {/* ----fetch details */}

      {/* ---end of fetch details--- */}

      {/* --------fetch detaaials end-------- */}

      {/* seaarch */}

      <div>
        <div className="seaarch-report-flexbox-container">
          {/* ------search ------ */}
          {/* <div className="search-section-statusreport">
            <div className="search-text-div-staatusreport">Search</div>
            <img
              src={customSearchIcon}
              alt="Search"
              className="custom-search-icon-staatusreport"
            />
            <input
              type="text"
              placeholder="Search by dealer code"
              className="searchbar-statusreport"
              onChange={handleSearch}
              value={searchValue}
            />
          </div> */}

          {/* ---download---- */}
          {isMobile ? (
            <div className="download-device-sections" onClick={DownloadReport}>
              <FileDownloadOutlinedIcon
                className="download-status-reporticon"
                style={{
                  width: "25px",
                  height: "25px",
                  margin: "auto",
                  display: "block",
                  textAlign: "center",
                  justifyContent: "center",
                  justifyItems: "center",
                  alignContent: "center",
                }}
              />
            </div>
          ) : (
            <div className="">{/* download */}</div>
          )}
        </div>
        <div className="download-report-mobile-container">
          {/* <div className='download-report-divs'  >

Download report
<span className='downloadd-icon'><FileDownloadOutlinedIcon  /></span>
</div> */}

          <div className="download-report-divs">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "2px",
                justifyContent: "center",
                alignItems: "Center",
              }}
              onClick={DownloadReport}
            >
              <div className="download-report-div">Download report</div>
              <div>
                <FileDownloadOutlinedIcon />
              </div>
            </div>
          </div>
        </div>
        {/* -------start of table------------- */}

        <div className="dealercode-table-section">
          {isDataEmpty && tableData.length === 0 ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "30px",
                fontFamily: "Hyundai Sans Head Office regular",
              }}
            >
              No data found
            </div>
          ) : (
            <div>
              {/* <Table dataSource={tableData} columns={columns} pagination={paginationOptions}  style={{ width: '100%' }}  scroll={{ x: true }}  className='dealers-table' /> */}

              {
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow style={{ backgroundColor: "#003984"}}>
                        <TableCell className="dealer-sect">Zone</TableCell>
                        <TableCell className="dealer-sect">Region</TableCell>
                        <TableCell className="dealer-sect">City</TableCell>

                        <TableCell
                          className="dealer-sect"
                          style={{ color: "#FFFFFF" }}
                        >
                          Dealer Code
                        </TableCell>
                        <TableCell className="dealer-sect">
                          Dealer Name
                        </TableCell>
                        <TableCell className="dealer-sect">
                          Device Name
                        </TableCell>
                        <TableCell className="dealer-sect">Status</TableCell>
                        <TableCell className="dealer-sect">
                          Content Update
                        </TableCell>
                        <TableCell className="dealer-sect">
                          App Update
                        </TableCell>
                        <TableCell className="dealer-sect">
                          Offline since
                        </TableCell>
                      </TableRow>
                    </TableHead>
                 { tableData
                          .filter((row) => {
                            if (selectedOption === null) {
                              // Apply filter based on selectedStatusForFiltering if selectedOption is null
                              return selectedStatusForFiltering
                                .map((status) => status.toLowerCase())
                                .includes(row.device_status.toLowerCase());
                            } else {
                              // Display all rows if selectedOption is not null
                              return true;
                            }
                          }).length===0 &&  <div style={{width:'max content', padding:'10px'}}>No data found</div>}
                    <TableBody>
                      {Array.isArray(tableData) &&
                        tableData
                          .filter((row) => {
                            if (selectedOption === null) {
                              // Apply filter based on selectedStatusForFiltering if selectedOption is null
                              return selectedStatusForFiltering
                                .map((status) => status.toLowerCase())
                                .includes(row.device_status.toLowerCase());
                            } else {
                              // Display all rows if selectedOption is not null
                              return true;
                            }
                          })
                          .map((row) => {
                            console.log(
                              "line 2826",
                              row,
                              selectedStatusForFiltering
                            );
                            return (
                              <TableRow
                                key={row.dealer_code}
                                onClick={() =>
                                  handleClick(
                                    row.device,
                                    row.dealer_code,
                                    row.device
                                  )
                                }
                              >
                                <TableCell className="row-device-sec">
                                  {row.zone}
                                </TableCell>
                                <TableCell className="row-device-sec">
                                  {row.region}
                                </TableCell>
                                <TableCell className="row-device-sec">
                                  {row.city}
                                </TableCell>
                                <TableCell className="row-device-sec">
                                  {row.dealer_code}
                                </TableCell>
                                <TableCell className="row-device-sec">
                                  {row.dealer_name}
                                </TableCell>
                                <TableCell className="row-device-sec">
                                  {/* {row.device === "central display"
                                    ? "Centralized Waiting Period Display"
                                    : row.device === "horizontal"
                                    ? "3d Configurator"
                                    : "Digital Kiosk"} */}
                                    {row.device}
                                </TableCell>

                                <TableCell
                                  sx={{ minWidth: 120 }}
                                  className="row-device-sec"
                                >
                                  {row.device_status}
                                  {row.device_status === "online" ? (
                                    <div
                                      className="circle"
                                      style={{ backgroundColor: "#40F400" }}
                                    ></div>
                                  ) : (
                                    <div
                                      className="circle"
                                      style={{ backgroundColor: "red" }}
                                    ></div>
                                  )}
                                </TableCell>
                                <TableCell
                                  className="row-device-sec-new"
                                  style={{
                                    color:
                                      row.content_update_status === "pending"
                                        ? "#A81E1E"
                                        : "#32C100",
                                  }}
                                >
                                  {row.content_update_status}
                                </TableCell>
                                <TableCell
                                  className="row-device-sec-new"
                                  style={{
                                    color:
                                      row.app_update_status === "outdated"
                                        ? "#A81E1E"
                                        : "#32C100",
                                  }}
                                >
                                  {row.app_update_status}
                                </TableCell>
                                <TableCell className="row-device-sec">
                                  {row.offline_since}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                    </TableBody>
                  </Table>
                </TableContainer>
              }

{searchValue.trim() === "" && tableData.length>0 && !dealerSelect
  && (
    <div className="pagination-flex-device-status-report"  style={{display:"flex",flexDirection:"row",gap:"5px",justifyContent:"right",marginTop:"10px",alignContent:"center",textAlign:"center",alignItems:"center"}}>
                    <div>
                      <Pagination
                        count={Math.ceil(totalRecords / recordsPerPage)}
                        page={currentPage}
                        onChange={handlePageChange}
                      />
                      {/* <Pagination count={10} color="secondary" /> */}
                    </div>

                    <div>
                      page {currentPage} of {totalPages}
                    </div>
                  </div>
                )}
            </div>
          )}
        </div>
      </div>

      <Button onClick={handleOpenPopup}></Button>
      <Dialog
        open={openPopup}
        onClose={handleClosePopup}
        className="mui-filter-modaals"
        PaperProps={{
          style: {
            backgroundColor: "#ffffff",
            boxShadow: "none",

            paddingBottom: "30px",

            width: "calc(100vw - 20px)", // Adjust the width by subtracting the desired left and right spacing
            // marginLeft: "10px", // Left spacing
            // marginRight: "10px", // Right spacing
          },
        }}
      >
        {/* <DialogTitle>Popup Title</DialogTitle> */}
        <DialogContent>
          {/* Display the API data here */}
          {apiNewData ? (
            <div>
              {/* dealer detail page data */}

              <div className="details-page-navbar">
                <div className="back-date-flexbox-container">
                  <div className="details-backicon-flexbox-container">
                    {/* <div><KeyboardBackspaceIcon /></div> */}
                    <div>
                      <div
                        className="back-btn-delaers"
                        onClick={handleBack}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "5px",
                        }}
                      >
                        <div>
                          <img src={Leftarrow} />
                        </div>
                        <div>Back</div>
                      </div>
                    </div>
                  </div>

                  <div></div>
                </div>
                <div className="dealer-detail-downloadreport-container">
                  {/* <div>
Download Report
</div> */}
                  {/* <div  ><FileDownloadOutlinedIcon  /></div> */}
                </div>
              </div>

              <div className="dealers-detail-fullbox-flex-container">
                <div className="dealers-detail-navbar-heading-container">
                  <div className="dealers-details-text-image-container">
                    <div className="dealers-details-image"></div>
                    <div>{deviceTypeSavingData}</div>
                  </div>

                  {/* for dealer code */}
                  {/* <p>Dealer Code: {dealerCode}</p> */}

                  <div>
                    <div>status</div>
                    <div>
                      {activesta === "online" ? (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "5px",
                            justifyContent: "Center",
                            alignContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <div className="Dealers-detail-number">
                            {activesta}
                          </div>
                          <div
                            className="circle"
                            style={{ backgroundColor: "#40F400" }}
                          >
                            {" "}
                          </div>
                        </div>
                      ) : (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "5px",
                            justifyContent: "Center",
                            alignContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <div className="Dealers-detail-number">
                            {activesta}
                          </div>
                          <div
                            className="circle"
                            style={{ backgroundColor: "red" }}
                          ></div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                {/* -------deaaler detail flex box container------------- */}
                <div className="dealer-detail-firstrow-data-container"></div>

                <div className="dealer-detail-firstrow-data-container">
                  <div className="dealer-details-individual-cards">
                    <div className="Dealer-details-id">Dealer ID </div>
                    <div className="Dealers-detail-number">
                      {DetailDealerId}{" "}
                    </div>
                  </div>

                  <div className="dealer-details-individual-cards">
                    <div className="Dealer-details-id">Showroom Name </div>
                    <div className="Dealers-detail-number">{ShowroomName} </div>
                  </div>

                  <div className="dealer-details-individual-cards">
                    <div className="Dealer-details-id">Last content Update</div>
                    <div className="Dealers-detail-number">
                      {LastcontDealerupdate}{" "}
                    </div>
                  </div>

                  <div className="dealer-details-individual-cards">
                    <div className="Dealer-details-id">Content Update </div>
                    <div className="Dealers-detail-number">
                      {Dealercontupdate}{" "}
                    </div>
                  </div>

                  <div className="dealer-details-individual-cards">
                    <div className="Dealer-details-id">Zone </div>
                    <div className="Dealers-detail-number">{DealeZone}</div>
                  </div>

                  <div className="dealer-details-individual-cards">
                    <div className="Dealer-details-id">DealeRegion</div>
                    <div className="Dealers-detail-number">{DealeRegion}</div>
                  </div>

                  <div className="dealer-details-individual-cards">
                    <div className="Dealer-details-id">Last App Update</div>
                    <div className="Dealers-detail-number">
                      {LastDealerAppUdate}{" "}
                    </div>
                  </div>
                  <div className="dealer-details-individual-cards">
                    <div className="Dealer-details-id">App Update</div>
                    <div className="Dealers-detail-number">
                      {DealerAppUpda}{" "}
                    </div>
                  </div>
                </div>

                {/* ----------------Activity status------------------------ */}
                <div className="dealers-detaails-activity-status"></div>

                {/* --------------device content-app-update------------------ */}

                <div className="dealers-details-content-app-update-section">
                  <div className="dealers-details-tab-flexbox-container">
                    {tabsection.map((tab) => (
                      <div
                        key={tab.id}
                        className={
                          setNewactiveTabs === tab.id
                            ? "active-tab"
                            : "inactive-tab"
                        }
                        onClick={() => handleTabChange(tab.id)}
                      >
                        {tab.name}
                      </div>
                    ))}
                  </div>
                </div>

                {/* tabs details */}

                <div className="dealers-details-tab-content">
                  {/* Content for Tab 1 */}
                  {setNewactiveTabs === "tabone" && (
                    <>
                      <div>
                        <div className="content-app-update-table-full-section">
                          <div className="contentname-title-section">
                            <div>Content name</div>
                            <div>Date</div>
                          </div>

                          {Newcontentupdate &&
                            Newcontentupdate.slice(0, 5).map((card) => (
                              <div
                                key={card.id}
                                className="dealers-details-content-app-flexbox-cards"
                              >
                                <div className="dealers-detaails-contentname-full-container-table">
                                  <div
                                    className="dealers-content-name-heading-texts"
                                    style={{ alignItems: "flex-start" }}
                                  >
                                    {card.title}
                                  </div>
                                  <div
                                    className="dealers-content-name-heading-texts"
                                    style={{ alignItems: "flex-end" }}
                                  >
                                    {card.date}
                                  </div>
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                    </>
                  )}

                  {setNewactiveTabs === "tabtwo" && (
                    <>
                      <div>
                        <div className="content-app-update-table-full-section">
                          <div className="contentname-title-section">
                            <div>Content name</div>
                            <div>Date</div>
                          </div>

                          {NewappUpdate &&
                            NewappUpdate.slice(0, 5).map((card) => (
                              <div
                                key={card.id}
                                className="dealers-details-content-app-flexbox-cards"
                              >
                                <div className="dealers-detaails-contentname-full-container-table">
                                  <div className="dealers-content-name-heading-texts">
                                    {card.title}
                                  </div>
                                  <div className="dealers-content-name-heading-texts">
                                    {card.date}
                                  </div>
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <p>Loading...</p>
          )}
        </DialogContent>
        {/* <DialogActions>
          <Button onClick={handleClosePopup}>Close</Button>
        </DialogActions> */}
      </Dialog>
    </>
  );
}

export default Devicestatusreport;
