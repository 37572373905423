import React,{useState,useEffect,useRef}from 'react'
import { DatePicker } from "antd";
import '../Assets/components.css'
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import  customSearchIcon from "../Assets/icon.png";
import Dialog from "@mui/material/Dialog";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import chart from '../Assets/chart.png' 
import FilterListIcon from '@mui/icons-material/FilterList';
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import Chart from 'chart.js/auto';
import { Line } from 'react-chartjs-2';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, BarElement, CategoryScale, LinearScale, Tooltip } from 'chart.js';
import  next from  '../Assets/newchefronleft.png'
import   prev  from '../Assets/newchefronright.png'
import { Doughnut,Pie } from 'react-chartjs-2'
import Navbar from './Navbar';
import Dashboard from './Dashboard';
import axios from 'axios'
import moment from 'moment';
import {  DialogTitle, DialogContent, Button } from "@mui/material";
import closeIcon from '../Assets/Closeicons.png';
import Selects from 'react-select';
import { Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';


function Usagedashboard() {
  const [apiResponse, setApiResponse] =useState(null);
  const[prodlistArray,setProdlistArray]=useState([])
 
  const [allZones, setAllZones] = useState(null)
  const[newResponsetwo,setNewResppnseTwo]=useState(null);
  const[dealerSelect,setDealerSelect]=useState(null)


    const [isMobile, setIsMobile] = useState(false);
    const [accordionExpanded, setAccordionExpanded] = useState(false);
    const [accordionExpandedtwo, setAccordionExpandedtwo] = useState(false); 
    const [accordionExpandedthree, setAccordionExpandedthree] = useState(false); 
    const[showapplybtn,setShowApplyBtn]=useState(false);
    // graph code
    const [chart, setChart] = useState([]);
    // for line
    const [charttwo,setChartwo]=useState([]);
    // forpie
    const[chartDatatwo,setChartDatatwo]=useState([])
    const[productList,setProductList]=useState([])
    const [startIndex, setStartIndex] = useState(3);
    const itemsPerPage = isMobile ? 5 : 15; 


    const itemsPerPageLine = isMobile ? 6 : 15;
    const itemsPerPageBar = isMobile ? 6 : 15;


  
    //my  own data
    const contentupdate = ["Select All", "3D Configurator ", "Kiosk"];
    const allContentnames = ['Select All', '3D Configurator', 'Kiosk'];
    const allContentnamesTwo = ['Select All', '3d configurator', 'kiosk'];
    const [selectedContent, setContentStatus] = useState(allContentnames.slice(1));
    // const [prodcontent,setprodcont]=useState(productList)
    const[selectedContenttwo,setSelectedContentTwo]=useState(allContentnamesTwo.slice(1))
    const [formattedSelectedContentOptions, setFormattedSelectedContentOptions] = useState([]);
    const [allregionZoneData, setAllRegionZoneData] = useState([]);
    const [allregionnames, setAllRegionNames] = useState([]);
    const [allzonenames, setAllZoneNames] = useState([]);
    const[prodmodel,setProdModel]=useState([]);

    const [selectedZone, setSelectedZone] = useState([]);
    const [showZoneSelect, setShowZoneSelect] = useState(true); // New state
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const[openSnackbartTwo,setOpenSnackbarTwo]=useState(false)

const[regionnamestwo,setRegionNamestwo]=useState([]);
const[zoneNamestwo,setZoneNamestwo]=useState([]);




    const currentDate = moment(); // Get today's date

const defaultEndDate = currentDate.clone(); // Set end date to today's date
const defaultStartDate = currentDate.clone().subtract(14, 'days'); // Set start date 15 days before today

const [startDate, setStartDate] = useState(defaultStartDate);
const [endDate, setEndDate] = useState(defaultEndDate);


const [startDateTwo,setStartDateTwo] = useState(defaultStartDate);
const [endDateTwo,setEndDateTwo] = useState(defaultEndDate);

const [applyClicked, setApplyClicked] = useState(false);


    const[fetchdetaile,setfetchDetails]=useState(true);
    const[cardsection,setCardsection]=useState(false)
    const [barChartIndex, setBarChartIndex] = useState(0);
  const[totaalVisits,setTotalVisits]=useState()
  const[topModels,setTopModels]=useState([])
  const[bottomModels,setBottomModels]=useState([])
  const[topDealers,setTopDealers]=useState([])
  const[topDealerscode,setTopDealersCode]=useState([])
  const[bottomDealers,setBottomDealers]=useState([]);
  const[bottomdealerscode,setBotomdealerscode]=useState([]);
  const[topSection,setTopSection]=useState([])
  const[bottomSection,setBottomSection]=useState([])
  const [isPrevClicked, setIsPrevClicked] = useState(false);
  const [isNextClicked, setIsNextClicked] = useState(false);

  const [isPrevClickedTwo, setIsPrevClickedTwo] = useState(false);
  const [isNextClickedTwo, setIsNextClickedTwo] = useState(false);

  const [fetchingDetails, setFetchingDetails] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

    
  const menuRef = useRef(null);
  const [menuLoaded, setMenuLoaded] = useState(false);


  // for mui popup
  useEffect(() => {
    if (menuRef.current && !menuLoaded) {
      // Scroll to the top only if the menu is loaded for the first time
      menuRef.current.scrollTop = 0;
      setMenuLoaded(true);
    }
  }, [menuLoaded]);



    
    const[daywiseResponse,setDaywiseResponse]=useState([])
    const[modelWiseUsage,setModelwiseUsage]=useState([])
    const[sectionWiseUsage,setSectionWiseUsage]=useState([])



    const handleSnackbarClose = () => {
      setOpenSnackbar(false);
  };

  const handleSnackbarCloseTwo = () => {
    setOpenSnackbarTwo(false);
};



    
    const handleDateChange = (dates) => {
      if (dates && dates.length === 2) {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
      } else if (!dates || dates.length === 0) {
        // Clear start date when both dates are cleared
        setStartDate(null);
        setEndDate(null); // Also clear end date explicitly
      }
      // Always trigger fetchDetails and setCardsection updates
      setfetchDetails(true)
      setCardsection(false)
      setFetchingDetails(false);
      setShowApplyBtn(true);
      setSearchValue('');
      setApplyClicked(false);
    };
    
    

    const handleContentChange = (event) => {
      setfetchDetails(true);
      setCardsection(false);
      setFetchingDetails(false);
      setShowApplyBtn(true);
      setSearchValue('');
      setApplyClicked(false);
    
      const { value } = event.target;
    
      if (value.includes('Select All')) {
        handleSelectContent();
      } else {
        const contentupdatedValue = value.filter((val) => val !== 'Select All');
        const lowercaseOptions = contentupdatedValue.map((option) => option.toLowerCase());
        setContentStatus(contentupdatedValue);
        setFormattedSelectedContentOptions(lowercaseOptions);
      }
    };
    



    const handleContentChanges = (event) => {
      setfetchDetails(true);
      setCardsection(false);
      setFetchingDetails(false);
      setShowApplyBtn(true);
      setSearchValue('');
      setApplyClicked(false);
    
      const { value, checked } = event.target;
    
      if (value === 'Select All') {
        handleSelectContent(checked);
      } else {
        // If "Select All" is checked, uncheck it
        const contentupdatedValue = selectedContent.includes('Select All')
          ? selectedContent.filter((val) => val !== 'Select All')
          : selectedContent;
    
        // Update the selected content based on the checkbox status
        const updatedContent = checked
          ? [...contentupdatedValue, value]
          : contentupdatedValue.filter((val) => val !== value);
    
        // Convert updatedContent to lowercase
        const lowercaseOptions = updatedContent.map((option) => option.toLowerCase());
    
        // Update the state with the new selected content
        setContentStatus(updatedContent);
        setFormattedSelectedContentOptions(lowercaseOptions);
      }
    };
    

    
    

    
    // Define a function to determine if "Select All" should be checked based on the selected checkboxes


  
  
    
  
    const handleSelectContent = () => {
      setfetchDetails(true);
      setCardsection(false);
      setFetchingDetails(false);
      setShowApplyBtn(true);
      setSearchValue('');
      setApplyClicked(false);
      if (selectedContent.length === allContentnames.length - 1) {
        setContentStatus([]);
        const lowercaseOptions = [];
        setFormattedSelectedContentOptions(lowercaseOptions);
      } else {
        const ContupdatedValue = allContentnames.slice(1);
        const lowercaseOptions = ContupdatedValue.map((option) => option.toLowerCase()); // Convert to lowercase
        setContentStatus(ContupdatedValue);
        setFormattedSelectedContentOptions(lowercaseOptions); // Set lowercase options
      }
    };
    
  
    const contentareAllOptionsSelected = () => {
      return selectedContent.length === allContentnames.length - 1;
    };
  
    useEffect(() => {
      // This effect ensures all options are checked when the component loads
      setContentStatus(allContentnames.slice(1));
      setFormattedSelectedContentOptions(allContentnames.slice(1).map(option => option.toLowerCase()));
      // setFormattedSelectedAppOptions(allAppnames.slice(1).map(option => option.toLowerCase()));
      console.log("useefectcontent", formattedSelectedContentOptions)
    }, []); // Run only once when component mounts
  
  console.log("selectedContent", selectedContent)




  // product list aapi:

  useEffect(()=>{

    const productListApi = `https://digilens.myhyundai.co.in/digi_lens/dashboard_api/get_product_list/`;
    console.log("newPrdoductListApi", productListApi);
    
    axios.get(productListApi)
      .then((response) => {
        console.log("response",response.data)
      
        setProductList(response.data)
        console.log("productList",productList)
        

      })
      .catch((error) => {
        console.error('Error fetching region and zone data:', error);
      });


  },[])


  const handleChange = (selectedOption) => {
    // Check if start and end dates exist
   

    if (!startDate || !endDate) {
      setOpenSnackbar(true);
      return;
  }


    console.log("line 298", selectedOption);
    setSelectedOption(selectedOption);
    const selectedValue = selectedOption ? selectedOption.value : '';
    setDealerSelect(selectedValue);

    console.log('Option selected:', selectedOption);
    console.log('selectedDealers', selectedValue);

    const apiUrl = `https://digilens.myhyundai.co.in/digi_lens/dashboard_api/usage_dashboard/?username=${storedUsername}&permission=${JSON.stringify(storedPermission)}&device_type_array=${JSON.stringify(formattedSelectedContentOptions)}&zone=${JSON.stringify(selectedRegions)}&region=${JSON.stringify(selectedZone)}&start_date=${formatDatesForAPI(startDate)}&end_date=${formatDatesForAPI(endDate)}&product_array=${JSON.stringify(selectedContentNine)}`;

    const updateStateWithData = (data) => {
        console.log('usageResp', data);
        setDaywiseResponse(data.day_wise_usage);
        setModelwiseUsage(data.model_wise_usage);
        setSectionWiseUsage(data.section_wise_usage);
        setTotalVisits(data.total_visits);
        setTopModels(data.top_models);
        setBottomModels(data.bottom_models);
        setTopDealers(data.top_dealers);
        setBottomDealers(data.bottom_dealers);
        setTopSection(data.top_sections);
        setBottomSection(data.bottom_sections);
    };

    if (selectedValue !== '') {
        const searchApiUrl = `${apiUrl}&dealer_code=${selectedValue}`;
        console.log('API URL:', searchApiUrl);

        axios.get(searchApiUrl)
            .then((response) => {
                updateStateWithData(response.data);
            })
            .catch((error) => {
                console.error('Error fetching usage dashboard data:', error);
            });
    } else {
        console.log('Fetching data without dealer filter');
        axios.get(apiUrl)
            .then((response) => {
                updateStateWithData(response.data);
            })
            .catch((error) => {
                console.error('Error fetching original usage dashboard data:', error);
            });
    }
};



  const [selectedRegions, setSelectedRegions] = useState([]);

  const storedPermission = JSON.parse(localStorage.getItem('permission'));
  const storedUsername = localStorage.getItem('username');


  const [lineChartIndex, setLineChartIndex] = useState(0);
  const [dealerCodes, setDealerCodes] = useState([]);
  // 
  const { RangePicker } = DatePicker;
  // const [Date, setDate] = useState(["2023-12-11", "2023-12-29"]);
 


  
 useEffect(() => {

callRegionApi()
  
 
  }, []);
  
  const callRegionApi =()=>{
    const zones = JSON.parse(localStorage.getItem("selectedZone"))
    const regions = JSON.parse(localStorage.getItem("selectedRegions"))
    const content = JSON.parse(localStorage.getItem("selectedContent"))
    localStorage.removeItem("selectedZone")
    localStorage.removeItem("selectedRegions")
    localStorage.removeItem("selectedContent")
    if(content)
      setContentStatus(content)
  else
  setContentStatus(allContentnames.slice(1))
    const regionApi = `https://digilens.myhyundai.co.in/digi_lens/dashboard_api/get_region_zone_list/?username=${storedUsername}&permission=${JSON.stringify(storedPermission)}`;
console.log("newRegionApi", regionApi);

axios.get(regionApi)
  .then((response) => {
    console.log('line 379 triggered')
    console.log("Response from device status API:", response);
    setApiResponse(response.data)
    if (Array.isArray(response.data)) {
      // Handle response with array structure
      const regionNames = response.data.map(singleRegion => Object.keys(singleRegion)[0]);
      setAllRegionNames(regionNames);
      setAllRegionZoneData(response.data);
      if(regions){
        setSelectedRegions(regions)
      }
      else
      setSelectedRegions(regionNames);
      setRegionNamestwo(regionNames);
  
      // Filter zones for selected regions and flatten the array
      const allZones = response.data.flatMap(regionData => Object.values(regionData)[0]);
      if(zones){
        setSelectedZone(zones)
      }
      else
      setSelectedZone(allZones);
      setAllZones(allZones);
      setZoneNamestwo(allZones)
    } 
    
    else if (typeof response.data === 'object') {
      // Handle response with object structure
      const regionNames = Object.keys(response.data);
      setAllRegionNames(regionNames);
      setAllRegionZoneData(regionNames.map(regionName => ({ [regionName]: response.data[regionName] })));

      // Set selected regions and corresponding zones
      setSelectedRegions(regionNames);
      setRegionNamestwo(regionNames)

      // Filter zones for selected regions and flatten the array
      const allZones = regionNames.flatMap(regionName => response.data[regionName]);
      setSelectedZone(allZones);
      setAllZones(allZones);
      setZoneNamestwo(allZones)
    } else {
      // Handle unexpected response structure
      console.error('Unexpected response structure:', response);
    }
  })
  .catch((error) => {
    console.error('Error fetching region and zone data:', error);
  });
  }



  useEffect(() => {
    const dealerApi = `https://digilens.myhyundai.co.in/digi_lens/dashboard_api/get_all_dealer/?username=${storedUsername}&permission=${JSON.stringify(storedPermission)}&device_type_array=${JSON.stringify(selectedContenttwo)}`;
    console.log("Dealer API URL:", dealerApi);
  
    axios.get(dealerApi)
      .then((response) => {
        console.log("dealerResponse", response);
        const codes = response.data.map(dealer => ({
          value: dealer.dealer_code,
          label: `${dealer.dealer_code} (${dealer.dealer_name})`
        }));
        setDealerCodes(codes);
        console.log("codes", codes);
      })
      .catch((error) => {
        console.error("Error fetching dealer codes:", error);
      });
  }, []);
  

  


  const handleRegionChange = (event, regionName) => {
    setfetchDetails(true);
    setShowZoneSelect(true);
    setCardsection(false);
    setShowApplyBtn(true);
    setSearchValue('');
    setApplyClicked(false);
    
  
    const { checked } = event.target;
    let updatedSelectedRegions = [...selectedRegions];
  
    console.log('line 443', regionName);
  
    // Initialize zoneArray and newRegions
    let zoneArray = [];
    let newRegions = [];
  
    if (regionName === 'select-all') {
      // Handle "Select All" logic
      if (checked) {
        // Select all regions
        newRegions = allregionnames;
        if (Array.isArray(apiResponse)) {
          zoneArray = apiResponse.flatMap(obj => Object.values(obj)[0]);
        } else if (typeof apiResponse === 'object' && apiResponse !== null) {
          zoneArray = Object.values(apiResponse).flat();
        }
      } else {
        // Deselect all regions
        newRegions = [];
        zoneArray = [];
      }
    } else {
      // Handle individual region logic
      if (checked) {
        // Add the region
        newRegions = [...updatedSelectedRegions, regionName];
      } else {
        // Remove the region
        newRegions = updatedSelectedRegions.filter(region => region !== regionName);
      }
  
      if (Array.isArray(apiResponse)) {
        const filteredData = apiResponse.filter(obj => newRegions.includes(Object.keys(obj)[0]));
        zoneArray = filteredData.flatMap(obj => Object.values(obj)[0]);
      } else if (typeof apiResponse === 'object' && apiResponse !== null) {
        const filteredData = Object.keys(apiResponse).filter(key => newRegions.includes(key));
        zoneArray = filteredData.flatMap(key => apiResponse[key]);
      }
    }
  
    setSelectedRegions(newRegions);
    setSelectedZone(zoneArray);
    setAllZones(zoneArray); // Update allZones to contain only the zones of the selected regions
    setfetchDetails(false);
  
    console.log('Updated selection:', regionName, zoneArray, newRegions);
  };
  
  const handleSelectAll = () => {
    setfetchDetails(true)
    setCardsection(false)
    setFetchingDetails(false);
    setShowApplyBtn(true);
    setApplyClicked(false);
    if (selectedRegions.length === allregionnames.length) {
      setSelectedRegions([]);
      setSelectedZone([]);
    } else {
      setSelectedRegions(allregionnames);
      const allZones = allregionZoneData.flatMap(regionData => Object.values(regionData)[0]);
      setSelectedZone(allZones.flat());
    }
  };




  
  const handleZoneChange = (event, zone) => {
    console.log('handle zone change triggered');
    setfetchDetails(true);
    setCardsection(false);
    setSearchValue('');
    setApplyClicked(false);
    const { value } = event.target;
    let newSelectedZones;


    const filteredZones = selectedRegions.flatMap(region =>
      allregionZoneData.flatMap(regionData => regionData[region] || [])
    );

    console.log('line 576', value,filteredZones, selectedZone)
  
    if (value === 'select-all') {
      const allZones = allregionZoneData.flatMap(regionData =>
        Object.values(regionData).flat()
      );
      newSelectedZones = selectedZone.length === filteredZones.length ? [] : filteredZones;
    } else {
      newSelectedZones = selectedZone.includes(zone)
        ? selectedZone.filter((selected) => selected !== zone)
        : [...selectedZone, zone];
    }
  
    setSelectedZone(newSelectedZones);
    setFetchingDetails(false);
    setShowApplyBtn(true);
  };
  



  useEffect(()=>{
    console.log("formateddate",Date)
  },[Date])



  console.log("firstusagedashboard",formattedSelectedContentOptions)
  console.log("secondusagedaashboaard",selectedRegions)
  console.log("thirdusagedashboard",selectedZone)
  console.log("Date",Date)



  // aapi caalls
  // const apiUrl = `http://hyundaianalytics.apprikart.com/digi_lens/dashboard_api/usage_dashboard/?username=${storedUsername}&permission=${JSON.stringify(storedPermission)}&device_type_array=${JSON.stringify(formattedSelectedContentOptions)}&zone=${JSON.stringify(selectedRegions)}&region=${JSON.stringify(selectedZone)}&start_date=${StartDate}&end_date=${Enddate}`;

  // console.log("API URL:", apiUrl);

  // useEffect(() => {
  //  axios.get(`http://hyundaianalytics.apprikart.com/digi_lens/dashboard_api/usage_dashboard/?username=${storedUsername}&permission=${JSON.stringify(storedPermission)}&device_type_array=${JSON.stringify(formattedSelectedContentOptions)}&zone=${JSON.stringify(selectedRegions)}&region=${JSON.stringify(selectedZone)}&start_date=${StartDate}&end_date=${Enddate}`)
  //   .then((response) => {
  //     console.log("usageResp", response.data);
     
  //   })
  //   .catch((error) => {
  //     console.error('Error fetching device status dashboard data:', error);
  //   });

  // },[])

  const formatDatesForAPI = (date) => {
    if (date) {
      return date.format('DD/MM/YYYY');
    } else {
      // Handle the case when date is null
      return ''; // Or any other default value that makes sense in your context
    }
  };






  useEffect(()=>{

    const fetchData = () => {
      setProdlistArray(productList)


  setFormattedSelectedContentOptions(allContentnamesTwo.slice(1))
      
    
    const apiUrl = `https://digilens.myhyundai.co.in/digi_lens/dashboard_api/usage_dashboard/?username=${storedUsername}&permission=${JSON.stringify(storedPermission)}&device_type_array=${JSON.stringify(formattedSelectedContentOptions)}&zone=${JSON.stringify(selectedRegions)}&region=${JSON.stringify(selectedZone)}&start_date=${formatDatesForAPI(startDate)}&end_date=${formatDatesForAPI(endDate)}&product_array=${JSON.stringify(productList)}`;

   
  
    console.log("API URLone:", apiUrl);
  
    axios.get(apiUrl)
      .then((response) => {
        console.log("apiResponseProducts",response)
      
        setDaywiseResponse(response.data.day_wise_usage);
     
       
        setModelwiseUsage(response.data.model_wise_usage);
        setSectionWiseUsage(response.data.section_wise_usage);
  
        setTotalVisits(response.data.total_visits)
        setTopModels(response.data.top_models)
        setBottomModels(response.data.bottom_models)
        setTopDealers(response.data.top_dealers)
        setBottomDealers(response.data.bottom_dealers)
       
        setTopSection(response.data.top_sections)
        setBottomSection(response.data.bottom_sections)
       
      })
      .catch((error) => {
        console.error('Error fetching usage dashboard data:', error);
      });
    };

    if(!showapplybtn){
      if(allZones?.length>0 && productList?.length>0){
      fetchData()
      }
     }

  },[selectedRegions,selectedZone,productList])


  const handleFetchdetails = () => {
    // Update state variables
  


    if (!selectedContentNine || selectedContentNine.length === 0) {
      setOpenSnackbarTwo(true);
      // return; // Exit the function to prevent the API call
    }

    setfetchDetails(false);
    setCardsection(true);
    setFetchingDetails(true);
    setShowApplyBtn(true);
    setApplyClicked(true);


 

    const apiUrl = `https://digilens.myhyundai.co.in/digi_lens/dashboard_api/usage_dashboard/?username=${storedUsername}&permission=${JSON.stringify(storedPermission)}&device_type_array=${JSON.stringify(formattedSelectedContentOptions)}&zone=${JSON.stringify(selectedRegions)}&region=${JSON.stringify(selectedZone)}&start_date=${formatDatesForAPI(startDate)}&end_date=${formatDatesForAPI(endDate)}&product_array=${JSON.stringify(selectedContentNine)}`;
  
    console.log("API URLTwo:", apiUrl);
  
    axios.get(apiUrl)
      .then((response) => {
        console.log("usageResp", response.data);
        console.log("day wise usaage",response.data.day_wise_usage)
        setDaywiseResponse(response.data.day_wise_usage);
        console.log("day wise response",response.data.day_wise_usage)
        console.log("model",response.data.model_wise_usage)
        console.log("section wise",response.data.section_wise_usage)
       
        setModelwiseUsage(response.data.model_wise_usage);
        setSectionWiseUsage(response.data.section_wise_usage);
        console.log("modelwiseUsage",modelWiseUsage);
        console.log("sectionWiseUsage",sectionWiseUsage)
        console.log("total visits",response.data.total_visits)
        console.log("topmodel",response.data.top_models)
      
        setTotalVisits(response.data.total_visits)
        setTopModels(response.data.top_models)
        setBottomModels(response.data.bottom_models)
        setTopDealers(response.data.top_dealers)
        setBottomDealers(response.data.bottom_dealers)
        setTopSection(response.data.top_sections)
        setBottomSection(response.data.bottom_sections)
        console.log("bottom models",bottomModels)
        console.log("top sections",topSection)
        
      })
      .catch((error) => {
        console.error('Error fetching usage dashboard data:', error);
      });

   }


   const handleClearFilters = () =>{


    console.log("Clearing filters...");
  
    setContentStatus(allContentnames.slice(1));
    setSelectedRegions(allregionnames);
    setSelectedZone(allZones);

    setStartDate(defaultStartDate);
    setEndDate(defaultEndDate);
    setStartDateTwo(null);
    setEndDateTwo(null);
    setSelectedContentNine(productList);
  
    console.log("allContentnames:", allContentnames.slice(1));
    console.log("allregionnames:", allregionnames);
    console.log("allZones:", allZones);
    console.log("selectedContenrNine",selectedContentNine);
    console.log("productlisrt",productList)
    setFormattedSelectedContentOptions(allContentnames.slice(1).map((ele)=>ele.toLowerCase()))
    setApplyClicked(false);
    setShowApplyBtn(false);
  


                // http://hyundaianalytics.apprikart.com/digi_lens/dashboard_api/usage_dashboard/?username=${storedUsername}&permission=${JSON.stringify(storedPermission)}&device_type_array=${JSON.stringify(formattedSelectedContentOptions)}&zone=${JSON.stringify(selectedRegions)}&region=${JSON.stringify(selectedZone)}&start_date=${formatDatesForAPI(startDate)}&end_date=${formatDatesForAPI(endDate)}&product_array=${JSON.stringify(productList)}
    const apiUrl = `https://digilens.myhyundai.co.in/digi_lens/dashboard_api/usage_dashboard/?username=${storedUsername}&permission=${JSON.stringify(storedPermission)}&device_type_array=${JSON.stringify(selectedContenttwo)}&zone=${JSON.stringify(regionnamestwo)}&region=${JSON.stringify(zoneNamestwo)}&start_date=${formatDatesForAPI(defaultStartDate)}&end_date=${formatDatesForAPI(defaultEndDate)}&product_array=${JSON.stringify(productList)}`;
  
    console.log("APIClear:", apiUrl);
  
    axios.get(apiUrl)
      .then((response) => {
        console.log("usageResp", response.data);
        console.log("day wise usaage",response.data.day_wise_usage)
        setDaywiseResponse(response.data.day_wise_usage);
        console.log("day wise response",response.data.day_wise_usage)
        console.log("model",response.data.model_wise_usage)
        console.log("section wise",response.data.section_wise_usage)
       
        setModelwiseUsage(response.data.model_wise_usage);
        setSectionWiseUsage(response.data.section_wise_usage);
        console.log("modelwiseUsage",modelWiseUsage);
        console.log("sectionWiseUsage",sectionWiseUsage)
        console.log("total visits",response.data.total_visits)
        console.log("topmodel",response.data.top_models)
      
        setTotalVisits(response.data.total_visits)
        setTopModels(response.data.top_models)
        setBottomModels(response.data.bottom_models)
        setTopDealers(response.data.top_dealers)
        setBottomDealers(response.data.bottom_dealers)
        setTopSection(response.data.top_sections)
        setBottomSection(response.data.bottom_sections)
        console.log("bottom models",bottomModels)
        console.log("top sections",topSection)
        
      })
      .catch((error) => {
        console.error('Error fetching usage dashboard data:', error);
      });




   }

  // useEffect(() => {
   
  // }, [formattedSelectedContentOptions,selectedRegions,selectedZone,startDate,endDate]);








  useEffect(()=>{
    console.log("formateedDates",Date)
  },[Date])
  



// graph code



    // card data

   
    useEffect(() => {
        const handleResize = () => {
          setIsMobile(window.innerWidth <= 769);
        };
    
        // Initial check for screen size
        handleResize();
    
        // Add event listener for window resize
        window.addEventListener('resize', handleResize);
    
        // Remove event listener on component unmount
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);


      // grph code

  


    const handlePrevBar = () => {
      const newIndex = Math.max(0, startIndex - itemsPerPage);
        setStartIndex(newIndex);
  };
  
  const handleNextBar = () => {
    const newIndex = Math.min( daywiseResponse && daywiseResponse.length - itemsPerPage, startIndex + itemsPerPage);
    setStartIndex(newIndex);
  };
  


//   const handlePrevLine = () => {
//     const newIndex = Math.max(0, startIndex - itemsPerPage);
//         setStartIndex(newIndex);
// };

// const handleNextLine = () => {
//   const newIndex = Math.min(daywiseResponse.length - itemsPerPage, startIndex + itemsPerPage);
//   setStartIndex(newIndex);
// };

  const getLineCount = () => {
    // Determine the number of bars to display based on screen size
    return window.innerWidth >= 768 ? 18 : 8;
  };


  const dates = daywiseResponse && daywiseResponse.map(item => item.date_label);
const usageCounts =  daywiseResponse && daywiseResponse.map(item => item.usage_count);




const getCurrentPageData = () => {
  const endIndex = Math.min(lineChartIndex + itemsPerPageLine, daywiseResponse.length);
  return daywiseResponse.slice(lineChartIndex, endIndex);
};

const handleNextLineClick = () => {
  setIsNextClicked(true);
  setIsPrevClicked(false);
  const newPageIndex = lineChartIndex + itemsPerPageLine;
  if (newPageIndex < daywiseResponse.length) {
    setLineChartIndex(newPageIndex);
  }
};

const handlePrevLineClick = () => {
  setIsPrevClicked(true);
  setIsNextClicked(false);
  const newPageIndex = Math.max(lineChartIndex - itemsPerPageLine, 0);
  setLineChartIndex(newPageIndex);
};

  // Prepare data for line chart
  const data = {
    labels: getCurrentPageData().map(item => item.date_label),
    datasets: [
      {
        label: 'Usage Count',
        data: getCurrentPageData().map(item => item.usage_count),
        fill: false,
        borderColor: '#676767',
        borderWidth: 2,
        pointStyle: 'circle',
        pointRadius: 2,
        pointBackgroundColor: '#962DFF',
        pointBorderColor: '#962DFF',
        pointBorderWidth: 5,
        tension: 0.1
      }
    ]
  };


 
// bar graph data
  // const getBarCount = () => {
  //   // Determine the number of bars to display based on screen size
  //   return window.innerWidth >= 768 ? 14 : 6;
  // };

  const getbarPercent = () =>{
    return window.inner > 769 ? 0.2 : 0.4;
  }




  const modelLabel = modelWiseUsage && modelWiseUsage.map(item => item.model_label);
  const usageCounting = modelWiseUsage && modelWiseUsage.map(item => item.usage_count);

  

  const getCurrentPageDataBar = () => {
    return modelWiseUsage.slice(barChartIndex, barChartIndex + itemsPerPageBar);
  }; 



const handleNextBars = () => {
  setIsNextClickedTwo(true);
  setIsPrevClickedTwo(false);
  const newPageIndex = Math.min(barChartIndex + itemsPerPageBar, modelWiseUsage.length - itemsPerPageBar);
  setBarChartIndex(newPageIndex);
};

const handlePrevBars = () => {
  setIsPrevClickedTwo(true);
  setIsNextClickedTwo(false);
  const newPageIndex = Math.max(barChartIndex - itemsPerPageBar, 0);
  setBarChartIndex(newPageIndex);
};

const bardata = {
  labels: getCurrentPageDataBar().map((item) => item.model_label),
  datasets: [
    {
      data: getCurrentPageDataBar().map((item) => item.usage_count),
      backgroundColor: '#4A3AFF',
      borderColor: '#D9D9D9',
      borderWidth: 0,
      borderRadius: 10,
      categoryPercentage: 1.0,
      barPercentage: getbarPercent,
    },
  ],
};


 useEffect(()=>console.log("Bar graph", bardata, barChartIndex),[bardata])

  const lineoptions = {
    interaction: {
        mode: 'index',
    },
    plugins: {
        legend: {
            display: false // Hide legend
        }
    },
    maintainAspectRatio: false,
    scales: {
        xAxes: [{ gridLines: { display: false } }],
        y: { beginAtZero: true, gridLines: { display: false } },
    },
    legend: { labels: { fontSize: 26 } },
    elements: {
        point: {
            radius: 0, // Set radius to 0 to hide other points
        }
        // we can customize line by adding line
    }
};


const baroptions = {
  interaction: {
    mode: 'nearest',
  },
  plugins: {
    legend: {
        display: false // Hide legend
    }
},
  maintainAspectRatio: false,
  scales: {
    xAxes: [{ gridLines: { display: false } }],
    y: { beginAtZero: true, gridLines: { display: false } },
  },
  legend: { labels: { fontSize: 26 } },
};

// start of pie chart


const piesModel = sectionWiseUsage && sectionWiseUsage.map(item => item.model_label);
const PiesUsage = sectionWiseUsage && sectionWiseUsage.map(item => item.percent_use);


const customColors =  [
  '#E89271', // Blue Violet
  '#962DFF', // Medium Slate Blue
  '#2D5BFF', // Slate Blue
  '#93AAFD', // Dark Slate Blue
  '#E0C6FD', // Indigo
  '#DBE9DD', // Medium Purple
  '#FDC6C6', 
];

// Create an array of background colors based on the number of data points
const backgroundColors = customColors.slice(0, piesModel.length);
const formattedData = piesModel.map(value => `${value} (values in %)`);
const pieData = {
  labels: formattedData,
  datasets: [
    {
      data: PiesUsage,
      backgroundColor: backgroundColors,
      // borderColor: borderColor,
      // borderWidth: 1
    }
  ],
  plugins: {
    legend: {
      labels: {
        formatter: function (context) {
          const value = context.dataset.data[context.dataIndex];
          return `${value}%`;
        }
      }
    }
  }
};
  useEffect(()=>console.log("pieData", pieData, PiesUsage),[pieData])

const pieOptions = {
  plugins: {
    legend: {
      display: false // Hide legend
    },
    
   
  },
  maintainAspectRatio: false
};



// forselect dropdowns




const [isModalOpen, setIsModalOpen] = useState(false);

const[searchValue,setSearchValue]=useState("");




  // const handleFilterClick = () => {
  //   setIsModalOpen(true);
  // };

  // const handleCloseModal = () => {
  //   setIsModalOpen(false);
  // };




  // mobile code

  const [isFilterOpen, setIsFilterOpen] = useState(false);


  const handleFilterClicks = () => {
    setIsFilterOpen(true);
  };

  const handleCloseFilter = () => {
    setIsFilterOpen(false);
  };


  const [accordionExpandeds, setAccordionExpandeds] = useState(false);
  const[ZoneAccordianExpanded,setZoneAccordianExpanded]=useState(false);
  const [regionAccordionExpanded, setRegionAccordionExpanded] = useState(false);

  const[modelExpanded,setModelExpanded]=useState(false);




// Function to toggle accordion state
const toggleRegionAccordion = () => {
  setRegionAccordionExpanded(!regionAccordionExpanded);
  setZoneAccordianExpanded(false);
  setAccordionExpandeds(false);
  setModelExpanded(false);  

};

const toggleModelAxccordian = () =>{
  setZoneAccordianExpanded(false);
  setAccordionExpandeds(false);
  setRegionAccordionExpanded(false);
  setModelExpanded(!modelExpanded);

}

const handleDeviceAccordianChange = () =>{
 setAccordionExpandeds(!accordionExpandeds);
 setRegionAccordionExpanded(false);
 setZoneAccordianExpanded(false);
 setModelExpanded(false);  
}


const handlezoneAccordianChange = () =>{
  setZoneAccordianExpanded(!ZoneAccordianExpanded);
  setAccordionExpandeds(false);
  setRegionAccordionExpanded(false);
  setModelExpanded(false);  

}





const handleRegionChanges = (event, regionName) => {
  setfetchDetails(true);
  setShowZoneSelect(true);
  setCardsection(false);
  setShowApplyBtn(true);
  setSearchValue('');
  setApplyClicked(false);



  setFetchingDetails(false);

  const { checked, value } = event.target;
  console.log('value in line 340', value);
  
  let zoneArray = [];

  if (Array.isArray(apiResponse)) {
    // Handle case when apiResponse is an array
    if (value.includes('select-all')) {
      // If 'select-all' is checked, include all zones
      zoneArray = apiResponse.flatMap(obj => Object.values(obj)[0]).flat();
    } else {
      // If individual regions are selected, filter the data accordingly
      const filteredData = apiResponse.filter(obj => {
        const key = Object.keys(obj)[0];
        return value.includes(key);
      });
      // Extract values (zones) associated with each selected region
      zoneArray = filteredData.flatMap(obj => Object.values(obj)[0]);
    }
  } else if (typeof apiResponse === 'object' && apiResponse !== null) {
    // Handle case when apiResponse is an object
    if (value.includes('select-all')) {
      // If 'select-all' is checked, include all zones
      zoneArray = Object.values(apiResponse).flat();
    } else {
      // If individual regions are selected, filter the data accordingly
      const filteredData = Object.keys(apiResponse).filter(key => value.includes(key));
      // Extract values (zones) associated with each selected region
      zoneArray = filteredData.flatMap(key => apiResponse[key]);
    }
  } else {
    // Handle unexpected response structure
    console.error('Unexpected apiResponse structure:', apiResponse);
    zoneArray = [];
  }
  
  console.log('line 338', value, zoneArray, selectedZone);
  
  // Update states accordingly
  setAllZones(zoneArray);
  setSelectedZone(zoneArray);




  // added menu props in select and added bottom 2 lines to remove scroll

  // const scrollTop = menuRef.current.scrollTop;
  // menuRef.current.scrollTop = scrollTop;


    const newSelectedRegions = typeof value === 'string' ? value.split(',') : value;

    if (newSelectedRegions.includes('select-all')) {
      setSelectedRegions(selectedRegions.length === allregionnames.length ? [] : allregionnames);
    } else {
      setSelectedRegions(newSelectedRegions);
    }

    setShowZoneSelect(newSelectedRegions.length > 0);
  };






  
const handleZoneChanges = (event) => {
  console.log('handle zone chaange triggered')
  setfetchDetails(true)
  setCardsection(false)
  setSearchValue('');
  console.log("line 406",event.target.value)
  setSelectedZone(event.target.value);

  setFetchingDetails(false);
  setShowApplyBtn(true);
  setApplyClicked(false);

  
  console.log('handle zone chaange triggered')
  setfetchDetails(true)
  setCardsection(false)
  console.log("line 406",event.target.value)
  setSelectedZone(event.target.value);

  setFetchingDetails(false);
  setShowApplyBtn(true);

  
  const { value } = event.target;
const newSelectedZones = typeof value === 'string' ? value.split(',') : value;

// Filter zones based on selectedRegions
const filteredZones = selectedRegions.flatMap(region =>
  allregionZoneData.flatMap(regionData => regionData[region] || [])
);

if (newSelectedZones.includes('select-all')) {
  console.log(value, selectedZone, filteredZones, selectedRegions, 'line 597');
  setSelectedZone(selectedZone.length === filteredZones.length ? [] : filteredZones);
} else {
  setSelectedZone(newSelectedZones);
}

  

};


  
  console.log("isMobile",isMobile);




  // handle moobile



  const handleCloseModal = () => {
    setIsModalOpen(false);
  };



  useEffect(() => {
    // Set initial background color of prev-btn to grey when component loads
    setIsPrevClicked(true);
  }, []);


  useEffect(() => {
    // Set initial background color of prev-btn to yellow when component loads
    setIsPrevClickedTwo(true);
  }, []);







  const handleSearch = (e) => {
    const searchInput = e.target.value;
    setSearchValue(searchInput);
  
    const apiUrl = `https://digilens.myhyundai.co.in/digi_lens/dashboard_api/usage_dashboard/?username=${storedUsername}&permission=${JSON.stringify(storedPermission)}&device_type_array=${JSON.stringify(formattedSelectedContentOptions)}&zone=${JSON.stringify(selectedRegions)}&region=${JSON.stringify(selectedZone)}&start_date=${formatDatesForAPI(startDate)}&end_date=${formatDatesForAPI(endDate)}&product_array=${JSON.stringify(productList)}`;
  
    // Function to update the state with the fetched data
    const updateStateWithData = (data) => {
      console.log("usageResp", data);
      setDaywiseResponse(data.day_wise_usage);
      setModelwiseUsage(data.model_wise_usage);
      setSectionWiseUsage(data.section_wise_usage);
      setTotalVisits(data.total_visits);
      setTopModels(data.top_models);
      setBottomModels(data.bottom_models);
      setTopDealers(data.top_dealers);
      setBottomDealers(data.bottom_dealers);
      setTopSection(data.top_sections);
      setBottomSection(data.bottom_sections);
    };
  
    // Check if the search input is not empty
    if (searchInput.trim() !== '') {
      const searchApiUrl = `${apiUrl}&dealer_code=${searchInput}`;
  
      console.log("API URLThree:", searchApiUrl);
  
      axios.get(searchApiUrl)
        .then((response) => {
          updateStateWithData(response.data);
        })
        .catch((error) => {
          console.error('Error fetching usage dashboard data:', error);
        });
    } else {
      // Handle the case where the search input is empty
      console.log('Search input is empty');
  
      axios.get(apiUrl)
        .then((response) => {
          updateStateWithData(response.data);
        })
        .catch((error) => {
          console.error('Error fetching original usage dashboard data:', error);
        });
    }
  };
  
  const [selectedContentNine, setSelectedContentNine] = useState([]);
  useEffect(() => {
    // Set initial state to select all items when component loads
    setSelectedContentNine(productList);
  }, [productList]);

  const handleContentChangenine = (event,regionName) => {

    
    setfetchDetails(true);
    setCardsection(false);
    setFetchingDetails(false);
    setShowApplyBtn(true);
    setSearchValue('');
    setApplyClicked(false);

    const newSelected = [...selectedContentNine];
    if (regionName === 'Select All') {
      if (contentareAllOptionsSelecteds()) {
        setSelectedContentNine([]);
      } else {
        setSelectedContentNine(productList);
      }
    } else {
      const index = newSelected.indexOf(regionName);
      if (index > -1) {
        newSelected.splice(index, 1);
      } else {
        newSelected.push(regionName);
      }
      setSelectedContentNine(newSelected);
    }
  };

  const handleContentChangenineMobile = (event) =>{

    setfetchDetails(true);
    setCardsection(false);
    setFetchingDetails(false);
    setShowApplyBtn(true);
    setSearchValue('');
    setApplyClicked(false);

    const value = event.target.value;
    if (value.includes('Select All')) {
      if (contentareAllOptionsSelecteds()) {
        setSelectedContentNine([]);
      } else {
        setSelectedContentNine(productList);
      }
    } else {
      setSelectedContentNine(value);
    }


  }

  const contentareAllOptionsSelecteds = () => {
    return selectedContentNine.length === productList.length;
  };

  console.log("selectedContentNine",selectedContentNine)


  
  const customStyles = {
    control: (provided) => ({
      ...provided,
      cursor: 'pointer',
    }),
    option: (provided) => ({
      ...provided,
      cursor: 'pointer',
    }),
    singleValue: (provided) => ({
      ...provided,
      cursor: 'pointer',
    }),
    placeholder: (provided) => ({
      ...provided,
      cursor: 'pointer',
    }),
    clearIndicator: (provided) => ({
      ...provided,
      cursor: 'pointer',
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      cursor: 'pointer',
    }),
  };
  
  


  return (
    <>
    <Navbar />
<Dashboard />
        <div>
    {!isMobile ? (
        <>



<div  className="device-status-full-contain-section-usage-dashboard">
          <div className="hyundai-content-section-usage-report">
          

          {/* ------zone div------ */}
          <div className="zone-flex-container-report">
                <div className='zone-div-report' style={{ marginBottom: "10px" }}>Device Type</div>
                <FormControl>
                  <Select
                    style={{
                      fontFamily: "Hyundai Sans Head Office regular",
                      textAlign: 'center',
                      fontSize: '15.711px',
                      fontStyle: 'normal',
                      fontWeight: 400,
                      lineHeight: 'normal',
                      backgroundColor: 'white',
                      height: '40px',
                      overflow: 'hidden', // Hide overflow
                      textOverflow: 'ellipsis', // Truncate text with ellipsis
                      whiteSpace: 'nowrap', // Prevent wrapping
                      width: '200px', // Set fixed width
                    }}
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    value={selectedContent}
                    onChange={handleContentChange}
                    renderValue={(selected) => {
                      if (contentareAllOptionsSelected()) {
                        return 'Select All';
                      } else {
                        console.log("line 1104",selected)
                        let newSelected = selected.map((ele)=>{
                          if(ele==="Kiosk"){
                            return "Digital Kiosk"
                          }
                          else
                          return ele
                        })
                        return newSelected.join(', ');
                      }
                    }}
                  >
                    {allContentnames.map((regionName) => (
                      <MenuItem key={regionName} value={regionName}>
                        <Checkbox checked={selectedContent.includes(regionName) || (regionName === 'Select All' && contentareAllOptionsSelected())} />
                        <ListItemText primary={regionName==="Kiosk"?"Digital Kiosk":regionName} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
        {/* -----------zone div----------------- */}

        <div className="zone-flex-container">
        <div className='zone-div' style={{ marginBottom: "10px" }}>Zone</div>
        <FormControl>
          <Select
            style={{
              fontFamily: "Hyundai Sans Head Office regular",
              textAlign: "center",
              fontSize: "15.711px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "normal",
              backgroundColor: "white",
              height: "40px",
              overflow: "hidden", // Hide overflow
              textOverflow: "ellipsis", // Truncate text with ellipsis
              whiteSpace: "nowrap", // Prevent wrapping
              width: "200px", // Set fixed width
            }}

            multiple
            value={selectedRegions}
            onChange={handleRegionChanges}
            MenuProps={{
              MenuListProps: {
                autoFocusItem: false,
                ref: menuRef,
              },
              PaperProps: {
                style: {
                  maxHeight: 300,
                  overflowY: 'auto',
                },
              },
            }}
            renderValue={(selected) => {
              if (selected.length === 1 && selected[0] !== "select-all") {
                return selected[0]; // Render the single selected region name
              } else if (selected.includes("select-all")) {
                return "Select All";
              } else if (selected.length === allregionnames.length && allregionnames.length > 1) {
                return "Select All";
              } else {
                return selected.join(", ");
              }
            }}
          >
            {allregionnames.length > 1 && (
              <MenuItem key="select-all" value="select-all">
                <Checkbox checked={selectedRegions.length === allregionnames.length} />
                <ListItemText primary="Select All" />
              </MenuItem>
            )}

            {allregionnames.map((regionName) => (
              <MenuItem key={regionName} value={regionName}>
                <Checkbox checked={selectedRegions.includes(regionName)} />
                <ListItemText primary={regionName} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      {/* -------------------------------------------------------------------------------- */}
      {showZoneSelect && (
        (selectedRegions.length || selectedRegions.includes("select-all") || selectedRegions?.length=== allregionnames.length) && selectedRegions.length > 0 && allregionZoneData.length > 0 && (
          <div className="zone-flex-container">
            <div className='zone-div' style={{ marginBottom: "10px" }}>Region</div>
            {/* // Render zone select only if showZoneSelect is true */}
            <FormControl>
              <Select
                multiple
                value={selectedZone}
                onChange={handleZoneChanges}
                MenuProps={{
                  MenuListProps: {
                    autoFocusItem: false,
                    ref: menuRef,
                  },
                  PaperProps: {
                    style: {
                      maxHeight: 300,
                      overflowY: 'auto',
                    },
                  },
                }}
                renderValue={(selected) => {
                  console.log('line 634',selected);
                  // const allZones = allregionZoneData.flatMap(regionData =>
                  //   Object.values(regionData).flat()
                  // );
                  // const allSelected = selected.length === allZones.length;
                  // return allSelected ? "Select All" : selected.join(", ");
                  if (selected.length === 1 && selected[0] !== "select-all") {
                    return selected[0]; // Render the single selected region name
                  } else if (selected.includes("select-all")) {
                    return "Select All";
                  } else if (selected.length >= allZones.length && allZones.length > 1) {
                    return "Select All";
                  } else {
                    return selected.join(", ");
                  }

                }}

                style={{
                  fontFamily: "Hyundai Sans Head Office regular",
                  textAlign: "center",
                  fontSize: "15.711px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "normal",
                  backgroundColor: "white",
                  height: "40px",
                  overflow: "hidden", // Hide overflow
                  textOverflow: "ellipsis", // Truncate text with ellipsis
                  whiteSpace: "nowrap", // Prevent wrapping
                  width: "200px", // Set fixed width
                }}
              >
                {allZones?.length > 1  &&  (
                  <MenuItem key="select-all" value="select-all">
                  <Checkbox checked={selectedZone.length >=allZones?.length} />
                    <ListItemText primary="Select All" />
                    </MenuItem>
                )}
                {/* {allregionZoneData.map((regionData) =>
                  Object.keys(regionData).map((regionName) =>
                    selectedRegions.includes(regionName) && (
                      regionData[regionName].map((zone) => (
                        <MenuItem key={zone} value={zone}>
                          <Checkbox checked={selectedZone.includes(zone)} />
                          <ListItemText primary={zone} />
                        </MenuItem>
                      ))
                    )
                  )
                )} */}
                {allZones?.map((zone)=>(
                    <MenuItem key={zone} value={zone}>
                    <Checkbox checked={selectedZone.includes(zone)} />
                    <ListItemText primary={zone} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        )
      )}


<div className='date-range-text-fexbox-container'>
<div className='zone-div' style={{marginBottom:"10px"}}>Date Range</div>

<RangePicker
 style={{ width: '100%', height: '40px',}}
  value={[startDate, endDate]}
  onChange={handleDateChange}
  format="DD-MM-YYYY" // Set the format to display
  onFocus={(e) =>{  e.preventDefault(); setStartDate();setEndDate()} }
  disabledDate={(currentDate) => {
    const today = moment().startOf('day');
    const march01 = moment('2024-03-01', 'YYYY-MM-DD').startOf('day');
    return (
      currentDate.isBefore(march01, 'day') ||
      currentDate.isAfter(today, 'day')
    );
  }}
   dropdownClassName="custom-range-picker-dropdown"
/>


</div>




{/* for model wise filter */}


<div className="zone-flex-container-report">
      <div className='zone-div-report' style={{ marginBottom: "10px" }}>Model</div>
      <FormControl>
        <Select
          style={{
            fontFamily: "Hyundai Sans Head Office regular",
            textAlign: 'center',
            fontSize: '15.711px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: 'normal',
            backgroundColor: 'white',
            height: '40px',
            overflow: 'hidden', // Hide overflow
            textOverflow: 'ellipsis', // Truncate text with ellipsis
            whiteSpace: 'nowrap', // Prevent wrapping
            width: '200px', // Set fixed width
          }}
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={selectedContentNine}
          onChange={handleContentChangenineMobile}
          renderValue={(selected) => {
            if (contentareAllOptionsSelecteds()) {
              return 'Select All';
            } else {
              return selected.join(', ');
            }
          }}
        >
          <MenuItem key="Select All" value="Select All">
            <Checkbox checked={contentareAllOptionsSelecteds()} />
            <ListItemText primary="Select All" />
          </MenuItem>
          {productList.map((regionName) => (
            <MenuItem key={regionName} value={regionName}>
              <Checkbox checked={selectedContentNine.includes(regionName)} />
              <ListItemText primary={regionName} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>



<div className='date-range-text-fexbox-container'>
<div className='zone-div' style={{marginBottom:"10px"}}>Dealer Filter</div>
<div style={{ width: '300px', margin: '0 auto' }}>
<Selects
 styles={customStyles}
      value={selectedOption}
      onChange={handleChange}
      options={dealerCodes}
      isSearchable
      placeholder="Select a dealer code"
      isClearable
     
    />
    </div>


</div>


<Snackbar
                open={openSnackbar}
                // autoHideDuration={6000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                className='snackbar'
            >
                <MuiAlert onClose={handleSnackbarClose} severity="warning" sx={{ width: '100%',backgroundColor:"grey",color:"black",zIndex:"100000",border:"1px solid red",backgroundColor:"#FFFFFF"}} className='snackbar'>
                 Please Select Date Range
                </MuiAlert>
            </Snackbar>



            
<Snackbar
                open={openSnackbartTwo}
                // autoHideDuration={6000}
                onClose={handleSnackbarCloseTwo}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                className='snackbar'
            >
                <MuiAlert onClose={handleSnackbarCloseTwo} severity="warning" sx={{ width: '100%',backgroundColor:"grey",color:"black",zIndex:"100000",border:"1px solid red",backgroundColor:"#FFFFFF"}} className='snackbar'>
                   Please Select Model
                </MuiAlert>
            </Snackbar>




{ startDate && endDate && showapplybtn && 
<div className="zone-flex-container">
      <div className='zone-div' style={{marginBottom:"10px",visibility:"hidden"}}>Button</div>
      {applyClicked ? (
                  <button onClick={handleClearFilters} className={`fetch-detailsbtntwo`}>Clear</button>
                ) : (
                  <button onClick={selectedOption?()=>handleChange(selectedOption):handleFetchdetails} className={`fetch-detailsbtn ${fetchingDetails ? 'fade-in' : 'fade-in'}`}>Apply</button>
                )}
</div>
}






        {/* ----seaarch---- */}

    
          



       </div>


       {/* <div className='search-section-statusreport' style={{marginTop:"20px"}}>
              <div className='search-text-div-staatusreport'>Search</div>
              <img src={customSearchIcon} alt="Search" className="custom-search-icon-staatusreport" />
              <input
                type="text"
                placeholder="Search by dealer code"
                className="searchbar-statusreports"
                onChange={handleSearch}
                value={searchValue}
              />

            </div> */}
       
          </div>





</>
    ):(

      // mobile version 
        <>




             
  {/* <Button variant="contained" onClick={handleFilterClicks}>Filter</Button> */}




  <div className="zone-flex-container" >
          {/* <div className='zone-div' style={{marginBottom:"10px",visibility:"hidden"}}>Date Range</div> */}

          <div className='date-range-text-fexbox-containers'>
<div className='zone-divs' style={{marginBottom:"10px",marginTop:"10px",marginLeft:"10px",marginRight:"10px"}}>Date Range</div>


<div style={{marginLeft:"10px",marginRight:"10px"}}>
<RangePicker
  style={{ width: '100%', height: '40px',cursor:"pointer" }}
  value={[startDate, endDate]}
  onChange={handleDateChange}
  format="DD-MM-YYYY" // Set the format to display
  onFocus={(e) =>{  e.preventDefault(); setStartDate();setEndDate()} }
  disabledDate={(currentDate) => {
    const today = moment().startOf('day');
    const march01 = moment('2024-03-01', 'YYYY-MM-DD').startOf('day');
    return (
      currentDate.isBefore(march01, 'day') ||
      currentDate.isAfter(today, 'day')
    );
  }}
  dropdownClassName="custom-range-picker-dropdown"

/>
</div>

</div>

            <div  className="filters-div" onClick={handleFilterClicks}>
             <div> < FilterListIcon /></div>
             <div> Filter</div>

             </div>
          </div>
          

{/* 
          <div className='search-section-statusreport' style={{marginTop:"20px"}}>
              <div className='search-text-div-staatusreport'>Search</div>
              <img src={customSearchIcon} alt="Search" className="custom-search-icon-staatusreport" />
              <input
                type="text"
                placeholder="Search by dealer code"
                className="searchbar-statusreports"
                onChange={handleSearch}
                value={searchValue}
              />

            </div> */}

      {/* add fetchdetaile to show  apply bth   */}


    
      {showapplybtn && (
                <div className="zone-flex-container">
           

                   {applyClicked ? (
                  <div className={`fetch-detailsbtnmobile ${fetchingDetails ? 'fade-in' : 'fade-in'}`} onClick={handleClearFilters} >Clear</div>
                ) : (
                  <div onClick={handleFetchdetails} className={`fetch-detailsbtnmobile ${fetchingDetails ? 'fade-in' : 'fade-in'}`}>Apply</div>
                )}
                 
                </div>
              )}

 
    

       

      {/* Filter Dialog */}
      <Dialog open={isFilterOpen} onClose={handleCloseFilter}
       className="mui-filter-modaal"
        
       PaperProps={{
        style: {
          backgroundColor: "#ffffff",
          boxShadow: "none",
         
          
          paddingBottom:"30px",
        
          width: "calc(100vw)", // Adjust the width by subtracting the desired left and right spacing
          // marginLeft: "10px", // Left spacing
          // marginRight: "10px", // Right spacing
      
          WebkitOverflowScrolling: "touch", // Enable smooth scrolling in iOS
          scrollbarWidth: "none", // Hide scrollbar for Firefox
          msOverflowStyle: "none", // Hide scrollbar for IE and Edge
          "&::-webkit-scrollbar": {
              display: "none" // Hide scrollbar for WebKit browsers
          }
         
        },
      }}
        >
         
 
         <div style={{display:"flex",flexDirection:"row",justifyContent: "space-between",paddingTop:"10px",paddingBottom:"40px",paddingLeft:"10px",paddingRight:"10px",position: "fixed",
      // top: 0,
      left: "10px",
      right: "10px",
    
      border:"1px solid grey",
      borderTop:"none",
      borderLeft:"none",
      borderRight:"none",
     backgroundColor:"#FFFFFF",
    borderTopRightRadius:"20px",
    borderTopLeftRadius:"20px",
      width:"100%",

      height:"40px",
      zIndex:"999",
      
    }}
    className="device-status-full-contain-sections"  >
    <div style={{marginTop:"10px"}}> Change Filters</div>
    <div onClick={handleCloseFilter} style={{textAlign:"right"}} className="close-btns">
      <div style={{width:"35px",height:"35px"}}  ><img src={closeIcon} style={{width:"100%",height:"100%",objectFit:"contain"}} /></div>
      
      </div> {/* Add onClick handler */}
  </div>

        <div  className="device-status-full-contain-section" style={{marginTop:"60px"}}>
      
      <div className="hyundai-content-sections">

        
        {/* ---------------------------------------------------------- */}
        
        <Accordion
  expanded={accordionExpandeds}
  onChange={handleDeviceAccordianChange}
  className={`accordian-container ${accordionExpandeds ? 'expanded' : ''}`}
>
  <AccordionSummary
    expandIcon={<ExpandMoreIcon />}
    aria-controls="panel1a-content"
    id="panel1a-header"
    className={`accordian-summary ${accordionExpandeds ? 'expanded' : ''}`}
  >
    <Typography>Device Type</Typography>
  </AccordionSummary>
  <AccordionDetails>
    <div style={{ display: "flex", flexDirection: "column" }}>
      {/* Render device type options */}
      {allContentnames.map((regionName) => (
        <div key={regionName} style={{ display: "flex", alignItems: "center", paddingTop: "5px" }}>
         <Checkbox
  checked={selectedContent.includes(regionName) || (regionName === 'Select All' && contentareAllOptionsSelected())}
  onChange={(event) => handleContentChanges(event)} // Pass the event object
  value={regionName}
/>
          {/* <div>{regionName}</div> */}
          <div>{regionName==="Kiosk"?"Digital Kiosk":regionName}</div>
        </div>
      ))}
    </div>
  </AccordionDetails>
</Accordion>



<Accordion
        expanded={ZoneAccordianExpanded}
        onChange={handlezoneAccordianChange}
        className={`accordian-container ${ZoneAccordianExpanded ? 'expanded' : ''}`}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="zone-panel-content"
          id="zone-panel-header"
          className={`accordian-summary ${ZoneAccordianExpanded ? 'expanded' : ''}`}
        >
          <Typography>Zone</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <div style={{ display: "flex", flexDirection: "column", overflowY: "scroll", minHeight: "10px" }} className="heightprop-check">
  {/* Conditionally render "Select All" checkbox */}
  {allregionnames.length > 1 && (
    <MenuItem key="select-all" value="select-all">
      <Checkbox
        checked={selectedRegions.length === allregionnames.length}
        onChange={(event) => handleRegionChange(event, 'select-all')}
        value="select-all"
      />
      <ListItemText primary="Select All" />
    </MenuItem>
  )}
  {/* Individual region checkboxes */}
  {allregionnames.map((regionName) => (
    <MenuItem key={regionName} value={regionName}>
      <Checkbox
        checked={selectedRegions.includes(regionName)}
        onChange={(event) => handleRegionChange(event, regionName)}
        value={regionName}
      />
      <ListItemText primary={regionName} />
    </MenuItem>
  ))}
</div>

        </AccordionDetails>
      </Accordion>

      <Accordion
  expanded={regionAccordionExpanded}
  onChange={toggleRegionAccordion}
  className={`accordian-container ${regionAccordionExpanded ? 'expanded' : ''}`}
>
  <AccordionSummary
    expandIcon={<ExpandMoreIcon />}
    aria-controls="region-panel-content"
    id="region-panel-header"
    className={`accordian-summary ${regionAccordionExpanded ? 'expanded' : ''}`}
  >
    <Typography>Region</Typography>
  </AccordionSummary>
  <AccordionDetails>
    <div className="zone-flex-container">
      <div style={{ display: "flex", flexDirection: "column" }}>
        {/* Conditionally render "Select All" checkbox */}
        {Array.isArray(allZones) && allZones.length > 1 && (
          <MenuItem key="select-all" value="select-all">
            <Checkbox
  checked={Array.isArray(selectedZone) && selectedZone.length >= allZones.length}
              onChange={handleZoneChange}
              value="select-all"
            />
            <ListItemText primary="Select All" />
          </MenuItem>
        )}
        {allZones && allZones.map((zone) => (
          <MenuItem key={zone} value={zone}>
            <Checkbox
              checked={selectedZone.includes(zone)}
              onChange={(event) => handleZoneChange(event, zone)}
              value={zone}
            />
            <ListItemText primary={zone} />
          </MenuItem>
        ))}
      </div>
    </div>
  </AccordionDetails>
</Accordion>



{/* for model */}


<Accordion
      expanded={modelExpanded}
      onChange={toggleModelAxccordian}
      className={`accordian-container ${modelExpanded ? 'expanded' : ''}`}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="region-panel-content"
        id="region-panel-header"
        className={`accordion-summary ${modelExpanded ? 'expanded' : ''}`}
      >
        <Typography>Model</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <div className="zone-flex-container">
          <MenuItem key="Select All" value="Select All">
            <Checkbox
              checked={contentareAllOptionsSelecteds()}
              onChange={(event) => handleContentChangenine(event, 'Select All')}
            />
            <ListItemText primary="Select All" />
          </MenuItem>
          {productList.map((regionName) => (
            <MenuItem key={regionName} value={regionName}>
              <Checkbox
                checked={selectedContentNine.includes(regionName)}
                onChange={(event) => handleContentChangenine(event, regionName)}
              />
              <ListItemText primary={regionName} />
            </MenuItem>
          ))}
        </div>
      </AccordionDetails>
    </Accordion>


<div style={{ width: '100%',height:"40px",margin: '20px auto'}} >
<Selects
      value={selectedOption}
      onChange={handleChange}
      options={dealerCodes}
      isSearchable
      placeholder="Select a dealer code"
      isClearable
     
    />
    </div>







        {/* -------------------------------------------------------------- */}

      

        {/* ------------------------------------------------------------------- */}
      
        {/* -------------------------------------------------------------------------------- */}
  





        {/* ------------------------------------------------------------------------------------------- */}

        {/* date range  */}
       
      </div>
      {/* --------------------------------------------------------- */}
     
    </div>
        <DialogContent>
        </DialogContent>
      </Dialog>


      {/* --2nd accordian-- */}




       
  
        </>

    )
}
</div>







<div>

<div className='usagedashboard-line-graph'>
  <div style={{ padding: '20px', border: '1px solid #FFFFFF', borderRadius: '4px', backgroundColor: '#FFFFFF' }}>
<div className='Totusagenum-flexbox-container'>

  <div className='img-text-container'  >
    <div>
    <div className='ads-img-section'></div>
    </div>
    <div className='overallusg-num'>
     Overall Usage 
    </div>
  </div>
 

  <div className='tot-visit'>
  {totaalVisits ? totaalVisits : 'No data found'}
</div>

</div>
  </div>
</div>

  
<div className='usagedashboard-line-graph'>
  {/* line graaaph */}
<div style={{ height: '100%', padding: '20px', border: '1px solid #FFFFFF', borderRadius: '4px', backgroundColor: '#FFFFFF' }} >
  <div className='linegraph-heading-flexboxcontainer' style={{borderBottom:"1px solid #E5E5EF"}}>
  <div>
    {/* <div className='statistics-text-div'>Activity</div> */}
    <div className='pie-statics-heading-containers' >
    <div className='usagebysection-text-div' >Overall Usage by date</div>
    </div>

  </div>
  <div className='prev-next-flexbox-container'>
  {lineChartIndex > 0 && (
  <div onClick={handlePrevLineClick} className={`prev-btn ${isPrevClicked ? 'clicked' : ''}`}>
    <img src={prev} alt="prev" className='prev-btn-img' />
  </div>
)}
{lineChartIndex + itemsPerPageLine < daywiseResponse.length && (
  <div onClick={handleNextLineClick} className={`next-btn ${isNextClicked ? 'clicked' : ''}`}>
    <img src={next} alt="next" className='next-btn-img' />
  </div>
)}
    </div>
            </div>
            <div style={{ height: '350px',paddingTop:"30px" }} >
                <Line
                    data={data}
                    options={lineoptions}
                />
            </div>
            
        </div>
  
</div>


<div className='usagedashboard-line-graph' >
  {/* line graaaph */}
<div style={{ height: '100%', padding: '20px', border: '1px solid #FFFFFF', borderRadius: '4px', backgroundColor: 'white' }} className='dynamic-full-page'>
<div className='bargraph-heading-flexboxcontainer' style={{borderBottom:"1px solid #E5E5EF"}}>
<div>
    {/* <div className='statistics-text-div'>Activity</div> */}
    <div className='pie-statics-heading-containers' >
    <div className='usagebysection-text-div' >Model Wise Usage</div>
    </div>

  </div>

  <div className='prev-next-flexbox-container'>
  {barChartIndex > 0 && (
  <div onClick={handlePrevBars} className={`prev-btnTwo ${isPrevClickedTwo ? 'clicked' : ''}`}>
    <img src={prev} alt="prev" className='prev-btn-img' />
  </div>
)}
{barChartIndex + itemsPerPageBar < modelWiseUsage.length && (
  <div onClick={handleNextBars} className={`next-btnTwo ${isNextClickedTwo ? 'clicked' : ''}`}>
    <img src={next} alt="next" className='next-btn-img' />
  </div>
)}
            </div>

  </div>
<div style={{ height: '350px',paddingTop:"30px" }}>
        <Bar data={bardata} options={baroptions} />
      </div>
           
        </div>
  
</div>


{/* cards */}


<div className='usagedashboard-line-graphs'>
<div className='top-five-usage-cards-section'>
  <div className='top-five-usage-cards-full-sections'>
    <div className='top-five-usage-cards-container'>


   <div className='top-five-flexbox-cardsect'>
  <div className='topfivecards-text-heading'>Top {topModels.length} Model Wise Usage</div>
  <div className='top-card-list-options'>
    {topModels && topModels.map((models, index) => (
      <div className='top-five-card-option' key={index}>
        <div className='top-five-usage-cards-title'>{models.product}</div>
        <div className='top-five-usage-cards-price'>{models.views}</div>
      </div>
    ))}
  </div>
  </div>



        
        <div className='top-five-flexbox-cardsect'>
          <div className='topfivecards-text-heading'>Bottom {bottomModels.length}  Model Wise Usage</div>
          <div className='top-card-list-options'>


          {bottomModels && bottomModels.map((models, index) => (
          
              <div className='top-five-card-option'  key={index} >
                <div className='top-five-usage-cards-title'>{models.product}</div>
                <div className='top-five-usage-cards-prices'>{models.views}</div>
              </div>
          ))}
       
          </div>
        </div>




      </div>
      </div>
      </div>
 
  </div>

  {/* pie graph */}

<div className='usagedashboard-pie-graph-section' >
  <div className='piegraph-flexbox-containers' >
  
<div className='pie-graph-container' >
  <div className='pie-statics-heading-container'>
   {/* <div className='statistics-text-div'>Statistics</div> */}
   <div className='usagebysection-text-div'>Usage by section</div>
   </div>
   <div className='piegraaph-specifications-flexbox-contaainer'  >
 <div className='piegraph-section'>

 <div style={{height:"250px",paddingTop:"20px"}} >
          <Pie data={pieData} options={pieOptions} />
          </div>

 </div>
 <div className='piegraph-data-detail-section' > 


 <div className="piegraph-details">
  <div className="column">
    {piesModel.slice(0, 3).map((name, index) => {
      const count = PiesUsage[index];
      return (
        <div key={index} className='bubble-data-detail-flexboxcontainer'>
          <div style={{ width: "20px", height: "20px", backgroundColor: customColors[index], border: "1px solid " + customColors[index], borderRadius: "50%" }}></div>
         
          <div>
            <span className="name">{name}</span> - <span className="count">{count}%</span>
          </div>

        </div>
      );
    })}
  </div>
  <div className="column">
    {piesModel.slice(3).map((name, index) => {
      const count = PiesUsage[index + 3]; // Adjust index for the second column
      return (
        <div key={index + 3} className='bubble-data-detail-flexboxcontainer'>
          <div style={{ width: "20px", height: "20px", backgroundColor: customColors[index + 3], border: "1px solid " + customColors[index + 3], borderRadius: "50%" }}></div>
         
          <div>
            <span className="name">{name}</span> - <span className="count">{count}%</span>
          </div>

        </div>
      );
    })}
  </div>
</div>



 

 </div>
</div>

</div>

{/* <div className='overall-usage-num-container'>
  <div className='ads-img-section'></div>
  <div className='overall-usaage-num-div'>{totaalVisits}</div>
  <div className='overall-usaage-text-div'>Overall usage no</div>

</div> */}

</div>


</div>
<div className='top-five-usage-cards-section'>
  <div className='top-five-usage-cards-full-section'>
    <div className='top-five-usage-cards-container'>

   
        {!selectedOption  && (
        <div className='top-five-flexbox-cards'>
          <div className='topfivecards-text-heading'>Top {topDealers.length} Dealer Wise Usage</div>
          <div className='top-card-list-options'>


          {topDealers && topDealers.map((models, index) => (
          
              <div className='top-five-card-option' key={index}>
                <div className='top-five-usage-cards-title'>{models.dealer_name} ({models.dealer_code})</div>
                <div className='top-five-usage-cards-price'>{models.views}</div>
              </div>

          ))}
       
          </div>
        </div>
        )}



{!selectedOption  && (
        <div className='top-five-flexbox-cards'>
          <div className='topfivecards-text-heading'>Bottom {bottomDealers.length} Dealers Wise Usage</div>
          <div className='top-card-list-options'>



            
          {bottomDealers && bottomDealers.map((models, index) => (
          
              <div className='top-five-card-option'  key={index}>
                <div className='top-five-usage-cards-title'>{models.dealer_name} ({models.dealer_code})</div>
                <div className='top-five-usage-cards-prices'>{models.views}</div>
              </div>

          ))}
       
          </div>
        </div>
)}

        
        <div className='top-five-flexbox-cards'>
          <div className='topfivecards-text-heading'>Top  Section Wise Usage</div>
          <div className='top-card-list-options'>
          


          {topSection && topSection.map((models, index) => (
              <div className='top-five-card-option' key={index}>
                <div className='top-five-usage-cards-title'>{models.section}</div>
                <div className='top-five-usage-cards-price'>{models.views}</div>
              </div>
          ))}
       
          </div>
        </div>

        
        <div className='top-five-flexbox-cards'>
          <div className='topfivecards-text-heading'>Bottom  Section Wise Usage</div>
          <div className='top-card-list-options'>
          


          {bottomSection && bottomSection.map((models, index) => (
              <div className='top-five-card-option' key={index}>
                <div className='top-five-usage-cards-title'>{models.section}</div>
                <div className='top-five-usage-cards-prices'>{models.views}</div>
              </div>
          ))}
       
          </div>
        </div>


    </div>
  </div>
</div>
</div>


      
    </>
  )
}

export default Usagedashboard


