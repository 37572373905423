import React, { useState, useEffect, useRef } from "react";
import { DatePicker } from "antd";

import "../Assets/components.css";

import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import chart from "../Assets/chart.png";
import FilterListIcon from "@mui/icons-material/FilterList";
import Navbar from "./Navbar";
import Dashboard from "./Dashboard";
import axios from "axios";
import closeIcon from "../Assets/Closeicons.png";
import { Modal, Box, Tabs, Tab } from "@mui/material";
import closethree from "../Assets/cancel.png";
import { Grid } from "@mui/material";
import { DialogTitle, DialogContent, Button } from "@mui/material";

import { useNavigate, useParams } from "react-router-dom";

const typeNames = [
  "3D Configurator",
  "Kiosk",
  "Centralized Waiting Period Display",
];

const DeviceStatus = () => {
  const { deviceType } = useParams();

  // const {deviceTypeTwo}=useParams();

  const [apiResponse, setApiResponse] = useState(null);
  const [apiResponseTwo, setApiResponseTwo] = useState(null);
  const [disclaimer, setDisclaimer] = useState("")
  const [Date, setDate] = useState(["2023-12-11", "2023-12-29"]);
  const [showapplybtn, setShowApplyBtn] = useState(false);
  const [internalSelectedType, setInternalSelectedType] =
    useState("3D Configurator");
  const [internalSelectedAppType, setInternalSelectedAppType] =
    useState("Virtukiosk");
  const [internalCategory, setInternalCategory] = useState();

  const [internalRegiontwo, setInternalRegiontwo] = useState("Select All");
  const [isMobile, setIsMobile] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [accordionExpandedtwo, setAccordionExpandedtwo] = useState(false);
  const [accordionExpandedthree, setAccordionExpandedthree] = useState(false);
  const [accordionExpandednine, setAccordionExpandednine] = useState(false);

  // --new one
  const [allregionZoneData, setAllRegionZoneData] = useState([]);
  const [allregionnames, setAllRegionNames] = useState([]);
  const [allzonenames, setAllZoneNames] = useState([]);
  const [selectedRegions, setSelectedRegions] = useState([]);
  const [selectedZone, setSelectedZone] = useState([]);
  const [showZoneSelect, setShowZoneSelect] = useState(true); // New state
  const [responseData, setResponseData] = useState(null);
  const [fetchdetaile, setfetchDetails] = useState(true);
  const [cardsection, setCardsection] = useState(false);

  const [allZones, setAllZones] = useState(null);
  const [newResponsetwo, setNewResppnseTwo] = useState(null);

  const [fetchingDetails, setFetchingDetails] = useState(false);

  const menuRef = useRef(null);
  const [menuLoaded, setMenuLoaded] = useState(false);

  const [version, setVersion] = useState("");
  const [contentText, setContentText] = useState("");

  const [appVersion, setAppVersion] = useState("");
  const [appText, setAppText] = useState("");
  const [applyClicked, setApplyClicked] = useState(false);

  const [offline, setOffine] = useState();

  const allContentnames = [
    "Select All",
    "3D Configurator",
    "Kiosk",
    "Centralized Waiting Period Display",
  ];

  const [selectedContent, setContentStatus] = useState([]);
  const [selecedContenttwo, setSelectedContentTwo] = useState(
    allContentnames.slice(1)
  );
  const [formattedSelectedContentOptions, setFormattedSelectedContentOptions] =
    useState([]);

  const [regionnamestwo, setRegionNamestwo] = useState([]);
  const [zoneNamestwo, setZoneNamestwo] = useState([]);

  const [tabIndex, setTabIndex] = useState(0);
  const [tabIndexOne, setTabIndexOne] = useState(0);
  const [apiData, setApiData] = useState(null);
  const [apiDataTwo, setApiDataTwo] = useState(null);

  const contentareAllOptionsSelected = () => {
    return selectedContent.length === allContentnames.length - 1;
  };

  console.log("selectedContent", selectedContent);

  useEffect(() => {
    const fetchData = () => {
      console.log("useEffect is executing...");
      const NewApi = `https://digilens.myhyundai.co.in/digi_lens/dashboard_api/device_status_dashboard_data/?username=${storedUsername}&permission=${JSON.stringify(
        storedPermission
      )}&zone=${JSON.stringify(selectedRegions)}&region=${JSON.stringify(
        selectedZone
      )}&device_type_array=${JSON.stringify(selectedContent)}`;
      console.log("NewApi", NewApi);

      // Set fetchingDetails to true when fetching starts
      setFetchingDetails(true);

      axios
        .get(NewApi)
        .then((response) => {
          console.log("responsestaatus", response);
          setResponseData(response.data);
        })
        .catch((error) => {
          console.error("Error fetching device status dashboard data:", error);
        })
        .finally(() => {
          // Set fetchingDetails to false when fetching completes
          setFetchingDetails(false);
        });
    };

    if (!showapplybtn) {
      if(allZones?.length>0 ){
      fetchData();
      }
    }
  }, [selectedRegions, selectedZone, selectedContent, showapplybtn]);

  const fetchDataTwo = () => {
    const NewApiTwo = `https://digilens.myhyundai.co.in/digi_lens/dashboard_api/dashboard_info/?username=${storedUsername}&permission=${JSON.stringify(
      storedPermission
    )}&device_type_array=${JSON.stringify(selectedContent)}`;
    console.log("NewApiTwo", NewApiTwo);

    axios
      .get(NewApiTwo)
      .then((response) => {
        console.log("deviceresponse", response);
        setNewResppnseTwo(response.data);
        setOffine(response.data.offline);
        setDisclaimer(response.data.device_activity)

        // Handle content version
        const contentVersion = response.data.content_version;
        const contentVersionDetails = Object.keys(contentVersion).map((key) => {
          return {
            type: key,
            release_date: contentVersion[key].description,
            description: contentVersion[key].release_date,
          };
        });

        // Assuming you want to store these details in an array or an object
        setContentVersionDetails(contentVersionDetails);

        // Handle app version similarly if needed
        const appVersion = response.data.app_version;
        const appVersionDetails = Object.keys(appVersion).map((key) => {
          return {
            type: key,
            release_date: appVersion[key].release_date,
            description: appVersion[key].description,
            versions: appVersion[key].version,
          };
        });

        setAppVersionDetails(appVersionDetails);
      })
      .catch((error) => {
        console.error("Error fetching device status dashboard data:", error);
      })
      .finally(() => {
        // Set fetchingDetails to false when fetching completes
      });
  };

  // Example state hooks
  const [contentVersionDetails, setContentVersionDetails] = useState([]);
  const [appVersionDetails, setAppVersionDetails] = useState([]);

  useEffect(() => {
    if (!showapplybtn) {
      if(selectedContent.length>0){
  
      fetchDataTwo();
      }
  
    }
  }, [selectedContent]); // Empty dependency array ensures this effect runs only once

  // for mui popup
  useEffect(() => {
    if (menuRef.current && !menuLoaded) {
      // Scroll to the top only if the menu is loaded for the first time
      menuRef.current.scrollTop = 0;
      setMenuLoaded(true);
    }
  }, [menuLoaded]);

  const handleFilterClicks = () => {
    setIsFilterOpen(true);
  };

  const handleCloseFilter = () => {
    setIsFilterOpen(false);
  };

  const handleCloseFilterTwo = () => {
    // window.alert("hii")
    setIsFilterOpenTwo(true);

    const HistoryApi = `https://digilens.myhyundai.co.in/digi_lens/dashboard_api/get_version_history/?username=${storedUsername}&permission=${JSON.stringify(
      storedPermission
    )}&device_type_array=${JSON.stringify(
      selectedContent
    )}&update_type=content`;
    console.log("HistoryApi", HistoryApi);

    axios
      .get(HistoryApi)
      .then((response) => {
        console.log("HistoryApi", response);
        setApiData(response.data);
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  };

  const handleCloseFilterThree = () => {
    setIsFilterOpenThree(true);

    const AppHistoryApi = `https://digilens.myhyundai.co.in/digi_lens/dashboard_api/get_version_history/?username=${storedUsername}&permission=${JSON.stringify(
      storedPermission
    )}&device_type_array=${JSON.stringify(selectedContent)}&update_type=app`;
    console.log("HistoryApi", AppHistoryApi);

    axios
      .get(AppHistoryApi)
      .then((response) => {
        console.log("HistoryApi", response);
        setApiDataTwo(response.data);
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  };

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const handleTabChangeTwo = (event, newValue) => {
    setTabIndexOne(newValue);
  };

  console.log("TABINDEX",tabIndex)

  const renderTabContent = (newKey) => {
    let key = ""
    if(newKey==="Digital Kiosk")
      key = "vertical"
    else if(newKey==="3D Configurator")
      key = 'horizontal'
    else
    key = 'central display'

    const descriptions = apiData[key].map((item) => item.description);
    const releaseDates = apiData[key].map((item) => item.release_date);

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Grid item xs={6}>
          <div style={{ marginTop: "10px" }}>
            <div style={{ color: "#9A9A9A" }}>Content</div>
            {descriptions.map((description, index) => (
              <Box key={index} mb={2} mt={2} pr={4}>
                <div>{description}</div>
              </Box>
            ))}
          </div>
        </Grid>
        <Grid item xs={6}>
          <div style={{ marginTop: "10px" }}>
            <div style={{ color: "#9A9A9A" }}>Date</div>
            {releaseDates.map((date, index) => (
              <Box key={index} mb={2} mt={2}>
                <div>{date}</div>
              </Box>
            ))}
          </div>
        </Grid>
      </div>
    );
  };

 
  const getTabLabels = () => {
    if (!apiData) return [];
    
    console.log("line 312", Object.keys(apiData));
    
    // Define the desired order of tab labels
    const desiredOrder = ["3D Configurator", "Digital Kiosk", "Centralized Display Monitor"];
    
    // Generate the tab labels based on the keys in apiData
    const tabNames = Object.keys(apiData).map((ele) => {
      if (ele === "central display") return "Centralized Display Monitor";
      else if (ele === "vertical") return "Digital Kiosk";
      else return "3D Configurator";
    });
    
    console.log("line 321", tabNames);
    
    // Sort the tabNames array based on the desired order
    const sortedTabNames = tabNames.sort((a, b) => desiredOrder.indexOf(a) - desiredOrder.indexOf(b));
    
    return sortedTabNames;
  };
  
  // <Tabs value={tabIndex} onChange={handleTabChange}>
  //   {getTabLabels().map((label, index) => (
  //     <Tab label={label} key={index} />
  //   ))}
  // </Tabs>



const getTabLabelsTwo= () => {
  if (!apiDataTwo) return [];

  // Define the desired order of tab labels
  const desiredOrder = ["3D Configurator", "Digital Kiosk", "Centralized Display Monitor"];
  
  // Generate the tab labels based on the keys in apiDataTwo
  const tabNames = Object.keys(apiDataTwo).map((ele) => {
    if (ele === "horizontal") return "3D Configurator";
    else if (ele === "vertical") return "Digital Kiosk";
    else return "Centralized Display Monitor";
  });

  console.log("line 321", tabNames);
  
  // Sort the tabNames array based on the desired order
  const sortedTabNames = tabNames.sort((a, b) => desiredOrder.indexOf(a) - desiredOrder.indexOf(b));
  
  return sortedTabNames;
};

{/* <Tabs value={tabIndex} onChange={handleTabChange}>
  {getTabLabelsTwo().map((label, index) => (
    <Tab label={label} key={index} />
  ))}
</Tabs> */}


  const renderTabContentTwo = (newKey) => {
    let key = ""
    if(newKey==="Digital Kiosk")
      key = "vertical"
    else if(newKey==="3D Configurator")
      key = 'horizontal'
    else
    key = 'central display'

    console.log("line 339", apiData, key)
    const descriptions = apiDataTwo[key]?.map((item) => item.description);
    const releaseDates = apiDataTwo[key]?.map((item) => item.release_date);

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Grid item xs={6}>
          <div style={{ marginTop: "10px" }}>
            <div style={{ color: "#9A9A9A" }}>Content</div>
            {descriptions.map((description, index) => (
              <Box key={index} mb={2} mt={2} pr={4}>
                <div>{description}</div>
              </Box>
            ))}
          </div>
        </Grid>
        <Grid item xs={6}>
          <div style={{ marginTop: "10px" }}>
            <div style={{ color: "#9A9A9A" }}>Date</div>
            {releaseDates.map((date, index) => (
              <Box key={index} mb={2} mt={2}>
                <div>{date}</div>
              </Box>
            ))}
          </div>
        </Grid>
      </div>
    );
  };

  const handleDialogCloseTwo = () => {
    setIsFilterOpenTwo(false);
  };

  const handleDialogCloseThree = () => {
    setIsFilterOpenThree(false);
  };

  const [accordionExpandeds, setAccordionExpanded] = useState(false);
  const [ZoneAccordianExpanded, setZoneAccordianExpanded] = useState(false);
  const [regionAccordionExpanded, setRegionAccordionExpanded] = useState(false);

  // Function to toggle accordion state
  const toggleRegionAccordion = () => {
    setRegionAccordionExpanded(!regionAccordionExpanded);
    setZoneAccordianExpanded(false);
    setAccordionExpanded(false);
  };

  const handleDeviceAccordianChange = () => {
    setAccordionExpanded(!accordionExpandeds);
    setRegionAccordionExpanded(false);
    setZoneAccordianExpanded(false);
  };

  const handlezoneAccordianChange = () => {
    setZoneAccordianExpanded(!ZoneAccordianExpanded);
    setAccordionExpanded(false);
    setRegionAccordionExpanded(false);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 769);
    };

    // Initial check for screen size
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // modal
  const handleFilterClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleTypeChange = (event) => {
    setfetchDetails(true);
    setCardsection(false);

    setFetchingDetails(false);

    setShowApplyBtn(true);
    const selectedType = event.target.value;

    if (selectedType === "Select All") {
      setInternalSelectedType(typeNames.slice(0));
    } else {
      setInternalSelectedType(selectedType);
    }
  };

  const handleContentChange = (event) => {
    setTabIndex(0);
    setTabIndexOne(0);
    setfetchDetails(true);
    setCardsection(false);

    setFetchingDetails(false);

    setShowApplyBtn(true);

    setApplyClicked(false);

    const { value } = event.target;

    if (value.includes("Select All")) {
      handleSelectContent();
    } else {
      const contentupdatedValue = value.filter((val) => val !== "Select All");
      const lowercaseOptions = contentupdatedValue.map((option) =>
        option.toLowerCase()
      );
      setContentStatus(contentupdatedValue);
      setFormattedSelectedContentOptions(lowercaseOptions);
    }
  };

  const handleSelectContent = () => {
    setfetchDetails(true);
    setCardsection(false);
    setFetchingDetails(false);
    setShowApplyBtn(true);

    if (selectedContent.length === allContentnames.length - 1) {
      setContentStatus([]);
      const lowercaseOptions = [];
      setFormattedSelectedContentOptions(lowercaseOptions);
    } else {
      const ContupdatedValue = allContentnames.slice(1);
      const lowercaseOptions = ContupdatedValue.map((option) =>
        option.toLowerCase()
      ); // Convert to lowercase
      setContentStatus(ContupdatedValue);
      setFormattedSelectedContentOptions(lowercaseOptions); // Set lowercase options
    }
  };

  const handleTypeChanges = (value) => {
    const isSelected = internalSelectedType.includes(value);
    setInternalSelectedType(value);
  };

  const handleContentChanges = (event) => {
    setTabIndex(0);
    setTabIndexOne(0);
    setfetchDetails(true);
    setCardsection(false);
    setFetchingDetails(false);
    setShowApplyBtn(true);
    // setAccordionExpanded(false);

    const { value, checked } = event.target;

    if (value === "Select All") {
      handleSelectContent(checked);
    } else {
      // If "Select All" is checked, uncheck it
      const contentupdatedValue = selectedContent.includes("Select All")
        ? selectedContent.filter((val) => val !== "Select All")
        : selectedContent;

      // Update the selected content based on the checkbox status
      const updatedContent = checked
        ? [...contentupdatedValue, value]
        : contentupdatedValue.filter((val) => val !== value);

      // Convert updatedContent to lowercase
      const lowercaseOptions = updatedContent.map((option) =>
        option.toLowerCase()
      );

      // Update the state with the new selected content
      setContentStatus(updatedContent);
      setFormattedSelectedContentOptions(lowercaseOptions);
    }
  };

  const handleFetchdetailsTwo = () => {
    // Update state variables
    setfetchDetails(false);
    setCardsection(true);
    setApplyClicked(true);

    setFetchingDetails(true);

    fetchDataTwo();
    // fetchDataTwo();

    const NewApiDetailng = `https://digilens.myhyundai.co.in/digi_lens/dashboard_api/device_status_dashboard_data/?username=${storedUsername}&permission=${JSON.stringify(
      storedPermission
    )}&zone=${JSON.stringify(selectedRegions)}&region=${JSON.stringify(
      selectedZone
    )}&device_type_array=${JSON.stringify(selectedContent)}`;
    console.log("NewdetailingApi", NewApiDetailng);
    axios
      .get(NewApiDetailng)
      .then((response) => {
        console.log("Final Response", response.data);
        setResponseData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching device status dashboard data:", error);
      });
  };

  const storedPermission = JSON.parse(localStorage.getItem("permission"));
  const storedUsername = localStorage.getItem("username");

  useEffect(() => {
    callRegionApi();
  }, []);

  const callRegionApi = () => {
    const zones = JSON.parse(localStorage.getItem("selectedZone"));
    const regions = JSON.parse(localStorage.getItem("selectedRegions"));
    const content = JSON.parse(localStorage.getItem("selectedContent"));
    localStorage.removeItem("selectedZone");
    localStorage.removeItem("selectedRegions");
    localStorage.removeItem("selectedContent");
    if (content) setContentStatus(content);
    else setContentStatus(allContentnames.slice(1));
    const regionApi = `https://digilens.myhyundai.co.in/digi_lens/dashboard_api/get_region_zone_list/?username=${storedUsername}&permission=${JSON.stringify(
      storedPermission
    )}`;
    console.log("newRegionApi", regionApi);

    axios
      .get(regionApi)
      .then((response) => {
        console.log("line 379 triggered");
        console.log("Response from device status API:", response);
        setApiResponse(response.data);
        if (Array.isArray(response.data)) {
          // Handle response with array structure
          const regionNames = response.data.map(
            (singleRegion) => Object.keys(singleRegion)[0]
          );
          setAllRegionNames(regionNames);
          setAllRegionZoneData(response.data);
          if (regions) {
            setSelectedRegions(regions);
          } else setSelectedRegions(regionNames);
          setRegionNamestwo(regionNames);

          // Filter zones for selected regions and flatten the array
          console.log(response.data)
          let newZoness = response.data.filter((ele) => 
            regions?.some(region => ele[region])
          );
          console.log(newZoness)
          const extractValues = (data) => {
            return data.flatMap(zone => Object.values(zone).flat());
          };
          let newZone = extractValues(newZoness)
          console.log(newZone)
          const allZones = response.data.flatMap(
            (regionData) => Object.values(regionData)[0]
          );
          if (zones) {
            setSelectedZone(zones);
            setAllZones(newZone)
          } else {setSelectedZone(allZones);
           setAllZones(allZones);}
          // setZoneNamestwo(allZones);
        } else if (typeof response.data === "object") {
          // Handle response with object structure
          const regionNames = Object.keys(response.data);
          setAllRegionNames(regionNames);
          setAllRegionZoneData(
            regionNames.map((regionName) => ({
              [regionName]: response.data[regionName],
            }))
          );

          // Set selected regions and corresponding zones
          setSelectedRegions(regions?regions:regionNames);
          setRegionNamestwo(regionNames);
          console.log(regions, regionNames)
          // Filter zones for selected regions and flatten the array
          const allZones = regionNames.flatMap(
            (regionName) => response.data[regionName]
          );
          setSelectedZone(zones?zones:allZones);
           setAllZones(allZones);
          setZoneNamestwo(allZones);
        } else {
          // Handle unexpected response structure
          console.error("Unexpected response structure:", response);
        }
      })
      .catch((error) => {
        console.error("Error fetching region and zone data:", error);
      });
  };

  console.log("first", internalSelectedType);
  console.log("second one", selectedRegions);
  console.log("third one", selectedZone);

  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [isFilterOpenTwo, setIsFilterOpenTwo] = useState(false);
  const [isFilterOpenThree, setIsFilterOpenThree] = useState(false);

  const handleRegionChange = (event, regionName) => {
    setfetchDetails(true);
    setShowZoneSelect(true);
    setCardsection(false);
    setShowApplyBtn(true);
    setApplyClicked(false);

    const { checked } = event.target;
    let updatedSelectedRegions = [...selectedRegions];

    console.log("line 443", regionName);

    // Initialize zoneArray and newRegions
    let zoneArray = [];
    let newRegions = [];

    if (regionName === "select-all") {
      // Handle "Select All" logic
      if (checked) {
        // Select all regions
        newRegions = allregionnames;
        if (Array.isArray(apiResponse)) {
          zoneArray = apiResponse.flatMap((obj) => Object.values(obj)[0]);
        } else if (typeof apiResponse === "object" && apiResponse !== null) {
          zoneArray = Object.values(apiResponse).flat();
        }
      } else {
        // Deselect all regions
        newRegions = [];
        zoneArray = [];
      }
    } else {
      // Handle individual region logic
      if (checked) {
        // Add the region
        newRegions = [...updatedSelectedRegions, regionName];
      } else {
        // Remove the region
        newRegions = updatedSelectedRegions.filter(
          (region) => region !== regionName
        );
      }

      if (Array.isArray(apiResponse)) {
        const filteredData = apiResponse.filter((obj) =>
          newRegions.includes(Object.keys(obj)[0])
        );
        zoneArray = filteredData.flatMap((obj) => Object.values(obj)[0]);
      } else if (typeof apiResponse === "object" && apiResponse !== null) {
        const filteredData = Object.keys(apiResponse).filter((key) =>
          newRegions.includes(key)
        );
        zoneArray = filteredData.flatMap((key) => apiResponse[key]);
      }
    }

    setSelectedRegions(newRegions);
    setSelectedZone(zoneArray);
    setAllZones(zoneArray); // Update allZones to contain only the zones of the selected regions
    setfetchDetails(false);

    console.log("Updated selection:", regionName, zoneArray, newRegions);
  };

  useEffect(() => {
    console.log("selectedRegions updated", selectedRegions);
  }, [selectedRegions]);

  const handleRegionChanges = (event, regionName) => {
    setfetchDetails(true);
    setShowZoneSelect(true);
    setCardsection(false);
    setShowApplyBtn(true);

    setApplyClicked(false);

    setFetchingDetails(false);

    const { checked, value } = event.target;
    console.log("value in line 340", value);

    let zoneArray = [];

    if (Array.isArray(apiResponse)) {
      // Handle case when apiResponse is an array
      if (value.includes("select-all")) {
        // If 'select-all' is checked, include all zones
        zoneArray = apiResponse.flatMap((obj) => Object.values(obj)[0]).flat();
      } else {
        // If individual regions are selected, filter the data accordingly
        const filteredData = apiResponse.filter((obj) => {
          const key = Object.keys(obj)[0];
          return value.includes(key);
        });
        // Extract values (zones) associated with each selected region
        zoneArray = filteredData.flatMap((obj) => Object.values(obj)[0]);
      }
    } else if (typeof apiResponse === "object" && apiResponse !== null) {
      // Handle case when apiResponse is an object
      if (value.includes("select-all")) {
        // If 'select-all' is checked, include all zones
        zoneArray = Object.values(apiResponse).flat();
      } else {
        // If individual regions are selected, filter the data accordingly
        const filteredData = Object.keys(apiResponse).filter((key) =>
          value.includes(key)
        );
        // Extract values (zones) associated with each selected region
        zoneArray = filteredData.flatMap((key) => apiResponse[key]);
      }
    } else {
      // Handle unexpected response structure
      console.error("Unexpected apiResponse structure:", apiResponse);
      zoneArray = [];
    }

    console.log("line 338", value, zoneArray, selectedZone);

    // Update states accordingly
    setAllZones(zoneArray);
    setSelectedZone(zoneArray);

    const newSelectedRegions =
      typeof value === "string" ? value.split(",") : value;

    if (newSelectedRegions.includes("select-all")) {
      setSelectedRegions(
        selectedRegions.length === allregionnames.length ? [] : allregionnames
      );
    } else {
      setSelectedRegions(newSelectedRegions);
    }

    setShowZoneSelect(newSelectedRegions.length > 0);
  };

  const handleZoneChange = (event, zone) => {
    console.log("handle zone change triggered");
    setfetchDetails(true);
    setCardsection(false);
    setApplyClicked(false);

    const { value } = event.target;
    let newSelectedZones;

    const filteredZones = selectedRegions.flatMap((region) =>
      allregionZoneData.flatMap((regionData) => regionData[region] || [])
    );

    console.log("line 576", value, filteredZones, selectedZone);

    if (value === "select-all") {
      const allZones = allregionZoneData.flatMap((regionData) =>
        Object.values(regionData).flat()
      );
      newSelectedZones =
        selectedZone.length === filteredZones.length ? [] : filteredZones;
    } else {
      newSelectedZones = selectedZone.includes(zone)
        ? selectedZone.filter((selected) => selected !== zone)
        : [...selectedZone, zone];
    }

    setSelectedZone(newSelectedZones);
    setFetchingDetails(false);
    setShowApplyBtn(true);
  };

  const handleZoneChanges = (event) => {
    console.log("handle zone chaange triggered");
    setfetchDetails(true);
    setCardsection(false);
    console.log("line 406", event.target.value);
    setSelectedZone(event.target.value);

    setFetchingDetails(false);
    setShowApplyBtn(true);
    setApplyClicked(false);

    console.log("handle zone chaange triggered");
    setfetchDetails(true);
    setCardsection(false);
    console.log("line 406", event.target.value);
    setSelectedZone(event.target.value);

    setFetchingDetails(false);
    setShowApplyBtn(true);

    const { value } = event.target;
    const newSelectedZones =
      typeof value === "string" ? value.split(",") : value;

    // Filter zones based on selectedRegions
    const filteredZones = selectedRegions.flatMap((region) =>
      allregionZoneData.flatMap((regionData) => regionData[region] || [])
    );

    if (newSelectedZones.includes("select-all")) {
      console.log(
        value,
        selectedZone,
        filteredZones,
        selectedRegions,
        "line 597"
      );
      setSelectedZone(
        selectedZone.length === filteredZones.length ? [] : filteredZones
      );
    } else {
      setSelectedZone(newSelectedZones);
    }
  };

  console.log("internalSelectedType", internalSelectedType);
  console.log("internalCategory", internalCategory);

  console.log("isMobile", isMobile);
  console.log("internalSelectedAppType", internalSelectedAppType);

  console.log(
    "all region zone data",
    allregionZoneData.flatMap((regionData) => Object.values(regionData).flat())
      .length
  );

  const handleSelectAll = () => {
    if (selectedRegions.length === allregionnames.length) {
      setSelectedRegions([]);
      setSelectedZone([]);
    } else {
      setSelectedRegions(allregionnames);
      const allZones = allregionZoneData.flatMap(
        (regionData) => Object.values(regionData)[0]
      );
      setSelectedZone(allZones.flat());
    }
  };

  console.log("internalSelectedType", internalSelectedType);
  console.log("internalCategory", internalCategory);

  console.log("isMobile", isMobile);
  console.log("internalSelectedAppType", internalSelectedAppType);

  // useEffect(() => {
  //   if (deviceType) {
  //     setContentStatus([deviceType]);
  //   }
  // }, [deviceType, setContentStatus]);
  useEffect(() => {
    if (deviceType) {
      setContentStatus([deviceType]);
    }
  }, [deviceType]);

  // Make sure to include only necessary dependencies

  useEffect(() => {
    console.log("Response data updated:", responseData);
    // Update the UI based on the new responseData
  }, [responseData]);

  const handleFetchDetails = () => {
    // Fetch details logic here
    setApplyClicked(true);
  };

  const handleClearFilters = async () => {
    console.log("Clearing filters...");

    setContentStatus(allContentnames.slice(1));
    // setSelectedRegions(allregionnames);
    // setSelectedZone(allZones);
    callRegionApi();
    setApplyClicked(false);
    setShowApplyBtn(false);

    const clearFiltUrl = `https://digilens.myhyundai.co.in/digi_lens/dashboard_api/device_status_dashboard_data/?username=${storedUsername}&permission=${JSON.stringify(
      storedPermission
    )}&zone=${JSON.stringify(regionnamestwo)}&region=${JSON.stringify(
      zoneNamestwo
    )}&device_type_array=${JSON.stringify(selecedContenttwo)}`;
    console.log("clearFiltUrl:", clearFiltUrl);

    try {
      const response = await axios.get(clearFiltUrl);
      console.log("Final Response:", response.data);
      setResponseData(response.data);
    } catch (error) {
      console.error("Error fetching device status dashboard data:", error);
    }
  };

  const navigate = useNavigate();

  const handleOffline = (deviceTypeTwo) => {
    console.log("line `1004", selectedZone, selectedRegions, selectedContent);
    localStorage.setItem("selectedZone", JSON.stringify(selectedZone));
    localStorage.setItem("selectedRegions", JSON.stringify(selectedRegions));
    localStorage.setItem("selectedContent", JSON.stringify(selectedContent));
    if (deviceTypeTwo === "Offline") {
      // Check for specific device type
      const path = `/devicereport/status/${encodeURIComponent(deviceTypeTwo)}`;
      navigate(path);
    } else if (deviceTypeTwo === "Pending") {
      const path = `/devicereport/contentStatus/${encodeURIComponent(
        deviceTypeTwo
      )}`;
      navigate(path);
    } else if (deviceTypeTwo === "Outdated") {
      const path = `/devicereport/appStatus/${encodeURIComponent(
        deviceTypeTwo
      )}`;
      navigate(path);
    } else {
      // Handle other device types or default behavior
      const defaultPath = "/report"; // Optional default path
      navigate(defaultPath); // Or perform actions specific to other device types
    }
  };

  const handleOfflineTwo = (deviceTypeThree) => {
    if (deviceTypeThree === "Pending") {
      // Check for specific device type
      const path = `/devicereport/contentStatus/${encodeURIComponent(
        deviceTypeThree
      )}`;
      navigate(path);
    } else {
      // Handle other device types or default behavior
      const defaultPath = "/report"; // Optional default path
      navigate(defaultPath); // Or perform actions specific to other device types
    }
  };

  return (
    <div>
      <Navbar />
      <Dashboard />
      {!isMobile ? (
        <>
          <div className="device-status-full-contain-section">
            <div className="hyundai-content-section">
              {/* ---------------------------------------------------------- */}

              <div className="zone-flex-container-report">
                <div
                  className="zone-div-report"
                  style={{ marginBottom: "10px" }}
                >
                  Device Type
                </div>
                <FormControl>
                  <Select
                    style={{
                      fontFamily: "Hyundai Sans Head Office regular",
                      textAlign: "center",
                      fontSize: "15.711px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "normal",
                      backgroundColor: "white",
                      height: "40px",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      width: "200px",
                    }}
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    value={selectedContent.map((ele) => {
                      if (ele === "kiosk") {
                        return "Digital Kiosk";
                      } else if (ele === "Centralized Waiting Period Display") {
                        return "Centralized Display Monitor";
                      } else {
                        return ele;
                      }
                    })}
                    onChange={handleContentChange}
                    renderValue={(selected) => {
                      if (contentareAllOptionsSelected()) {
                        return "Select All";
                      } else {
                        console.log("line 1104", selected);
                        let newSelected = selected.map((ele) => {
                          if (ele === "Kiosk") {
                            return "Digital Kiosk";
                          } 
                          else if (ele === "Centralized Waiting Period Display") {
                            return "Centralized Display Monitor";
                          } 
                          else return ele;
                        });
                        return newSelected.join(", ");
                      }
                    }}
                  >
                    {allContentnames.map((regionName) => (
                      <MenuItem key={regionName} value={regionName}>
                        <Checkbox
                          checked={
                            selectedContent.includes(regionName) ||
                            (regionName === "Select All" &&
                              contentareAllOptionsSelected())
                          }
                        />
                        <ListItemText
                          primary={
                            regionName === "Kiosk"
                              ? "Digital Kiosk"
                                  : regionName === "Centralized Waiting Period Display"
                  ? "Centralized Display Monitor"
                              : regionName
                          }
                        />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              {/* -------------------------------------------------------------- */}

              {/* ------------------------------------------------------------------- */}
              <div className="zone-flex-container">
                <div className="zone-div" style={{ marginBottom: "10px" }}>
                  Zone
                </div>
                <FormControl>
                  <Select
                    style={{
                      fontFamily: "Hyundai Sans Head Office regular",
                      textAlign: "center",
                      fontSize: "15.711px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "normal",
                      backgroundColor: "white",
                      height: "40px",
                      overflow: "hidden", // Hide overflow
                      textOverflow: "ellipsis", // Truncate text with ellipsis
                      whiteSpace: "nowrap", // Prevent wrapping
                      width: "200px", // Set fixed width
                    }}
                    multiple
                    value={selectedRegions}
                    onChange={handleRegionChanges}
                    MenuProps={{
                      MenuListProps: {
                        autoFocusItem: false,
                        ref: menuRef,
                      },
                      PaperProps: {
                        style: {
                          maxHeight: 300,
                          overflowY: "auto",
                        },
                      },
                    }}
                    renderValue={(selected) => {
                      if (
                        selected.length === 1 &&
                        selected[0] !== "select-all"
                      ) {
                        return selected[0]; // Render the single selected region name
                      } else if (selected.includes("select-all")) {
                        return "Select All";
                      } else if (
                        selected.length === allregionnames.length &&
                        allregionnames.length > 1
                      ) {
                        return "Select All";
                      } else {
                        return selected.join(", ");
                      }
                    }}
                  >
                    {allregionnames.length > 1 && (
                      <MenuItem key="select-all" value="select-all">
                        <Checkbox
                          checked={
                            selectedRegions.length === allregionnames.length
                          }
                        />
                        <ListItemText primary="Select All" />
                      </MenuItem>
                    )}

                    {allregionnames.map((regionName) => (
                      <MenuItem key={regionName} value={regionName}>
                        <Checkbox
                          checked={selectedRegions.includes(regionName)}
                        />
                        <ListItemText primary={regionName} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              {/* -------------------------------------------------------------------------------- */}
              {showZoneSelect &&
                (selectedRegions.length ||
                  selectedRegions.includes("select-all") ||
                  selectedRegions?.length === allregionnames.length) &&
                selectedRegions.length > 0 &&
                allregionZoneData.length > 0 && (
                  <div className="zone-flex-container">
                    <div className="zone-div" style={{ marginBottom: "10px" }}>
                      Region
                    </div>
                    {/* // Render zone select only if showZoneSelect is true */}
                    <FormControl>
                      <Select
                        multiple
                        value={selectedZone}
                        onChange={handleZoneChanges}
                        MenuProps={{
                          MenuListProps: {
                            autoFocusItem: false,
                            ref: menuRef,
                          },
                          PaperProps: {
                            style: {
                              maxHeight: 300,
                              overflowY: "auto",
                            },
                          },
                        }}
                        renderValue={(selected) => {
                        
                          console.log("line 634",apiResponse, selected, selectedRegions);

                          // const allZones = allregionZoneData.flatMap(regionData =>
                          //   Object.values(regionData).flat()
                          // );
                          // const allSelected = selected.length === allZones.length;
                          // return allSelected ? "Select All" : selected.join(", ");
                          if (
                            selected.length === 1 &&
                            selected[0] !== "select-all"
                          ) {
                            return selected[0]; // Render the single selected region name
                          } else if (selected.includes("select-all")) {
                            return "Select All";
                          } else if (
                            selected.length >= allZones?.length &&
                            allZones.length > 1
                          ) {
                            return "Select All";
                          } else {
                            return selected.join(", ");
                          }
                        }}
                        style={{
                          fontFamily: "Hyundai Sans Head Office regular",
                          textAlign: "center",
                          fontSize: "15.711px",
                          fontStyle: "normal",
                          fontWeight: 400,
                          lineHeight: "normal",
                          backgroundColor: "white",
                          height: "40px",
                          overflow: "hidden", // Hide overflow
                          textOverflow: "ellipsis", // Truncate text with ellipsis
                          whiteSpace: "nowrap", // Prevent wrapping
                          width: "200px", // Set fixed width
                        }}
                      >
                        {allZones?.length > 1 && (
                          <MenuItem key="select-all" value="select-all">
                            <Checkbox
                              checked={selectedZone.length >= allZones?.length}
                            />
                            <ListItemText primary="Select All" />
                          </MenuItem>
                        )}

                        {allZones?.map((zone) => (
                          <MenuItem key={zone} value={zone}>
                            <Checkbox checked={selectedZone.includes(zone)} />
                            <ListItemText primary={zone} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                )}

              {showapplybtn && (
                <div className="zone-flex-container">
                  <div
                    className="zone-div"
                    style={{ marginBottom: "10px", visibility: "hidden" }}
                  >
                    Button
                  </div>
                  {applyClicked ? (
                    <button
                      onClick={handleClearFilters}
                      className={`fetch-detailsbtntwo`}
                    >
                      Clear
                    </button>
                  ) : (
                    <button
                      onClick={handleFetchdetailsTwo}
                      className={`fetch-detailsbtn ${
                        fetchingDetails ? "fade-out" : "fade-in"
                      }`}
                    >
                      Apply
                    </button>
                  )}
                </div>
              )}

             
             
              {/* ------------------------------------------------------------------------------------------- */}

              {/* date range  */}
            </div>
            {/* --------------------------------------------------------- */}
          </div>
        </>
      ) : (
        // for mobile version
        <>
          {/* <Button variant="contained" onClick={handleFilterClicks}>Filter</Button> */}

          <div className="zone-flex-container" onClick={handleFilterClicks}>
            {/* <div className='zone-div' style={{marginBottom:"10px",visibility:"hidden"}}>Date Range</div> */}
            <div className="filters-div">
              <div>
                {" "}
                <FilterListIcon />
              </div>
              <div> Filter</div>
            </div>
          </div>

          {/* add fetchdetaile to show  apply bth           */}
          {showapplybtn && (
            <div>
              {applyClicked ? (
                <div>
                  <div
                    className={`fetch-detailsbtnmobile ${
                      fetchingDetails ? "fade-out" : "fade-in"
                    }`}
                    onClick={handleClearFilters}
                  >
                    Clear
                  </div>
                </div>
              ) : (
                <div
                  className={`fetch-detailsbtnmobile ${
                    fetchingDetails ? "fade-out" : "fade-in"
                  }`}
                  onClick={handleFetchdetailsTwo}
                >
                  Apply
                </div>
              )}
            </div>
          )}

          {/* Filter Dialog */}
          <Dialog
            open={isFilterOpen}
            onClose={handleCloseFilter}
            className="mui-filter-modaal"
            PaperProps={{
              style: {
                backgroundColor: "#ffffff",
                boxShadow: "none",

                paddingBottom: "30px",
                marginLeft:"10px",
             

                width: "calc(100vw)", // Adjust the width by subtracting the desired left and right spacing
                // marginLeft: "10px", // Left spacing
                // marginRight: "10px", // Right spacing

                WebkitOverflowScrolling: "touch", // Enable smooth scrolling in iOS
                scrollbarWidth: "none", // Hide scrollbar for Firefox
                msOverflowStyle: "none", // Hide scrollbar for IE and Edge
                "&::-webkit-scrollbar": {
                  display: "none", // Hide scrollbar for WebKit browsers
                },
              },
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                paddingTop: "10px",
                paddingBottom: "40px",
                paddingLeft: "10px",
                paddingRight: "10px",
                position: "fixed",
                // top: 0,
                // left: "10px",
                // right: "10px",

                border: "1px solid grey",
                borderTop: "none",
                borderLeft: "none",
                borderRight: "none",
                backgroundColor: "#FFFFFF",
                borderTopRightRadius: "20px",
                borderTopLeftRadius: "20px",
                width: "100%",
                // marginLeft:"10px",
                // marginRight:"10px",

                height: "40px",
                zIndex: "999",
              }}
              className="device-status-full-contain-sections"
            >
              <div style={{ marginTop: "10px" }}> Change Filters</div>
              <div
                onClick={handleCloseFilter}
                style={{ textAlign: "right" }}
                className="close-btns"
              >
                <div style={{ width: "35px", height: "35px" }}>
                  <img
                    src={closeIcon}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                    }}
                  />
                </div>
              </div>{" "}
              {/* Add onClick handler */}
            </div>

            <div
              className="device-status-full-contain-section"
              style={{ marginTop: "60px" }}
            >
              <div className="hyundai-content-sections">
                {/* ---------------------------------------------------------- */}
                <Accordion
                  expanded={accordionExpandeds}
                  onChange={handleDeviceAccordianChange}
                  className={`accordian-container ${
                    accordionExpandeds ? "expanded" : ""
                  }`}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    className={`accordian-summary ${
                      accordionExpandeds ? "expanded" : ""
                    }`}
                  >
                    <Typography>Device Type</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      {/* Render device type options */}
                      {allContentnames.map((regionName) => (
                        <div
                          key={regionName}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            paddingTop: "5px",
                          }}
                        >
                          <Checkbox
                            checked={
                              selectedContent.includes(regionName) ||
                              (regionName === "Select All" &&
                                contentareAllOptionsSelected())
                            }
                            onChange={(event) => handleContentChanges(event)} // Pass the event object
                            value={regionName}
                          />
                          {/* <div>{regionName}</div> */}
                          <div>
                            {regionName === "Kiosk"
                              ? "Digital Kiosk"
                                   : regionName === "Centralized Waiting Period Display"
                                      ? "Centralized Display Monitor"

                : regionName}
                          </div>
                        </div>
                      ))}
                    </div>
                  </AccordionDetails>
                </Accordion>


                <Accordion
                  expanded={ZoneAccordianExpanded}
                  onChange={handlezoneAccordianChange}
                  className={`accordian-container ${
                    ZoneAccordianExpanded ? "expanded" : ""
                  }`}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="zone-panel-content"
                    id="zone-panel-header"
                    className={`accordian-summary ${
                      ZoneAccordianExpanded ? "expanded" : ""
                    }`}
                  >
                    <Typography>Zone</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        overflowY: "scroll",
                        minHeight: "10px",
                      }}
                      className="heightprop-check"
                    >
                      {/* Conditionally render "Select All" checkbox */}
                      {allregionnames.length > 1 && (
                        <MenuItem key="select-all" value="select-all">
                          <Checkbox
                            checked={
                              selectedRegions.length === allregionnames.length
                            }
                            onChange={(event) =>
                              handleRegionChange(event, "select-all")
                            }
                            value="select-all"
                          />
                          <ListItemText primary="Select All" />
                        </MenuItem>
                      )}
                      {/* Individual region checkboxes */}
                      {allregionnames.map((regionName) => (
                        <MenuItem key={regionName} value={regionName}>
                          <Checkbox
                            checked={selectedRegions.includes(regionName)}
                            onChange={(event) =>
                              handleRegionChange(event, regionName)
                            }
                            value={regionName}
                          />
                          <ListItemText primary={regionName} />
                        </MenuItem>
                      ))}
                    </div>
                  </AccordionDetails>
                </Accordion>

                <Accordion
                  expanded={regionAccordionExpanded}
                  onChange={toggleRegionAccordion}
                  className={`accordian-container ${
                    regionAccordionExpanded ? "expanded" : ""
                  }`}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="region-panel-content"
                    id="region-panel-header"
                    className={`accordian-summary ${
                      regionAccordionExpanded ? "expanded" : ""
                    }`}
                  >
                    <Typography>Region</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="zone-flex-container">
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        {/* Conditionally render "Select All" checkbox */}
                        {Array.isArray(allZones) && allZones.length > 1 && (
                          <MenuItem key="select-all" value="select-all">
                            <Checkbox
                              checked={
                                Array.isArray(selectedZone) &&
                                selectedZone.length >= allZones.length
                              }
                              onChange={handleZoneChange}
                              value="select-all"
                            />
                            <ListItemText primary="Select All" />
                          </MenuItem>
                        )}
                        {allZones &&
                          allZones.map((zone) => (
                            <MenuItem key={zone} value={zone}>
                              <Checkbox
                                checked={selectedZone.includes(zone)}
                                onChange={(event) =>
                                  handleZoneChange(event, zone)
                                }
                                value={zone}
                              />
                              <ListItemText primary={zone} />
                            </MenuItem>
                          ))}
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>

                {/* -------------------------------------------------------------- */}

                {/* ------------------------------------------------------------------- */}

                {/* -------------------------------------------------------------------------------- */}

                {/* ------------------------------------------------------------------------------------------- */}

                {/* date range  */}
              </div>
              {/* --------------------------------------------------------- */}
            </div>
            <DialogContent></DialogContent>
          </Dialog>

          {/* --2nd accordian-- */}
        </>
      )}
      :{/* ----fetch details */}
      {/* ---end of fetch details--- */}
      {/* ----start of card-section------------ */}
      {responseData && (
        <div className="card-containers">
          {/* <div> */}
          <div className="cards">
            <div className="no-of-div-card">
              <div className="no-of-device-img-sect"></div>
              <div className="num-devices-div">
                <div className="centralized-num-of-devices-div">
                  Total no. of device
                </div>
                <div className="centralized-devices-count-div">
                  {responseData.total_device}
                </div>
              </div>
              <div className="active-inactibe-div">
                <div className="active-div">
                  <div className="active-content-div">Online</div>
                  <div className="active-count-divtwo">
                    {responseData.online}
                  </div>
                </div>

                <div className="inactive-div">
                  <div className="active-content-div">Offline*</div>
                  <div
                    className="active-count-div"
                    onClick={() => handleOffline("Offline")}
                    style={{textDecoration:"underline"}}
                  >
                    {responseData.offline}
                  </div>
                </div>
              </div>

              <br />
              <hr style={{ borderTop: "1px solid #9A9A9A" }} />
              <br />

              <div className="active-content-div">{/* Definition */}</div>

              <div
                className="active-count-di"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "5px",
                  justifyContent: "Center",
                }}
              >
                <div
                  className="circle"
                  style={{
                    backgroundColor: "red",
                    paddingLeft: "10px",
                    marginTop: "15px",
                  }}
                ></div>

                <div className="active-count-divs">{offline}</div>
              </div>
            </div>
          </div>
          <div className="cards">
            <div className="no-of-div-card">
              <div className="content-img-sect"></div>
              <div className="num-devices-div">
                <div className="centralized-num-of-devices-div">
                  Content Update Status
                </div>
              </div>

              <div
                className="active-inactibe-div"
                style={{ marginTop: "40px" }}
              >
                <div className="active-div">
                  <div className="active-content-div">Updated dealers</div>
                  <div className="active-count-divtwo" >
                    {responseData.content_synced}
                  </div>
                </div>

                <div className="inactive-div">
                  <div className="active-content-div">Pending dealers</div>
                  <div
                    className="active-count-div"
                    onClick={() => handleOffline("Pending")}
                    style={{textDecoration:"underline"}}
                  >
                    {responseData.content_unsynced}
                  </div>
                </div>
              </div>
            </div>

            <br />
            <hr style={{ borderTop: "1px solid #9A9A9A" }} />
            <br />
{/* 
            <div className="active-inactibe-divss">
              <div className="active-divs">
                <div className="active-content-div">Latest Update</div>
              </div>

              <div className="inactive-divs">
                <div className="active-content-div">Date</div>
              </div>
            </div> */}

            {/* <div className="active-inactibe-d">
              {contentVersionDetails.map((version, index) => (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "10px",
                    marginTop: "20px",
                  }}
                >
                  <div
                    className="active-content-div"
                    style={{ flexBasis: "70%" }}
                  >
                    <div>
                      {" "}
                      {index + 1}.{version.type==="kiosk"?"digital kiosk":version.type}
                    </div>

                    <div className="inactive-divstwoo">
                      {version.release_date}
                    </div>
                  </div>

                  <div className="inactive-divs">
                    <div className="inactive-divs" style={{ flexBasis: "30%" }}>
                      {version.description}
                    </div>
                  </div>
                </div>
              ))}
            </div> */}

            <div className="active-count-divss" onClick={handleCloseFilterTwo}   style={{textDecoration:"underline"}}>
              {" "}
            
              Update History
            </div>
          </div>
          <div className="cards">
            <div className="no-of-div-card">
              <div className="appupdate-img-sect"></div>
              <div className="num-devices-div">
                <div className="centralized-num-of-devices-div">
                  App update status
                </div>
              </div>
              <div
                className="active-inactibe-div"
                style={{ marginTop: "40px" }}
              >
                <div className="active-div">
                  <div className="active-content-div">Updated</div>
                  <div className="active-count-divtwo">
                    {responseData.app_updated}
                  </div>
                </div>

                <div className="inactive-div">
                  <div className="active-content-div">Outdated</div>
                  <div
                    onClick={() => handleOffline("Outdated")}
                    className="active-count-div"
                    style={{textDecoration:"underline"}}
                  >
                    {responseData.app_outdated}
                  </div>
                </div>
              </div>
            </div>

            <br />
            <hr style={{ borderTop: "1px solid #9A9A9A" }} />
            <br />

            {/* <div className="active-inactibe-divss">
              <div className="active-divs">
                <div className="active-content-div">Latest Update</div>
              </div>

              <div className="inactive-divs">
                <div className="active-content-div">Date</div>
              </div>
            </div> */}

            {/* <div className="active-inactibe-d">
              {appVersionDetails.map((version, index) => (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "10px",
                    marginTop: "20px",
                  }}
                >
                  <div
                    className="active-content-div"
                    style={{ flexBasis: "70%" }}
                  >
                    <div>
                      {" "}
                      {index + 1}.{version.type==="kiosk"?"digital kiosk":version.type}
                    </div>
                    <div className="inactive-divstwoo"> {version.versions}</div>
                  </div>

                  <div className="inactive-divs">
                    <div className="inactive-divs" style={{ flexBasis: "30%" }}>
                      {version.release_date}
                    </div>
                  </div>
                </div>
              ))}
            </div> */}

            <div
              className="active-count-divss"
              onClick={handleCloseFilterThree}
              style={{textDecoration:"underline"}}
            >
              {" "}
              Update History
            </div>
          </div>
        </div>
      )}
     <div style={{padding:'20px', paddingLeft:'30px'}}>
     <p><strong>Disclaimer* : </strong>{disclaimer}</p>
     </div>


     <Dialog
                open={isFilterOpenTwo}
                onClose={handleCloseFilterTwo}
                className="mui-filter-modaals"
                PaperProps={{
                  style: {
                    backgroundColor: "#ffffff",
                    boxShadow: "none",

                    paddingBottom: "30px",
                    paddingLeft: "20px",
                    paddingRight: "20px",

                    // width: "calc(100vw - 20px)", // Adjust the width by subtracting the desired left and right spacing
                    // marginLeft: "10px", // Left spacing
                    // marginRight: "10px", // Right spacing

                    WebkitOverflowScrolling: "touch", // Enable smooth scrolling in iOS
                    scrollbarWidth: "none", // Hide scrollbar for Firefox
                    msOverflowStyle: "none", // Hide scrollbar for IE and Edge
                    "&::-webkit-scrollbar": {
                      display: "none", // Hide scrollbar for WebKit browsers
                    },
                  },
                }}
              >
                {/* <DialogContent>
  hiii
        </DialogContent> */}

                <div className="content-close-section">
                  <div>Content Update</div>
                  <div onClick={handleDialogCloseTwo}>
                    <img src={closethree} style={{cursor:"pointer"}} />{" "}
                  </div>
                </div>

                <div
                  sx={{
                    width: 600,
                    margin: "auto",
                    mt: 5,
                    bgcolor: "background.paper",
                    boxShadow: 24,
                    p: 4,
                    paddingTop: "20px",
                  }}
                >
                <Tabs value={tabIndex} onChange={handleTabChange}
                 variant="scrollable"
                 scrollButtons="auto"
                 aria-label="scrollable auto tabs example"
                 >
  {getTabLabels().map((label, index) => (
    <Tab 
      label={label} 
      key={index} 
      style={{ textTransform: 'none' }} // This will prevent the text from being transformed to uppercase
    />
  ))}
</Tabs>

                  {getTabLabels().map((label, index) => (
                    <Box
                      role="tabpanel"
                      hidden={tabIndex !== index}
                      key={index}
                      id={`tabpanel-${index}`}
                      aria-labelledby={`tab-${index}`}
                      style={{ marginTop: "30px" }}
                    >
                      {tabIndex === index && renderTabContent(label)}
                    </Box>
                  ))}
                </div>

                <DialogActions>
                  {/* <div onClick={handleDialogCloseTwo}> 
          close

          </div> */}
                  {/* <Button onClick={handleDialogCloseTwo} color="primary">
            Close
          </Button> */}
                </DialogActions>
              </Dialog>


               <Dialog
                open={isFilterOpenThree}
                onClose={handleCloseFilterThree}
                className="mui-filter-modaals"
                PaperProps={{
                  style: {
                    backgroundColor: "#ffffff",
                    boxShadow: "none",

                    paddingBottom: "30px",
                    paddingLeft: "20px",
                    paddingRight: "20px",

                    // width: "calc(100vw - 20px)", // Adjust the width by subtracting the desired left and right spacing
                    // marginLeft: "10px", // Left spacing
                    // marginRight: "10px", // Right spacing

                    WebkitOverflowScrolling: "touch", // Enable smooth scrolling in iOS
                    scrollbarWidth: "none", // Hide scrollbar for Firefox
                    msOverflowStyle: "none", // Hide scrollbar for IE and Edge
                    "&::-webkit-scrollbar": {
                      display: "none", // Hide scrollbar for WebKit browsers
                    },
                  },
                }}
              >
   
                <div className="content-close-section">
                  <div>App Update</div>
                  <div onClick={handleDialogCloseThree}>
                    <img src={closethree}  style={{cursor:"pointer"}}/>{" "}
                  </div>
                </div>

                <div
                  sx={{
                    width: 600,
                    margin: "auto",
                    mt: 5,
                    bgcolor: "background.paper",
                    boxShadow: 24,
                    p: 4,
                    paddingTop: "20px",
                  }}
                >
                  <Tabs value={tabIndexOne} onChange={handleTabChangeTwo}
                      variant="scrollable"
                      scrollButtons="auto">
                    {getTabLabelsTwo().map((label, index) => (
                      <Tab label={label} key={index}
                      style={{ textTransform: 'none' }} />
                    ))}
                  </Tabs>
                  {getTabLabelsTwo().map((label, index) => (
                    <Box
                      role="tabpanel"
                      hidden={tabIndexOne !== index}
                      key={index}
                      id={`tabpanel-${index}`}
                      aria-labelledby={`tab-${index}`}
                      style={{ marginTop: "30px" }}
                    >
                      {tabIndexOne === index && renderTabContentTwo(label)}
                    </Box>
                  ))}
                </div>

                <DialogActions>
            
                </DialogActions>
              </Dialog>



    </div>
  );
};
export default DeviceStatus;