import {React,useState} from 'react'

import '../Assets/components.css'
import vector from '../Assets/close (6).png'
import Email from '../Assets/menu (1).png'
import hyundai from '../Assets/Hyundaicon.png'
import { useNavigate } from 'react-router-dom'


// className={ismobile?"nav-links-mobile":"nav-links"} 
// export default function Navigations() {

  function Navbar(){
  const[ismobile,setmobile]=useState(false);
  const handleclick=()=>setmobile(!ismobile)
  
  const storedPermission = JSON.parse(localStorage.getItem('permission'));
  const storedUsername = localStorage.getItem('username');
  const firstName = localStorage.getItem('firstName');
  const navigate = useNavigate();


  const handleLogout = () =>{
    localStorage.removeItem('permission');
    localStorage.removeItem('username');
    localStorage.removeItem('firstName')
    localStorage.removeItem('selectedStatus');
    localStorage.removeItem('selectedType');

    localStorage.removeItem('formattedContentUpdate');
    localStorage.removeItem('selectedRegions');

    localStorage.removeItem('DeviceType');
    localStorage.removeItem('accessToken');

    
    localStorage.removeItem('formattedAppUpdate');
    localStorage.removeItem('selectedZone');


    navigate('/');


  }

  return (
    <>
    <div className="navbars">
      <div className="logo"><img src={hyundai} style={{width:"100%",height:"100%",objectFit:"contain"}} /></div> 
      {/* initiaally it was nav-links....if smaaller screen nav link mobiles..laarger screen means nav links */}
         <div className={ismobile?"nav-links-mobile":"nav-links"} >
             <div className="welcome-text">Welcome,<span className='welcome-texts' style={{ marginLeft: '5px' }}> {firstName}</span></div>
            <button className="logout-btn" onClick={handleLogout}>Logout</button>
           
           
                </div> 
       <div className='mobile-menu-icon' onClick={handleclick}>{ismobile?(<img src={vector}  alt="microsft" className='vect' />):(<img src={Email}  alt="microsft" className='vector' />)}</div>
      </div>
    
      </>
  )
}
export default Navbar
