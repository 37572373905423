import React,{useState,useEffect}from "react";
import { DatePicker } from "antd";
import Navbar from "./Navbar";
import Dashboard from "./Dashboard";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { useParams } from 'react-router-dom';
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';


const { RangePicker } = DatePicker;


function Dealersdetailpage() {

  const { dealerCode } = useParams();
  const navigate = useNavigate();
  console.log("dealer code",dealerCode)
    // const[isMobile,setIsMobile]=useState("");
    const [setNewactiveTabs, setNewActiveTabs] = useState('tabone');
 
    const [dealersDetailPage, setDealersDetailsPage] = useState([]);
    const[deviceId,setDeviceid]=useState();
    const[NewappUpdate,setNewAppupdate]=useState([]);
    const[Newcontentupdate,setNewContentUpdate]=useState([]);


    // useStates 
    const[activesta,setActivesta]=useState();
    const[DetailDealerId,setDealerDetailId]=useState();
    const[ShowroomName,setShowroomNamed]=useState();
    const[LastcontDealerupdate,setLastcontDealerupdate]=useState();
    const[Dealercontupdate,setDealercontupdate]=useState();
    const[DealeZone,setDealeZone]=useState();
    const[DealeRegion,setDealeRegion]=useState();
    const[LastDealerAppUdate,setLastDealerAppUdate]=useState();
    const[DealerAppUpda,setDealerAppUpda]=useState();



    // const[deviceType,setDeviceType]=useState("kiosk");

    

    const tabsection = [
        { id: 'tabone', name: 'Content update' },
        { id: 'tabtwo', name: 'App update' },
      
      ];
     


        
       
       
     
      
  

  

      const handleTabChange = (tab) => {
        setNewActiveTabs(tab);
      };



      const storedPermission = JSON.parse(localStorage.getItem('permission'));
  const storedUsername = localStorage.getItem('username');
  const deviceType = localStorage.getItem('DeviceType')


  console.log("deviceType",deviceType)
  useEffect(() => {
    console.log("dealersDetailPage changed:", dealersDetailPage);
  }, [dealersDetailPage]);

      useEffect(() => {
        axios.get(`https://digilens.myhyundai.co.in/digi_lens/dashboard_api/get_device_details/?username=${storedUsername}&permission=${JSON.stringify(storedPermission)}&device_type=${deviceType}&dealer_code=${dealerCode}`)
          .then((response) => {
            console.log("detailpage response:", response.data);
            console.log("devicedataofdeatilpage",response.data.device_data)
             setDealersDetailsPage(response.data.device_data);
             console.log("dealersDetailPage",dealersDetailPage)
             console.log("deviceid",response.data.device_data.device)
             setDeviceid(response.data.device_data.device);
             console.log("dataResp",response.data.app_update_table
            )
             setNewAppupdate( response.data.app_update_table)
             setNewContentUpdate(response.data.content_update_table)
             setActivesta(response.data.device_data.device_status)
             console.log("status value",response.data.device_data.device_status
            )
          
            setDealerDetailId(response.data.device_data.dealer_code)
             setShowroomNamed(response.data.device_data.dealer_name )
             setLastcontDealerupdate(response.data.device_data.last_app_update)
             setDealercontupdate(response.data.device_data.content_update_status)
             setDealeZone(response.data.device_data.DealeZone)
             setDealeRegion(response.data.device_data.region)
             setLastDealerAppUdate(response.data.device_data.last_app_update)
             setDealerAppUpda(response.data.device_data.app_update_status)
           
            
          })
          .catch((error) => {
            console.error('Error fetching region and DealeZone data:', error);
          });
      }, []);
      
  
      

      useEffect(()=>{
        axios.get(``)
        .then((resp)=>
        {
        console.log("resp",resp)
        }
      )
      .catch(()=>{

      })

      },[])


    const handleBackClick = (event) =>{
      event.preventDefault();
      navigate('/devicereport');
    }
    

    return(
        <>
        <Navbar />
        {/* desktop ui */}
     


<div className="details-page-navbar">

                <div className="back-date-flexbox-container">
                    <div className="details-backicon-flexbox-container">
                    {/* <div><KeyboardBackspaceIcon /></div> */}
                    <div onClick={handleBackClick}>
                <div  className="back-btn-delaers">Back</div>
                </div>
                </div>
          
            <div>
           
           
          
          </div>
          </div>
          <div className="dealer-detail-downloadreport-container">
          {/* <div>
            Download Report
          </div> */}
          {/* <div  ><FileDownloadOutlinedIcon  /></div> */}
          </div>
          </div>


<div className="dealers-detail-fullbox-flex-container">
          <div className="dealers-detail-navbar-heading-container">
            <div className="dealers-details-text-image-container">
            <div className="dealers-details-image">

            </div>
      
            <div>{deviceType}</div>
            </div>


{/* for dealer code */}
            {/* <p>Dealer Code: {dealerCode}</p> */}


<div>
            <div>
             status
            </div>
            <div>
   {activesta}
            </div>
            </div>
          </div>

      

        {/* -------deaaler detail flex box container------------- */}
       <div className="dealer-detail-firstrow-data-container">
 


</div> 







<div className="dealer-detail-firstrow-data-container">

    <div  className="dealer-details-individual-cards">
     
     <div className="Dealer-details-id">Dealer ID </div>
      <div className="Dealers-detail-number">{DetailDealerId} </div>
    </div>


    <div  className="dealer-details-individual-cards">
     
     <div className="Dealer-details-id">Showroom Name </div>
      <div className="Dealers-detail-number">{ShowroomName} </div>
    </div>

    <div  className="dealer-details-individual-cards">
     
     <div className="Dealer-details-id">Last content Update</div>
      <div className="Dealers-detail-number">{LastcontDealerupdate} </div>
    </div>

    <div  className="dealer-details-individual-cards">
     
     <div className="Dealer-details-id">Content Update </div>
      <div className="Dealers-detail-number">{Dealercontupdate} </div>
    </div>

    <div  className="dealer-details-individual-cards">
     
     <div className="Dealer-details-id">Zone </div>
      <div className="Dealers-detail-number">{DealeZone}</div>
    </div>

    <div  className="dealer-details-individual-cards">
     
     <div className="Dealer-details-id">DealeRegion</div>
      <div className="Dealers-detail-number">{DealeRegion}</div>
    </div>

    <div  className="dealer-details-individual-cards">
     
     <div className="Dealer-details-id">Last App Update</div>
      <div className="Dealers-detail-number">{LastDealerAppUdate} </div>
    </div>
    <div  className="dealer-details-individual-cards">
     
     <div className="Dealer-details-id">App Update</div>
      <div className="Dealers-detail-number">{DealerAppUpda} </div>
    </div>
    
  
</div> 


          
  


          {/* ----------------Activity status------------------------ */}
          <div className="dealers-detaails-activity-status">
           

          </div>


          {/* --------------device content-app-update------------------ */}

          <div className="dealers-details-content-app-update-section">
            <div className="dealers-details-tab-flexbox-container">
            {tabsection.map(tab => (
              <div
                key={tab.id}
                className={setNewactiveTabs === tab.id ? 'active-tab' : 'inactive-tab'}
                onClick={() => handleTabChange(tab.id)}
              >
                {tab.name}
              </div>
            ))}

            </div>

          </div>

        

          {/* tabs details */}

          <div className="dealers-details-tab-content">
        {/* Content for Tab 1 */}
        {setNewactiveTabs === 'tabone' && (
          <>
            <div>

 
    <div className='content-app-update-table-full-section'>
    <div className="contentname-title-section">
         <div>Content name</div>
         <div>Date</div>
         </div>
   
      {NewappUpdate &&NewappUpdate.slice(0, 5).map((card) => (
    
         
        <div key={card.id} className='dealers-details-content-app-flexbox-cards'>
          
            <div className="dealers-detaails-contentname-full-container-table">
          <div className='dealers-content-name-heading-texts'>{card.title}</div>
          <div className='dealers-content-name-heading-texts'>{card.date}</div>
          </div>
      
        </div>
     
      ))}
    </div>
 

                
                </div>
          </>
        )}


{setNewactiveTabs === 'tabtwo' && (
          <>

<div>

 

<div className='content-app-update-table-full-section'>
    <div className="contentname-title-section">
         <div>Content name</div>
         <div>Date</div>
         </div>
   
      {Newcontentupdate && Newcontentupdate.slice(0, 5).map((card) => (
    
         
        <div key={card.id} className='dealers-details-content-app-flexbox-cards'>
          
            <div className="dealers-detaails-contentname-full-container-table">
          <div className='dealers-content-name-heading-texts'>{card.title}</div>
          <div className='dealers-content-name-heading-texts'>{card.date}</div>
          </div>
          
        </div>
     
      ))}
    </div>


            
            </div>

          
          </>
        )}
        </div>

        </div>
   
        </>
    )
}
export default Dealersdetailpage