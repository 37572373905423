import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import vector from '../Assets/Frame 2085660376 (1).png';
import Email from '../Assets/Frame 2085660376.png';
import Hyundaimage from '../Assets/Hyundaicon.png';
import eyeIcon from '../Assets/visibility (1).png';
import eyeSlashIcon from '../Assets/visibility_off (1).png';

export default function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const [emailmessage, setEmailMessage] = useState('');
  const [passwordMessage, setPasswordMessage] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const[forcerestpassowrd,setforceresetpassowrd]=useState(false);
  const [showPassword, setShowPassword] = useState(false);



  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };




  // const  handleSubmits = () => {
  //   setShowPopup(true);
  // };

  const handleResetpassword = () =>{
   navigate('/resetpassword')
  }

  

  const handleSubmit = (e) => {
    e.preventDefault();
    // Make API call to authenticate user
    
    axios
      .get(`https://digilens.myhyundai.co.in/digi_lens/dashboard_api/dashboard_login/?username=${username}&password=${password}`)
      .then((response) => {
        console.log("LOGINRESPONSE:", response);
        console.log("")

        if (response.data.force_reset_password === "true") {
          setforceresetpassowrd(false);
        }

       
        if (response.data.status === "success") {
          localStorage.setItem('permission', JSON.stringify(response.data.permission));
          localStorage.setItem('username',username);
          localStorage.setItem('firstName',response.data.first_name);
         
        
          // Navigate to the appropriate page upon successful login
          navigate('/home'); // Replace '/analytics' with your desired route
        } else {
          setShowPopup(true);
          // Set appropriate error messages if login fails
          setPasswordMessage(response.data.error);
        }
      })
      .catch((error) => {
        console.error('LoginError:', error);

        // Handle error and set appropriate error messages
        if (error.response && error.response.status === 401) {
          setPasswordMessage('Incorrect username or password.');
        } else {
          setPasswordMessage('An error occurred. Please try again later.');
        }
      });
  };
  

  // const handleSubmits = (e) => {
  //   window.alert("hi")
  // }

  return (
    <>
      <section>
        <div className="form-container">
          <form>
            <div className='login-Hyundai-image' >
              <img src={Hyundaimage} alt="hyundaiumage" className='hyundaaimage-sec' />
            </div>
            <div className='hyundai-digilens' >
              DIGILENS
            </div>
            <div className='login-form-controller'>
              <div className="control">
                <input
                  type="email"
                  placeholder="Enter Your Username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
                <img src={Email} alt="padlock" className="Email-icon" />
              </div>
              {/* <label style={{ color: "red", fontSize: "12px" }}>{emailmessage}</label> */}
            </div>
            <div className='control'>
              <input
               type={showPassword ? "text" : "password"}
                placeholder="Enter Your Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <img src={vector} alt="padlock" className='password-icon' />
              <img src={showPassword ? eyeSlashIcon : eyeIcon} alt="toggle password visibility" className='password-iconvisibility' onClick={togglePasswordVisibility} />
            </div>
           


           
          
            {forcerestpassowrd &&(
<div className="div-forgot-password">
              <div className="forgot-password" onClick={handleResetpassword} >
             Reset Password
              </div>
            </div>
            )
}

            <div className="control">
              <button className="login" onClick={handleSubmit}>
                SIGN IN
              </button>
            </div>


          </form>
        </div>
    

      </section>
      {showPopup && (
  <div className="popup">
    <p>{passwordMessage}</p>
    <button onClick={() => setShowPopup(false)} className='close-btn'>Close</button>
  </div>
)}
    </>
  );
}
