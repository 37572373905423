import React from 'react'
import Navbar from './Navbar';
import Dashboard from './Dashboard';

function DevicethreeReport() {
  return (
    <>

    <Navbar />
      <Dashboard />


      <div  className="device-status-full-contain-section">
      
      <div className="hyundai-content-section">


        <div className='commingSoonPage '>Coming soon</div>


        </div>
        </div>
      
    </>
  )
}

export default DevicethreeReport
