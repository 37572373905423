import React from 'react';
import { useNavigate } from 'react-router-dom';
import Navbar from './Navbar';
import Dashboard from './Dashboard';
import kioskImage from '../Assets/3DConfigurator (2).png';
import config from '../Assets/Dk.png';
import waiting from '../Assets/CentralizedDisplay.png';

const Card = ({ deviceType, description, imageSrc, onClick }) => (
  <div className="cardshome" onClick={() => onClick(deviceType)}>
    <div>
      <img src={imageSrc} alt={deviceType} style={{ width: "100%", height: "300px", objectFit: "contain" }} />
    </div>
    <div className="threedheading">{deviceType}</div>
  </div>
);

const Home = () => {
  const navigate = useNavigate();

  const handleCardClick = (deviceType) => {
    let type;
    if (deviceType === "Digital Kiosk") {
      type = "Kiosk";
    } else if (deviceType === "Centralized Display Monitor") {
      type = "Centralized Waiting Period Display";
    } else {
      type = deviceType;
    }
    navigate(`/analytics/${encodeURIComponent(type)}`);
  };
  

  return (
    <>
      <Navbar />
      <Dashboard />
      <div className="device-status-full-contain-sections">
        <div className="card-containerhome">
          <Card
            deviceType="3D Configurator"
            description="Description for the 3D configurator"
            imageSrc={kioskImage}
            onClick={handleCardClick}
           
          />
          <Card
            deviceType="Digital Kiosk"
            description="Description for the digital kiosk"
            imageSrc={config}
            onClick={handleCardClick}

          />
          <Card
            deviceType="Centralized Display Monitor"
            description="Centralized Display Monitor"
            imageSrc={waiting}
            onClick={handleCardClick}
          />
        </div>
      </div>
    </>
  );
};

export default Home;
