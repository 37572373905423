import React, { useState, useEffect } from 'react';
import Navbar from './Navbar';
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import { useNavigate, useLocation } from 'react-router-dom';

function Dashboard() {
  const [isMobile, setIsMobile] = useState(false);
  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  // Mock device types - replace with your actual device types
  const deviceTypes = ['Kiosk', 'Tablet', 'Phone'];

  const tabs = [
    { id: 'tab1', name: 'Home', path: '/home' },
    { id: 'tab2', name: 'Overall Device Status', path: '/analytics' },
    { id: 'tab3', name: 'Dealer Wise Device Status', path: '/devicereport' },
    { id: 'tab4', name: 'Usage Analytics', path: '/usageDashboard' },
    { id: 'tab5', name: 'Overall Dealer Summary', path: '/usageReport' },
  ];

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 769);
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const activeTab = tabs.find(tab => location.pathname.startsWith(tab.path))?.id || 'tab1';

  const handleTabChange = (tab, deviceType) => {
    const selectedTab = tabs.find(t => t.id === tab);
    let path = selectedTab.path;

    // if (tab === 'tab2' && deviceType) {
    //   path = `${path}/${deviceType}`;
    // }

    localStorage.removeItem("selectedZone")
    localStorage.removeItem("selectedRegions")
    localStorage.removeItem("selectedContent")
   

    navigate(path);
  };

  return (
    <>
      <Navbar />
      <div className='statuss'>
        <div className='status-tab'>
          {!isMobile ? (
            <div className="tabs-flexbox-container">
              <div className='tabs-flexbox-container-two'>
                {tabs.map(tab => (
                  <div
                    key={tab.id}
                    style={{ color: activeTab === tab.id ? 'blue' : 'black' }}
                    className={activeTab === tab.id ? 'active-tab' : 'inactive-tab'}
                    onClick={() => handleTabChange(tab.id)}
                  >
                    {tab.name}
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <>
              <div className="mobile-select-container" style={{ width: "100%" }}>
                <FormControl fullWidth>
                  <Select
                    style={{
                      fontSize: "15.711px",
                      fontStyle: "normal",
                      textAlign: "center",
                      fontWeight: 400,
                      width: "100%",
                      lineHeight: "normal",
                      borderColor: "rgba(104, 82, 144, 1)",
                      backgroundColor: "white",
                      height: "40px",
                      color: "#333333",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                    value={activeTab}
                    onChange={(e) => {
                      const selectedTabId = e.target.value;
                      handleTabChange(selectedTabId);
                    }}
                    onOpen={() => setIsSelectOpen(true)}
                    onClose={() => setIsSelectOpen(false)}
                    renderValue={(selected) => {
                      const selectedTab = tabs.find(tab => tab.id === selected);
                      return selectedTab ? selectedTab.name : '';
                    }}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          width: "100%",
                          marginRight: '5px',
                          minWidth: "90%",
                        },
                      },
                      transformOrigin: {
                        vertical: 'top',
                        horizontal: 'center',
                      },
                      getContentAnchorEl: null,
                    }}
                  >
                    <div className='change-text-div'>change</div>
                    {tabs.map((tab) => (
                      <MenuItem key={tab.id} value={tab.id} className='option-containers'>
                        <Checkbox checked={activeTab === tab.id} />
                        <ListItemText primary={tab.name} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              {isSelectOpen && <div className="backdrop" />}
            </>
          )}
        </div>

        {activeTab === 'tab2' && (
          <div className="device-type-selector">
            <FormControl>
              {/* <Select
                value={location.pathname.split('/').pop()}
                onChange={(e) => handleTabChange('tab2', e.target.value)}
              >
                {deviceTypes.map((deviceType) => (
                  <MenuItem key={deviceType} value={deviceType}>
                    <ListItemText primary={deviceType} />
                  </MenuItem>
                ))}
              </Select> */}
            </FormControl>
          </div>
        )}
      </div>
    </>
  );
}

export default Dashboard;
