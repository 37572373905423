import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Dashboard from './Components/Dashboard';
import Dealersdetailpage from './Components/Dealersdetailpage';
import Login from './Components/Login';
import Devicestatusreport from './Components/Devicestatusreport';
import DeviceStatus from './Components/Devicestatus';
import Usagedashboard from './Components/Usagedashboard';
import Usagereport from './Components/Usagereport';
import Resetpassowordscreen from './Components/Resetpassowordscreen';
import DevicethreeReport from './Components/DevicethreeReport';
import Home from './Components/Home';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/home" element={<Home />} />
        <Route path="/analytics" element={<DeviceStatus />} />
        <Route path="/analytics/:deviceType" element={<DeviceStatus />} />
        <Route path="/devicereport" element={<Devicestatusreport />} />
        <Route path="/devicereport/:field/:deviceTypeTwo" element={<Devicestatusreport />} />
        {/* <Route path="/devicereport/:field/:deviceTypeTwo" element={<Devicestatusreport />} />
        <Route path="/devicereport/:field/:deviceTypeTwo" element={<Devicestatusreport />} /> */}
        
        {/* <Route path="/devicereport/:deviceTypeThree" element={<Devicestatusreport />} /> */}
        <Route path="/usageDashboard" element={<Usagedashboard />} />
        <Route path="/usageReport" element={<Usagereport />} />
        <Route path="/Resetpassword" element={<Resetpassowordscreen />} />
      </Routes>
    </Router>
  );
}

export default App;