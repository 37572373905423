import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import vector from '../Assets/Frame 2085660376 (1).png';
import Email from '../Assets/Frame 2085660376.png';
import Hyundaimage from '../Assets/hyundai.png';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

export default function Resetpassowordscreen() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [newpassword, setnewPassword] = useState('');
  const navigate = useNavigate();
  const [emailmessage, setEmailMessage] = useState('');
  const [passwordMessage, setPasswordMessage] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);




  // const  handleSubmits = () => {
  //   setShowPopup(true);
  // };

  const handleResetpassword = () =>{
    window.alert("hi")
  }


  console.log("naame",username)
  console.log("datapassowrd",password)
  console.log("data new passowrd",newpassword)
  

  const handleSubmit = (e) => {
    e.preventDefault();
    // Make API call to authenticate user
    
    axios
      .get(`http://hyundaianalytics.apprikart.com/digi_lens/dashboard_api/dashboard_reset_password/?username=${username}&current_password=${password}&new_password=${newpassword}`)
      .then((response) => {
        console.log("LOGINRESPONSE:", response);
        console.log("")
       
       
        if (response.data.status === "success") {
         
          // Navigate to the appropriate page upon successful login
          navigate('/'); // Replace '/analytics' with your desired route
        } else {
          setShowPopup(true);
          // Set appropriate error messages if login fails
          setPasswordMessage(response.data.error);
        }
      })
      .catch((error) => {
        console.error('LoginError:', error);

        // Handle error and set appropriate error messages
        if (error.response && error.response.status === 401) {
          setPasswordMessage('Incorrect username or password.');
        } else {
          setPasswordMessage('An error occurred. Please try again later.');
        }
      });
  };
  

  // const handleSubmits = (e) => {
  //   window.alert("hi")
  // }

  return (
    <>
      <section>
        <div className="form-container">
          <form>
            <div className='login-Hyundai-image'>
              <img src={Hyundaimage} alt="hyundaiumage" className='hyundaaimage-sec' />
            </div>
            <div className='login-form-controller'>
              <div className="control">
                <input
                  type="email"
                  placeholder="Enter Your Email id"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
                <img src={Email} alt="padlock" className="Email-icon" />
              </div>
              <label style={{ color: "red", fontSize: "12px" }}>{emailmessage}</label>
            </div>
            <div className='control'>
              <input
                type="password"
                placeholder="Current Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <img src={vector} alt="padlock" className='password-icon' />
              
           

            </div>

            {/* -new passowrd- */}
            <div className='control'>
              <input
                type="password"
                placeholder="New Password"
                value={newpassword}
                onChange={(e) => setnewPassword(e.target.value)}
              />
              <img src={vector} alt="padlock" className='password-icon' />
            </div>
           


           
          



            <div className="control">
              <button className="login" onClick={handleSubmit}>
                SIGN IN
              </button>
            </div>


          </form>
        </div>
    

      </section>
      {showPopup && (
  <div className="popup">
    <p>{passwordMessage}</p>
    <button onClick={() => setShowPopup(false)} className='close-btn'>Close</button>
  </div>
)}
    </>
  );
}
