import React, { useState, useEffect, useRef } from "react";
import "../Assets/components.css";
import { DatePicker } from "antd";
import { DialogContent } from "@mui/material";
// import { Table } from 'antd';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Button,
} from "@mui/material";

import axios from "axios";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import customSearchIcon from "../Assets/icon.png";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined"; // Import the ExpandMore icon
import Navbar from "./Navbar";
import Dashboard from "./Dashboard";
import { Link } from "react-router-dom";
import moment from "moment";

import Pagination from "@mui/material/Pagination";
import FilterListIcon from "@mui/icons-material/FilterList";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import Dialog from "@mui/material/Dialog";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import closeIcon from "../Assets/Closeicons.png";
import Selects from "react-select";
import { red } from "@mui/material/colors";

function UsageReport() {
  const [totalPages, setTotalPages] = useState();
  const [fetchingDetails, setFetchingDetails] = useState(false);
  const [applyClicked, setApplyClicked] = useState(false);

  // Calculate the total number of pages whenever tableData changes

  const [showapplybtn, setShowApplyBtn] = useState(false);

  const typeNames = [
    "Kiosk",
    "3D Configurator",
    "Centralized Waiting Period Display",
  ];

  const [apiResponse, setApiResponse] = useState(null);
  const [allZones, setAllZones] = useState(null);
  const allContentnames = ["Select All", "3D Configurator", "Kiosk"];
  const allContentnamesTwo = ["Select All", "3d configurator", "kiosk"];
  const [selectedContenttwo, setSelectedContentTwo] = useState(
    allContentnamesTwo.slice(1)
  );

  const [selectedContent, setContentStatus] = useState(
    allContentnames.slice(1)
  ); // Select all options except "Select All"
  const [formattedSelectedContentOptions, setFormattedSelectedContentOptions] =
    useState([]);
  const [newSelectedDeviceType, setNewSelectedDeviceType] =
    useState(selectedContent);

  const regionNames = [
    "Select All",
    "East",
    "West",
    "North",
    "South",
    "Central",
  ];

  const ActivityStatus = ["Select All", "online", "offline"];
  const contentupdate = ["Select All", "Pending", "Up to Date"];
  const appUpdate = ["Select All", "Pending", "Up to Date"];

  const allStatusnames = ["Select All", "online", "offline"];
  const [selectedStatus, setSelectedStatus] = useState(allStatusnames.slice(1));
  const [formattedSelectedStatusOptions, setFormattedSelectedStatusOptions] =
    useState([]);

  const [isMobile, setIsMobile] = useState(false);
  const [Date, setDate] = useState(["2023-12-11", "2023-12-29"]);
  const [internalSelectedType, setInternalSelectedType] = useState("Kiosk");

  const [internalCategory, setInternalCategory] = useState("");
  const [internalRegion, setInternalRegion] = useState(regionNames.slice(0));
  const [internalRegiontwo, setInternalRegiontwo] = useState("Select All");
  const [status, setstatus] = useState(ActivityStatus.slice(0));
  const [contentsync, setcontentsync] = useState(contentupdate.slice(0));
  const [appupdatesync, setappUpdatesync] = useState(appUpdate.slice(0));
  const [searchValue, setSearchValue] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDataEmpty, setIsDataEmpty] = useState(false);

  const currentDate = moment(); // Get today's date

  const defaultEndDate = currentDate.clone(); // Set end date to today's date
  const defaultStartDate = currentDate.clone().subtract(14, "days"); // Set start date 15 days before today

  const [startDate, setStartDate] = useState(defaultStartDate);
  const [endDate, setEndDate] = useState(defaultEndDate);

  const [regionnamestwo, setRegionNamestwo] = useState([]);
  const [zoneNamestwo, setZoneNamestwo] = useState([]);

  const [processedTableData, setProcessedTableData] = useState([]);

  const [startDateTwo, setStartDateTwo] = useState(defaultStartDate);
  const [endDateTwo, setEndDateTwo] = useState(defaultEndDate);

  const [tableData, setTableData] = useState([]);
  const [orginalData, setOrginalData] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [selectedOption, setSelectedOption] = useState(null);
  const [dealerCodes, setDealerCodes] = useState([]);

  // Default number of items per page
  const [totalItems, setTotalItems] = useState(0);
  const [dealerSelect, setDealerSelect] = useState(null);

  //  new one

  const [allregionZoneData, setAllRegionZoneData] = useState([]);
  const [allregionnames, setAllRegionNames] = useState([]);
  const [allzonenames, setAllZoneNames] = useState([]);
  const [selectedRegions, setSelectedRegions] = useState([]);
  const [selectedZone, setSelectedZone] = useState([]);
  const [showZoneSelect, setShowZoneSelect] = useState(true); // New state
  const [responseData, setResponseData] = useState(null);
  const [fetchdetaile, setfetchDetails] = useState(true);
  const [cardsection, setCardsection] = useState(false);

  const menuRef = useRef(null);
  const [menuLoaded, setMenuLoaded] = useState(false);

  // for mui popup
  useEffect(() => {
    if (menuRef.current && !menuLoaded) {
      // Scroll to the top only if the menu is loaded for the first time
      menuRef.current.scrollTop = 0;
      setMenuLoaded(true);
    }
  }, [menuLoaded]);

  // paginaation
  const [currentPage, setCurrentPage] = useState(1);
  const [newZones, setNewZones] = useState(selectedRegions);
  const [recordsPerPage, setrecordsPerPage] = useState(); // Set the number of records per page
  const [totalRecords, setTotalRecords] = useState();

  const [deviceType, setdeviceType] = useState();

  const downloaadreport = "True";
  const currentPagetwo = 2;

  // for region

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 769);
    };

    // Initial check for screen size
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const dealerApi = `https://digilens.myhyundai.co.in/digi_lens/dashboard_api/get_all_dealer/?username=${storedUsername}&permission=${JSON.stringify(
      storedPermission
    )}&device_type_array=${JSON.stringify(selectedContent)}`;
    console.log("Dealer API URL:", dealerApi);

    axios
      .get(dealerApi)
      .then((response) => {
        console.log("dealerResponsefordata", response);
        const codes = response.data.map((dealer) => ({
          value: dealer.dealer_code,
          label: `${dealer.dealer_code} (${dealer.dealer_name})`,
        }));
        setDealerCodes(codes);
        console.log("codes", codes);
      })
      .catch((error) => {
        console.error("Error fetching dealer codes:", error);
      });
  }, []);

  const handleDateChange = (dates) => {
    if (dates && dates.length === 2) {
      const [start, end] = dates;
      setStartDate(start);
      setEndDate(end);
    } else if (!dates || dates.length === 0) {
      // Clear start date when both dates are cleared
      setStartDate(null);
      setEndDate(null); // Also clear end date explicitly
    }
    // Always trigger fetchDetails and setCardsection updates
    setfetchDetails(true);
    setCardsection(false);
    setFetchingDetails(false);
    setShowApplyBtn(true);
    setSearchValue("");
    setApplyClicked(false);
  };

  useEffect(() => {
    console.log("startdate", startDate);
  }, [startDate]);

  useEffect(() => {
    console.log("EndDate", endDate);
  }, [endDate]);

  const { RangePicker } = DatePicker;

  useEffect(() => {
    console.log("formateddate", Date);
  }, [Date]);
  console.log("Date", Date);

  const formatDatesForAPI = (date) => {
    if (date) {
      return date.format("DD/MM/YYYY");
    } else {
      // Handle the case when date is null
      return ""; // Or any other default value that makes sense in your context
    }
  };

  useEffect(() => {
    console.log("formateedDates", Date);
  }, [Date]);

  useEffect(() => {
    // This effect ensures all options are checked when the component loads
    setContentStatus(allContentnames.slice(1));
    setFormattedSelectedContentOptions(
      allContentnames.slice(1).map((option) => option.toLowerCase())
    );
    // setFormattedSelectedAppOptions(allAppnames.slice(1).map(option => option.toLowerCase()));
    console.log("useefectcontent", formattedSelectedContentOptions);
  }, []); // Run only once when component mounts

  console.log("selectedContent", selectedContent);

  const storedPermission = JSON.parse(localStorage.getItem("permission"));
  const storedUsername = localStorage.getItem("username");

  useEffect(() => {
    newRegionApi();
  }, []);

  const newRegionApi = () => {
    const regionApi = `https://digilens.myhyundai.co.in/digi_lens/dashboard_api/get_region_zone_list/?username=${storedUsername}&permission=${JSON.stringify(
      storedPermission
    )}`;
    console.log("newRegionApi", regionApi);

    axios
      .get(regionApi)
      .then((response) => {
        console.log("line 379 triggered");
        console.log("Response from device status API:", response);
        setApiResponse(response.data);
        if (Array.isArray(response.data)) {
          // Handle response with array structure
          const regionNames = response.data.map(
            (singleRegion) => Object.keys(singleRegion)[0]
          );
          setAllRegionNames(regionNames);
          setAllRegionZoneData(response.data);
          setSelectedRegions(regionNames);
          console.log(regionNames);
          setNewZones(regionNames);
          setRegionNamestwo(regionNames);

          // Filter zones for selected regions and flatten the array
          const allZones = response.data.flatMap(
            (regionData) => Object.values(regionData)[0]
          );
          setSelectedZone(allZones);
          setAllZones(allZones);
          setZoneNamestwo(allZones);
          recordApi(allZones, regionNames);
        } else if (typeof response.data === "object") {
          // Handle response with object structure
          const regionNames = Object.keys(response.data);
          console.log(regionNames);
          setAllRegionNames(regionNames);
          setAllRegionZoneData(
            regionNames.map((regionName) => ({
              [regionName]: response.data[regionName],
            }))
          );

          // Set selected regions and corresponding zones
          setSelectedRegions(regionNames);
          setRegionNamestwo(regionNames);

          // Filter zones for selected regions and flatten the array
          const allZones = regionNames.flatMap(
            (regionName) => response.data[regionName]
          );
          setSelectedZone(allZones);
          setAllZones(allZones);
          setZoneNamestwo(allZones);
          recordApi(allZones, regionNames);
          setNewZones(regionNames);

        } else {
          // Handle unexpected response structure
          console.error("Unexpected response structure:", response);
        }
      })
      .catch((error) => {
        console.error("Error fetching region and zone data:", error);
      });
  };

  const recordApi = (zones, regions) => {
    const newTypeNames = typeNames.map((ele) => ele.toLowerCase());
    const apiUrl = `https://digilens.myhyundai.co.in/digi_lens/dashboard_api/usage_report/?username=${storedUsername}&permission=${JSON.stringify(
      storedPermission
    )}&device_type_array=${JSON.stringify(newTypeNames)}&zone=${JSON.stringify(
      regions
    )}&region=${JSON.stringify(zones)}&start_date=${formatDatesForAPI(
      startDate
    )}&end_date=${formatDatesForAPI(endDate)}`;

    // const apiUrls=http://hyundaianalytics.apprikart.com/digi_lens/dashboard_api/usage_report/?username=hmil&permission=["HMIL"]&device_type_array=["kiosk","3d configurator"]&zone=["North"]&region=["N1"]&start_date=05/04/2024&end_date=10/04/2024

    console.log("APITwoURL:", apiUrl);

    axios
      .get(apiUrl)
      .then((response) => {
        console.log("responseDeviceUsageReport", response);
        // setTableData(response.data.data);
        console.log("devicereport", response.data.data);
        console.log("record limit", response.data.meta);
        setrecordsPerPage(response.data.meta.record_limit_per_page);
        setCurrentPage(response.data.meta.current_page);
        setTotalPages(response.data.meta.total_pages);
        console.log("total_records", response.data.meta.total_records);
        console.log("totalrecordone", response.data.meta.record_limit_per_page);
        console.log("setdeviceTypeone", response.data.data);
        // setTotalRecords(response.data.meta.totalRecords); // Assuming the response contains the total number of records
      })
      .catch((error) => {
        console.error("Error device status report:", error);
      });
  };

  useEffect(() => {
    console.log("tableDatatwo", tableData);
  }, [tableData]);
  console.log("currentpage", currentPage);
  console.log("record per page", recordsPerPage);

  // useeffect to fetch current data
  // useEffect(() => {

  // },[]);

  // modal

  // final api response

  // useEffect(() => {
  //   axios.get(http://hyundaianalytics.apprikart.com/digi_lens/dashboard_api/device_status_dashboard_data/?username=${storedUsername}&permission=${JSON.stringify(storedPermission)}&device_type=${internalSelectedType}&zone=${JSON.stringify(selectedRegions)}&region=${JSON.stringify(selectedZone)})
  //     .then((response) => {
  //       console.log("final Response", response.data);
  //       setResponseData(response.data);

  //     })
  //     .catch((error) => {
  //       console.error('Error fetching device status dashboard data:', error);
  //     });
  // }, [internalSelectedType, selectedRegions, selectedZone]);

  const handleRegionChange = (event, regionName) => {
    setfetchDetails(true);
    setShowZoneSelect(true);
    setCardsection(false);
    setShowApplyBtn(true);
    setSearchValue("");
    setApplyClicked(false);

    const { checked } = event.target;
    let updatedSelectedRegions = [...selectedRegions];

    console.log("line 443", regionName);

    // Initialize zoneArray and newRegions
    let zoneArray = [];
    let newRegions = [];

    if (regionName === "select-all") {
      // Handle "Select All" logic
      if (checked) {
        // Select all regions
        newRegions = allregionnames;
        if (Array.isArray(apiResponse)) {
          zoneArray = apiResponse.flatMap((obj) => Object.values(obj)[0]);
        } else if (typeof apiResponse === "object" && apiResponse !== null) {
          zoneArray = Object.values(apiResponse).flat();
        }
      } else {
        // Deselect all regions
        newRegions = [];
        zoneArray = [];
      }
    } else {
      // Handle individual region logic
      if (checked) {
        // Add the region
        newRegions = [...updatedSelectedRegions, regionName];
      } else {
        // Remove the region
        newRegions = updatedSelectedRegions.filter(
          (region) => region !== regionName
        );
      }

      if (Array.isArray(apiResponse)) {
        const filteredData = apiResponse.filter((obj) =>
          newRegions.includes(Object.keys(obj)[0])
        );
        zoneArray = filteredData.flatMap((obj) => Object.values(obj)[0]);
      } else if (typeof apiResponse === "object" && apiResponse !== null) {
        const filteredData = Object.keys(apiResponse).filter((key) =>
          newRegions.includes(key)
        );
        zoneArray = filteredData.flatMap((key) => apiResponse[key]);
      }
    }

    setSelectedRegions(newRegions);
    setSelectedZone(zoneArray);
    setAllZones(zoneArray); // Update allZones to contain only the zones of the selected regions
    setfetchDetails(false);

    console.log("Updated selection:", regionName, zoneArray, newRegions);
  };

  const handleSelectAll = () => {
    setfetchDetails(true);
    setCardsection(false);
    setFetchingDetails(false);
    setShowApplyBtn(true);
    setApplyClicked(false);
    if (selectedRegions.length === allregionnames.length) {
      setSelectedRegions([]);
      setSelectedZone([]);
    } else {
      setSelectedRegions(allregionnames);
      const allZones = allregionZoneData.flatMap(
        (regionData) => Object.values(regionData)[0]
      );
      setSelectedZone(allZones.flat());
    }
  };

  const handleZoneChange = (event, zone) => {
    console.log("handle zone change triggered");
    setfetchDetails(true);
    setCardsection(false);
    setSearchValue("");
    setApplyClicked(false);

    const { value } = event.target;
    let newSelectedZones;

    const filteredZones = selectedRegions.flatMap((region) =>
      allregionZoneData.flatMap((regionData) => regionData[region] || [])
    );

    console.log("line 576", value, filteredZones, selectedZone);

    if (value === "select-all") {
      const allZones = allregionZoneData.flatMap((regionData) =>
        Object.values(regionData).flat()
      );
      newSelectedZones =
        selectedZone.length === filteredZones.length ? [] : filteredZones;
    } else {
      newSelectedZones = selectedZone.includes(zone)
        ? selectedZone.filter((selected) => selected !== zone)
        : [...selectedZone, zone];
    }

    setSelectedZone(newSelectedZones);
    setFetchingDetails(false);
    setShowApplyBtn(true);
  };

  // useEfFECT FOR SEARCH

  // useEffect(() => {

  //   axios.get(http://hyundaianalytics.apprikart.com/digi_lens/dashboard_api/usage_report/?username=${storedUsername}&permission=${JSON.stringify(storedPermission)}&device_type_array=${JSON.stringify(selectedContent)}&zone=${JSON.stringify(selectedRegions)}&region=${JSON.stringify(selectedZone)}&start_date=${formatDatesForAPI(startDate)}&end_date=${formatDatesForAPI(endDate)})

  //     .then((response) => {
  //       console.log("devicereport", response.data.data);
  //       console.log("metadata",response.data.meta.total_records)
  //       console.log("record_limit_per_page",response.data.meta.record_limit_per_page)
  //       setTableData(response.data.data);
  //       setOrginalData(response.data.data);
  //       setTotalRecords(response.data.meta.total_records);
  //       console.log("total_records",response.data.meta.total_records)
  //       // setrecordsPerPage(response.data.meta.record_limit_per_page)
  //       setTotalPages(response.data.meta.total_pages)
  //       console.log("tabledata", tableData)
  //       console.log("totalrecordtwo",response.data.meta.record_limit_per_page)

  //     })
  //     .catch((error) => {
  //       console.error('Error device status report :', error);
  //     });
  // }, [internalSelectedType, selectedRegions, selectedZone,currentPage, recordsPerPage]);

  // ----start of activity status code------

  // --------end of activty status code------
  // ----start of content update code----
  const handleContentChange = (event) => {
    setfetchDetails(true);
    setCardsection(false);
    setFetchingDetails(false);
    setShowApplyBtn(true);
    setSearchValue("");
    setApplyClicked(false);

    const { value } = event.target;

    if (value.includes("Select All")) {
      handleSelectContent();
    } else {
      const contentupdatedValue = value.filter((val) => val !== "Select All");
      const lowercaseOptions = contentupdatedValue.map((option) =>
        option.toLowerCase()
      );
      setContentStatus(contentupdatedValue);
      setFormattedSelectedContentOptions(lowercaseOptions);
    }
  };

  const handleSelectContent = () => {
    setfetchDetails(true);
    setCardsection(false);
    setFetchingDetails(false);
    setShowApplyBtn(true);
    setSearchValue("");
    setApplyClicked(false);

    if (selectedContent.length === allContentnames.length - 1) {
      setContentStatus([]);
      const lowercaseOptions = [];
      setFormattedSelectedContentOptions(lowercaseOptions);
    } else {
      const ContupdatedValue = allContentnames.slice(1);
      const lowercaseOptions = ContupdatedValue.map((option) =>
        option.toLowerCase()
      ); // Convert to lowercase
      setContentStatus(ContupdatedValue);
      setFormattedSelectedContentOptions(lowercaseOptions); // Set lowercase options
    }
  };

  const contentareAllOptionsSelected = () => {
    return selectedContent.length === allContentnames.length - 1;
  };

  // ---end of content update code-----
  // ---start of app updaate-----

  // ----end of app update------
  // ---console log for api data----

  console.log("devicestype", internalSelectedType);
  console.log("selectedRegions", selectedRegions);
  console.log("selectedZone", selectedZone);

  // dummy table data

  //   // Add more data here if needed
  // ];

  const handleSearch = (e) => {
    const searchInput = e.target.value;
    setSearchValue(searchInput);

    // Check if the search input is not empty
    if (searchInput.trim() !== "") {
      // axios.get(http://hyundaianalytics.apprikart.com/digi_lens/dashboard_api/device_status_table_data/?username=${storedUsername}&permission=${JSON.stringify(storedPermission)}&device_type=${internalSelectedType}&zone=${JSON.stringify(selectedRegions)}&region=${JSON.stringify(selectedZone)}&device_status=${JSON.stringify(formattedSelectedStatusOptions)}&dealer_code=${searchInput})
      // Sample Request:
      // http://hyundaianalytics.apprikart.com/digi_lens/dashboard_api/usage_report/?username=hmil&permission=["HMIL"]&device_type_array=["kiosk","3d configurator"]&zone=["North"]&region=["N1"]&start_date=05/04/2024&end_date=10/04/2024&dealer_code=N5203
      axios
        .get(
          `https://digilens.myhyundai.co.in/digi_lens/dashboard_api/usage_report/?username=${storedUsername}&permission=${JSON.stringify(
            storedPermission
          )}&device_type_array=${JSON.stringify(
            formattedSelectedContentOptions
          )}&zone=${JSON.stringify(selectedRegions)}&region=${JSON.stringify(
            selectedZone
          )}&start_date=${formatDatesForAPI(
            startDate
          )}&end_date=${formatDatesForAPI(endDate)}&dealer_code=${searchInput}`
        )

        // axios.get(``)

        .then((response) => {
          console.log("new search", response.data);
          console.log("search data", response.data.data);
          // Update your state with the search results
          setTableData(response.data.data);

          const searchData = response.data.data;
          setIsDataEmpty(searchData.length === 0);
        })
        .catch((error) => {
          console.error("Error device status report:", error);
        });
    } else {
      // Handle the case where the search input is empty
      console.log("Search input is empty");
      // Reset your table data to the original data if needed
      setTableData(orginalData);
    }
  };

  const columns = [
    { title: "ID", dataIndex: "id", key: "id" },
    { title: "Dealer Code", dataIndex: "Dealercode", key: "Dealercode" },
    { title: "Dealer Name", dataIndex: "DealerName", key: "DealerName" },
    { title: "Device No", dataIndex: "DeviceNo", key: "DeviceNo" },
    { title: "Status", dataIndex: "status", key: "status" },
    {
      title: "Content Update",
      dataIndex: "ContentUpdate",
      key: "ContentUpdate",
    },
    { title: "App Update", dataIndex: "AppUpdate", key: "AppUpdate" },
  ];

  // custom pagination
  const deviceTypes = [
    ...new Set(
      tableData.flatMap((dealer) =>
        dealer.device_status_data.map((device) => device.device)
      )
    ),
  ];

  // Preprocess the data to ensure each dealer has a complete set of device types
  // const newProcessedTableData = tableData.map((dealer) => {
  //   const deviceDataMap = {};
  //   deviceTypes.forEach((deviceType) => {
  //     deviceDataMap[deviceType] = {
  //       content_update_status: "-",
  //       activity_status: "-",
  //     };
  //   });

  //   dealer.device_status_data.forEach((deviceData) => {
  //     deviceDataMap[deviceData.device] = {
  //       content_update_status: deviceData.content_update_status,
  //       activity_status: deviceData.activity_status,
  //     };
  //   });

  //   return { ...dealer, deviceDataMap };
  // });

  useEffect(() => {
    const newProcessedTableData = tableData.map((dealer) => {
      const deviceDataMap = {};
      deviceTypes.forEach((deviceType) => {
        deviceDataMap[deviceType] = {
          content_update_status: "-",
          activity_status: "-",
        };
      });

      dealer.device_status_data.forEach((deviceData) => {
        deviceDataMap[deviceData.device] = {
          content_update_status: deviceData.content_update_status,
          activity_status: deviceData.activity_status,
        };
      });

      return { ...dealer, deviceDataMap };
    });
    setProcessedTableData(newProcessedTableData);
  }, [tableData, selectedOption]);

  useEffect(
    () =>
      console.log("line 726", processedTableData, tableData, selectedOption),
    [processedTableData]
  );

  const fetchData = () => {
    console.log("recorPerpage", recordsPerPage);
    const apiurlFina = `https://digilens.myhyundai.co.in/digi_lens/dashboard_api/usage_report/?username=${storedUsername}&permission=${JSON.stringify(
      storedPermission
    )}&device_type_array=${JSON.stringify(
      formattedSelectedContentOptions
    )}&zone=${JSON.stringify(selectedRegions)}&region=${JSON.stringify(
      selectedZone
    )}&start_date=${formatDatesForAPI(startDate)}&end_date=${formatDatesForAPI(
      endDate
    )}&records_per_page=${recordsPerPage}&current_page=${currentPage}`;

    console.log("apiurlFinalone", apiurlFina);

    axios
      .get(apiurlFina)
      .then((response) => {
        console.log("devicereport", response.data.data);
        console.log("metadata", response.data.meta.total_records);
        console.log(
          "record_limit_per_page",
          response.data.meta.record_limit_per_page
        );
        setTableData(response.data.data);

        setTotalRecords(response.data.meta.total_records);
        console.log("total_records", response.data.meta.total_records);
        // setrecordsPerPage(response.data.meta.record_limit_per_page)
        setTotalPages(response.data.meta.total_pages);
        console.log("tabledata", tableData);
        console.log("totalrecordtwo", response.data.meta.record_limit_per_page);
      })
      .catch((error) => {
        console.error("Error device status report :", error);
      });
  };

  const fetchDatatwo = () => {
    // API call with dependencies
    const apiurlFina = `https://digilens.myhyundai.co.in/digi_lens/dashboard_api/usage_report/?username=${storedUsername}&permission=${JSON.stringify(
      storedPermission
    )}&device_type_array=${JSON.stringify(
      formattedSelectedContentOptions
    )}&zone=${JSON.stringify(selectedRegions)}&region=${JSON.stringify(
      selectedZone
    )}&start_date=${formatDatesForAPI(startDate)}&end_date=${formatDatesForAPI(
      endDate
    )}&records_per_page=${recordsPerPage}&current_page=${currentPage}`;

    console.log("apiurlFinaltwo", apiurlFina);

    axios
      .get(apiurlFina)
      .then((response) => {
        setTableData([]);
        setOrginalData([]);
        console.log("devicereport", response.data.data);
        console.log("metadata", response.data.meta.total_records);
        console.log(
          "record_limit_per_page",
          response.data.meta.record_limit_per_page
        );
        console.log("line 763", response.data);
        setTableData(response.data.data);
        setOrginalData(response.data.data);
        setTotalRecords(response.data.meta.total_records);
        setTotalPages(response.data.meta.total_pages);
      })
      .catch((error) => {
        console.error("Error device status report:", error);
      });
  };

  useEffect(() => {
    
    // Call fetchDatatwo function when the dependencies change
    if(allZones?.length>0 ){
    fetchDatatwo();
    }
   
  }, [recordsPerPage, currentPage]);

  const handleChange = (selectedOption) => {
    console.log("line 777", selectedOption);

    setSelectedOption(selectedOption);
    setTableData([]);
    setProcessedTableData();
    const selectedValue = selectedOption ? selectedOption.value : "";
    setDealerSelect(selectedValue);

    console.log("Option selected:", selectedOption);
    console.log("selectedDealers", selectedValue);

    const apiUrl = `https://digilens.myhyundai.co.in/digi_lens/dashboard_api/usage_report/?username=${storedUsername}&permission=${JSON.stringify(
      storedPermission
    )}&device_type_array=${JSON.stringify(
      formattedSelectedContentOptions
    )}&zone=${JSON.stringify(selectedRegions)}&region=${JSON.stringify(
      selectedZone
    )}&start_date=${formatDatesForAPI(startDate)}&end_date=${formatDatesForAPI(
      endDate
    )}`;
    if (selectedValue !== "") {
      const searchApiUrltwo = `${apiUrl}&dealer_code=${selectedValue}`;
      console.log("API URLsearch:", searchApiUrltwo);

      axios
        .get(searchApiUrltwo)
        .then((response) => {
          console.log("devicereport", response.data.data);
          console.log("metadata", response.data.meta.total_records);
          console.log(
            "record_limit_per_page",
            response.data.meta.record_limit_per_page
          );

          setTableData(response.data.data);

          const newProcessedTableData = response.data.data.map((dealer) => {
            const deviceDataMap = {};
            deviceTypes.forEach((deviceType) => {
              deviceDataMap[deviceType] = {
                content_update_status: "-",
                activity_status: "-",
              };
            });

            dealer.device_status_data.forEach((deviceData) => {
              deviceDataMap[deviceData.device] = {
                content_update_status: deviceData.content_update_status,
                activity_status: deviceData.activity_status,
              };
            });

            return { ...dealer, deviceDataMap };
          });

          setProcessedTableData(newProcessedTableData);

          setTotalRecords(response.data.meta.total_records);
          console.log("total_records", response.data.meta.total_records);
          // setrecordsPerPage(response.data.meta.record_limit_per_page)
          setTotalPages(response.data.meta.total_pages);
          console.log("tabledata", tableData);
          console.log(
            "totalrecordtwo",
            response.data.meta.record_limit_per_page
          );
        })

        .catch((error) => {
          console.error("Error fetching usage dashboard data:", error);
        });
    } else {
      console.log("Fetching data without dealer filter");
      fetchData();
    }
  };

  const handleFetchdetails = () => {
    setCurrentPage(1);
    setNewSelectedDeviceType(selectedContent);
    setfetchDetails(false);
    setCardsection(true);
    setFetchingDetails(true);
    setTableData([]);
    setShowApplyBtn(true);
    setApplyClicked(true);
    console.log("line 837", selectedRegions);
    setNewZones(selectedRegions); // state update to change the filter method of table when apply btn is clicked
    fetchDatatwo(); // Call the fetchDatatwo function when handleFetchdetails is invoked
  };

  // useEffect(() => {
  //   if (!showapplybtn) {
  //     // fetchData(); // Call the fetchData function when showapplybtn is false
  //   }
  // },[formattedSelectedContentOptions,selectedRegions,selectedZone,startDate,endDate,recordsPerPage,currentPage] );

  const handleClearFilters = () => {
    console.log("Clearing filters...");
    setSelectedOption(null);
    setContentStatus(allContentnames.slice(1));
    // setSelectedRegions(allregionnames);
    // setSelectedZone(allZones);
    // setNewSelectedDeviceType(selectedContent)
    setStartDate(defaultStartDate);
    setEndDate(defaultEndDate);
    setStartDateTwo(null);
    setEndDateTwo(null);

    setFormattedSelectedContentOptions(
      allContentnames.slice(1).map((ele) => ele.toLowerCase())
    );
    console.log("allContentnames:", allContentnames.slice(1));
    console.log("allregionnames:", allregionnames);
    console.log("allZones:", allZones);
    newRegionApi();

    setApplyClicked(false);
    setShowApplyBtn(false);

    // http://hyundaianalytics.apprikart.com/digi_lens/dashboard_api/usage_dashboard/?username=${storedUsername}&permission=${JSON.stringify(storedPermission)}&device_type_array=${JSON.stringify(formattedSelectedContentOptions)}&zone=${JSON.stringify(selectedRegions)}&region=${JSON.stringify(selectedZone)}&start_date=${formatDatesForAPI(startDate)}&end_date=${formatDatesForAPI(endDate)}&product_array=${JSON.stringify(productList)}
    // const apiUrl = `http://hyundaianalytics.apprikart.com/digi_lens/dashboard_api/usage_dashboard/?username=${storedUsername}&permission=${JSON.stringify(storedPermission)}&device_type_array=${JSON.stringify(selectedContenttwo)}&zone=${JSON.stringify(regionnamestwo)}&region=${JSON.stringify(zoneNamestwo)}&start_date=${formatDatesForAPI(defaultStartDate)}&end_date=${formatDatesForAPI(defaultEndDate)}`;
    const apiurlFina = `https://digilens.myhyundai.co.in/digi_lens/dashboard_api/usage_report/?username=${storedUsername}&permission=${JSON.stringify(
      storedPermission
    )}&device_type_array=${JSON.stringify(
      selectedContenttwo
    )}&zone=${JSON.stringify(regionnamestwo)}&region=${JSON.stringify(
      zoneNamestwo
    )}&start_date=${formatDatesForAPI(
      defaultStartDate
    )}&end_date=${formatDatesForAPI(
      defaultEndDate
    )}&records_per_page=${recordsPerPage}&current_page=${currentPage}`;

    console.log("apiurlFinalonereport", apiurlFina);

    axios
      .get(apiurlFina)
      .then((response) => {
        console.log("devicereport", response.data.data);
        console.log("metadata", response.data.meta.total_records);
        console.log(
          "record_limit_per_page",
          response.data.meta.record_limit_per_page
        );
        setTableData(response.data.data);

        setTotalRecords(response.data.meta.total_records);
        console.log("total_records", response.data.meta.total_records);
        // setrecordsPerPage(response.data.meta.record_limit_per_page)
        setTotalPages(response.data.meta.total_pages);
        console.log("tabledata", tableData);
        console.log("totalrecordtwo", response.data.meta.record_limit_per_page);
      })
      .catch((error) => {
        console.error("Error device status report :", error);
      });
  };

  // pagination
  const handlePageChange = (event, newPage) => {
    console.log("cuerrentpage", currentPage);
    // Increment current page by one when the next arrow is clicked
    if (newPage === currentPage + 1) {
      setCurrentPage(currentPage + 1);
    } else {
      setCurrentPage(newPage);
    }
  };

  // useeffect for search

  const DownloadReport = () => {
    // window.alert("downloaded")

    const downloadapiUrl = `https://digilens.myhyundai.co.in/digi_lens/dashboard_api/usage_report/?username=${storedUsername}&permission=${JSON.stringify(
      storedPermission
    )}&device_type_array=${JSON.stringify(
      formattedSelectedContentOptions
    )}&zone=${JSON.stringify(selectedRegions)}&region=${JSON.stringify(
      selectedZone
    )}&start_date=${formatDatesForAPI(startDate)}&end_date=${formatDatesForAPI(
      endDate
    )}&records_per_page=${recordsPerPage}&current_page=${currentPage}&download=${downloaadreport}`;
    axios
      .get(downloadapiUrl, { responseType: "blob" }) // Set responseType to 'blob'
      .then((response) => {
        // Create a Blob from the response data
        const blob = new Blob([response.data], {
          type: response.headers["content-type"],
        });

        // Create a temporary URL for the Blob
        const url = window.URL.createObjectURL(blob);

        // Create an anchor element to trigger the download
        const link = document.createElement("a");
        link.href = url;
        link.download = "report.csv"; // Set the desired filename
        document.body.appendChild(link);

        // Trigger the download
        link.click();

        // Cleanup
        window.URL.revokeObjectURL(url);
        document.body.removeChild(link);
      })
      .catch((error) => {
        // Handle errors if any
        console.error("Error downloading report:", error);
      });
  };

  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const handleFilterClicks = () => {
    setIsFilterOpen(true);
  };

  const handleCloseFilter = () => {
    setIsFilterOpen(false);
  };

  const [accordionExpandeds, setAccordionExpandeds] = useState(false);
  const [ZoneAccordianExpanded, setZoneAccordianExpanded] = useState(false);
  const [regionAccordionExpanded, setRegionAccordionExpanded] = useState(false);

  // Function to toggle accordion state
  const toggleRegionAccordion = () => {
    setRegionAccordionExpanded(!regionAccordionExpanded);
    setZoneAccordianExpanded(false);
    setAccordionExpandeds(false);
  };

  const handleDeviceAccordianChange = () => {
    setAccordionExpandeds(!accordionExpandeds);
    setRegionAccordionExpanded(false);
    setZoneAccordianExpanded(false);
  };

  const handlezoneAccordianChange = () => {
    setZoneAccordianExpanded(!ZoneAccordianExpanded);
    setAccordionExpandeds(false);
    setRegionAccordionExpanded(false);
  };

  const handleRegionChanges = (event, regionName) => {
    setfetchDetails(true);
    setShowZoneSelect(true);
    setCardsection(false);
    setShowApplyBtn(true);
    setSearchValue("");
    setApplyClicked(false);

    setFetchingDetails(false);

    const { checked, value } = event.target;
    console.log("value in line 340", value);

    let zoneArray = [];

    if (Array.isArray(apiResponse)) {
      // Handle case when apiResponse is an array
      if (value.includes("select-all")) {
        // If 'select-all' is checked, include all zones
        zoneArray = apiResponse.flatMap((obj) => Object.values(obj)[0]).flat();
      } else {
        // If individual regions are selected, filter the data accordingly
        const filteredData = apiResponse.filter((obj) => {
          const key = Object.keys(obj)[0];
          return value.includes(key);
        });
        // Extract values (zones) associated with each selected region
        zoneArray = filteredData.flatMap((obj) => Object.values(obj)[0]);
      }
    } else if (typeof apiResponse === "object" && apiResponse !== null) {
      // Handle case when apiResponse is an object
      if (value.includes("select-all")) {
        // If 'select-all' is checked, include all zones
        zoneArray = Object.values(apiResponse).flat();
      } else {
        // If individual regions are selected, filter the data accordingly
        const filteredData = Object.keys(apiResponse).filter((key) =>
          value.includes(key)
        );
        // Extract values (zones) associated with each selected region
        zoneArray = filteredData.flatMap((key) => apiResponse[key]);
      }
    } else {
      // Handle unexpected response structure
      console.error("Unexpected apiResponse structure:", apiResponse);
      zoneArray = [];
    }

    console.log("line 338", value, zoneArray, selectedZone);

    // Update states accordingly
    setAllZones(zoneArray);
    setSelectedZone(zoneArray);

    // added menu props in select and added bottom 2 lines to remove scroll

    // const scrollTop = menuRef.current.scrollTop;
    // menuRef.current.scrollTop = scrollTop;

    const newSelectedRegions =
      typeof value === "string" ? value.split(",") : value;

    if (newSelectedRegions.includes("select-all")) {
      setSelectedRegions(
        selectedRegions.length === allregionnames.length ? [] : allregionnames
      );
    } else {
      setSelectedRegions(newSelectedRegions);
    }

    setShowZoneSelect(newSelectedRegions.length > 0);
  };

  const handleZoneChanges = (event) => {
    console.log("handle zone chaange triggered");
    setfetchDetails(true);
    setCardsection(false);
    setSearchValue("");
    setApplyClicked(false);
    console.log("line 406", event.target.value);
    setSelectedZone(event.target.value);

    setFetchingDetails(false);
    setShowApplyBtn(true);

    console.log("handle zone chaange triggered");
    setfetchDetails(true);
    setCardsection(false);
    console.log("line 406", event.target.value);
    setSelectedZone(event.target.value);

    setFetchingDetails(false);
    setShowApplyBtn(true);

    const { value } = event.target;
    const newSelectedZones =
      typeof value === "string" ? value.split(",") : value;

    // Filter zones based on selectedRegions
    const filteredZones = selectedRegions.flatMap((region) =>
      allregionZoneData.flatMap((regionData) => regionData[region] || [])
    );

    if (newSelectedZones.includes("select-all")) {
      console.log(
        value,
        selectedZone,
        filteredZones,
        selectedRegions,
        "line 597"
      );
      setSelectedZone(
        selectedZone.length === filteredZones.length ? [] : filteredZones
      );
    } else {
      setSelectedZone(newSelectedZones);
    }
  };

  const handleContentChanges = (event) => {
    setfetchDetails(true);
    setCardsection(false);
    setFetchingDetails(false);
    setShowApplyBtn(true);
    setSearchValue("");
    setApplyClicked(false);
    const { value, checked } = event.target;

    if (value === "Select All") {
      handleSelectContent(checked);
    } else {
      // If "Select All" is checked, uncheck it
      const contentupdatedValue = selectedContent.includes("Select All")
        ? selectedContent.filter((val) => val !== "Select All")
        : selectedContent;

      // Update the selected content based on the checkbox status
      const updatedContent = checked
        ? [...contentupdatedValue, value]
        : contentupdatedValue.filter((val) => val !== value);

      // Convert to lowercase
      const lowercaseOptions = updatedContent.map((option) =>
        option.toLowerCase()
      );

      // Update the state with the new selected content
      setContentStatus(updatedContent);
      setFormattedSelectedContentOptions(lowercaseOptions); // lowercase options
    }
  };

  useEffect(() => {
    // This effect ensures all options are checked when the component loads
    setContentStatus(allContentnames.slice(1));
    setFormattedSelectedContentOptions(
      allContentnames.slice(1).map((option) => option.toLowerCase())
    );
    // setFormattedSelectedAppOptions(allAppnames.slice(1).map(option => option.toLowerCase()));
    console.log("useefectcontent", formattedSelectedContentOptions);
  }, []);

  // Run only once when component mounts

  console.log(
    "formattedSelectedContentOptions",
    formattedSelectedContentOptions
  );

  console.log("selectedContent", selectedContent);

  const disabledDate = (current) => {
    if (!startDate) return false; // Ensure startDate is defined
    const startDatePlus45Days = startDate.clone().add(45, "days");
    //  setEndDate(startDatePlus45Days);
    return current && (current < startDate || current > startDatePlus45Days);
  };


  const customStyles = {
    control: (provided) => ({
      ...provided,
      cursor: 'pointer',
    }),
    option: (provided) => ({
      ...provided,
      cursor: 'pointer',
    }),
    singleValue: (provided) => ({
      ...provided,
      cursor: 'pointer',
    }),
    placeholder: (provided) => ({
      ...provided,
      cursor: 'pointer',
    }),
    clearIndicator: (provided) => ({
      ...provided,
      cursor: 'pointer',
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      cursor: 'pointer',
    }),
  };
  


  const tableContainerRef = useRef(null);

  const scrollTable = (direction) => {
    if (tableContainerRef.current) {
      const scrollAmount = 100; // Adjust scroll amount as needed
      tableContainerRef.current.scrollLeft += direction === "left" ? -scrollAmount : scrollAmount;
    }
  };



  return (
    <>
      <Navbar />
      <Dashboard />

      {!isMobile ? (
        // desktop ui
        <>
          <div className="device-status-full-contain-section-statusreport">
            <div className="hyundai-content-section-statusreport">
              {/* ----------------------device type---------------- */}
              <div className="zone-flex-container-report">
                <div
                  className="zone-div-report"
                  style={{ marginBottom: "10px" }}
                >
                  Device Type
                </div>
                <FormControl>
                  <Select
                    style={{
                      fontFamily: "Hyundai Sans Head Office regular",
                      textAlign: "center",
                      fontSize: "15.711px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "normal",
                      backgroundColor: "white",
                      height: "40px",
                      overflow: "hidden", // Hide overflow
                      textOverflow: "ellipsis", // Truncate text with ellipsis
                      whiteSpace: "nowrap", // Prevent wrapping
                      width: "200px", // Set fixed width
                    }}
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    value={selectedContent}
                    onChange={handleContentChange}
                    renderValue={(selected) => {
                      if (contentareAllOptionsSelected()) {
                        return "Select All";
                      } else {
                        console.log("line 1104", selected);
                        let newSelected = selected.map((ele) => {
                          if (ele === "Kiosk") {
                            return "Digital Kiosk";
                          } else return ele;
                        });
                        return newSelected.join(", ");
                      }
                    }}
                  >
                    {allContentnames.map((regionName) => (
                      <MenuItem key={regionName} value={regionName}>
                        <Checkbox
                          checked={
                            selectedContent.includes(regionName) ||
                            (regionName === "Select All" &&
                              contentareAllOptionsSelected())
                          }
                        />
                        <ListItemText
                          primary={
                            regionName === "Kiosk"
                              ? "Digital Kiosk"
                              : regionName
                          }
                        />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              {/* ---------virtual kiosk */}
              {/* ----------------zone div -------- */}
              <div className="zone-flex-container">
                <div className="zone-div" style={{ marginBottom: "10px" }}>
                  Zone
                </div>
                <FormControl>
                  <Select
                    style={{
                      fontFamily: "Hyundai Sans Head Office regular",
                      textAlign: "center",
                      fontSize: "15.711px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "normal",
                      backgroundColor: "white",
                      height: "40px",
                      overflow: "hidden", // Hide overflow
                      textOverflow: "ellipsis", // Truncate text with ellipsis
                      whiteSpace: "nowrap", // Prevent wrapping
                      width: "200px", // Set fixed width
                    }}
                    multiple
                    value={selectedRegions}
                    onChange={handleRegionChanges}
                    MenuProps={{
                      MenuListProps: {
                        autoFocusItem: false,
                        ref: menuRef,
                      },
                      PaperProps: {
                        style: {
                          maxHeight: 300,
                          overflowY: "auto",
                        },
                      },
                    }}
                    renderValue={(selected) => {
                      if (
                        selected.length === 1 &&
                        selected[0] !== "select-all"
                      ) {
                        return selected[0]; // Render the single selected region name
                      } else if (selected.includes("select-all")) {
                        return "Select All";
                      } else if (
                        selected.length === allregionnames.length &&
                        allregionnames.length > 1
                      ) {
                        return "Select All";
                      } else {
                        return selected.join(", ");
                      }
                    }}
                  >
                    {allregionnames.length > 1 && (
                      <MenuItem key="select-all" value="select-all">
                        <Checkbox
                          checked={
                            selectedRegions.length === allregionnames.length
                          }
                        />
                        <ListItemText primary="Select All" />
                      </MenuItem>
                    )}

                    {allregionnames.map((regionName) => (
                      <MenuItem key={regionName} value={regionName}>
                        <Checkbox
                          checked={selectedRegions.includes(regionName)}
                        />
                        <ListItemText primary={regionName} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              {/* -------------------------------------------------------------------------------- */}
              {showZoneSelect &&
                (selectedRegions.length ||
                  selectedRegions.includes("select-all") ||
                  selectedRegions?.length === allregionnames.length) &&
                selectedRegions.length > 0 &&
                allregionZoneData.length > 0 && (
                  <div className="zone-flex-container">
                    <div className="zone-div" style={{ marginBottom: "10px" }}>
                      Region
                    </div>
                    {/* // Render zone select only if showZoneSelect is true */}
                    <FormControl>
                      <Select
                        multiple
                        value={selectedZone}
                        onChange={handleZoneChanges}
                        MenuProps={{
                          MenuListProps: {
                            autoFocusItem: false,
                            ref: menuRef,
                          },
                          PaperProps: {
                            style: {
                              maxHeight: 300,
                              overflowY: "auto",
                            },
                          },
                        }}
                        renderValue={(selected) => {
                          console.log("line 634", selected);
                          // const allZones = allregionZoneData.flatMap(regionData =>
                          //   Object.values(regionData).flat()
                          // );
                          // const allSelected = selected.length === allZones.length;
                          // return allSelected ? "Select All" : selected.join(", ");
                          if (
                            selected.length === 1 &&
                            selected[0] !== "select-all"
                          ) {
                            return selected[0]; // Render the single selected region name
                          } else if (selected.includes("select-all")) {
                            return "Select All";
                          } else if (
                            selected.length >= allZones.length &&
                            allZones.length > 1
                          ) {
                            return "Select All";
                          } else {
                            return selected.join(", ");
                          }
                        }}
                        style={{
                          fontFamily: "Hyundai Sans Head Office regular",
                          textAlign: "center",
                          fontSize: "15.711px",
                          fontStyle: "normal",
                          fontWeight: 400,
                          lineHeight: "normal",
                          backgroundColor: "white",
                          height: "40px",
                          overflow: "hidden", // Hide overflow
                          textOverflow: "ellipsis", // Truncate text with ellipsis
                          whiteSpace: "nowrap", // Prevent wrapping
                          width: "200px", // Set fixed width
                        }}
                      >
                        {allZones?.length > 1 && (
                          <MenuItem key="select-all" value="select-all">
                            <Checkbox
                              checked={selectedZone.length >= allZones?.length}
                            />
                            <ListItemText primary="Select All" />
                          </MenuItem>
                        )}
                        {/* {allregionZoneData.map((regionData) =>
                  Object.keys(regionData).map((regionName) =>
                    selectedRegions.includes(regionName) && (
                      regionData[regionName].map((zone) => (
                        <MenuItem key={zone} value={zone}>
                          <Checkbox checked={selectedZone.includes(zone)} />
                          <ListItemText primary={zone} />
                        </MenuItem>
                      ))
                    )
                  )
                )} */}
                        {allZones?.map((zone) => (
                          <MenuItem key={zone} value={zone}>
                            <Checkbox checked={selectedZone.includes(zone)} />
                            <ListItemText primary={zone} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                )}

              <div className="date-range-text-fexbox-container">
                <div className="zone-div" style={{ marginBottom: "10px" }}>
                  Date Range
                </div>

                <RangePicker
                  style={{ width: "100%", height: "40px" }}
                  value={[startDate, endDate]}
                  onChange={handleDateChange}
                  format="DD-MM-YYYY" // Set the format to display
                  onFocus={(e) => {
                    e.preventDefault();
                    setStartDate();
                    setEndDate();
                  }}
                  disabledDate={(currentDate) => {
                    const today = moment().startOf("day");
                    const march01 = moment("2024-03-01", "YYYY-MM-DD").startOf(
                      "day"
                    );
                    return (
                      currentDate.isBefore(march01, "day") ||
                      currentDate.isAfter(today, "day")
                    );
                  }}
                />
              </div>

              <div className="date-range-text-fexbox-container">
                <div className="zone-div" style={{ marginBottom: "10px" }}>
                  Dealer Filter
                </div>
                <div style={{ width: "300px", margin: "0 auto" }}>
                  <Selects
                   styles={customStyles}
                    value={selectedOption}
                    onChange={handleChange}
                    options={dealerCodes}
                    isSearchable
                    placeholder="Select a dealer code"
                    isClearable
                  />
                </div>
              </div>

              {startDate && endDate && showapplybtn && (
                <div className="zone-flex-container">
                  <div
                    className="zone-div"
                    style={{ marginBottom: "10px", visibility: "hidden" }}
                  >
                    Button
                  </div>
                  {applyClicked ? (
                    <button
                      onClick={handleClearFilters}
                      className={`fetch-detailsbtntwo`}
                    >
                      Clear
                    </button>
                  ) : (
                    <button
                      onClick={handleFetchdetails}
                      className={`fetch-detailsbtn ${
                        fetchingDetails ? "fade-out" : "fade-in"
                      }`}
                    >
                      Apply
                    </button>
                  )}
                </div>
              )}

              {/* --- end of region div div */}

              {/* ----------------activity status---------------------- */}

              {/* ----------content update---- */}

              {/* ------------------app update------------ */}

              {/* --------------date picker-------- */}
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="zone-flex-container">
            {/* <div className='zone-div' style={{marginBottom:"10px",visibility:"hidden"}}>Date Range</div> */}

            <div className="date-range-text-fexbox-containers">
              <div
                className="zone-divs"
                style={{
                  marginBottom: "10px",
                  marginTop: "10px",
                  marginLeft: "10px",
                  marginRight: "10px",
                }}
              >
                Date Range
              </div>

              <div style={{ marginLeft: "10px", marginRight: "10px" }}>
                <RangePicker
                  style={{ width: "100%", height: "40px" }}
                  value={[startDate, endDate]}
                  onChange={handleDateChange}
                  format="DD-MM-YYYY" // Set the format to display
                  onFocus={(e) => {
                    e.preventDefault();
                    setStartDate();
                    setEndDate();
                  }}
                  disabledDate={(currentDate) => {
                    const today = moment().startOf("day");
                    const march31 = moment("2024-03-31", "YYYY-MM-DD").startOf(
                      "day"
                    );
                    return (
                      currentDate.isBefore(march31, "day") ||
                      currentDate.isAfter(today, "day")
                    );
                  }}
                />
              </div>
            </div>

            <div className="filters-div" onClick={handleFilterClicks}>
              <div>
                {" "}
                <FilterListIcon />
              </div>
              <div> Filter</div>
            </div>
          </div>

          {/* add fetchdetaile to show  apply bth           */}
         

          {startDate && endDate && showapplybtn && (
                <div >
                  <div
                    className="zone-div"
                    style={{ marginBottom: "10px", visibility: "hidden" }}
                  >
                    Button
                  </div>
                  {applyClicked ? (
                    <div
                      onClick={handleClearFilters}
                      className={`fetch-detailsbtnmobile ${
                        fetchingDetails ? "fade-in" : "fade-in"
                      }`}
                    >
                      Clear
                    </div>
                  ) : (
                    <div
                      onClick={handleFetchdetails}
                      className={`fetch-detailsbtnmobile ${
                        fetchingDetails ? "fade-in" : "fade-in"
                      }`}
                    >
                      Apply
                    </div>
                  )}
                </div>
              )}



          {/* Filter Dialog */}
          <Dialog
            open={isFilterOpen}
            onClose={handleCloseFilter}
            className="mui-filter-modaal"
            PaperProps={{
              style: {
                backgroundColor: "#ffffff",
                boxShadow: "none",

                paddingBottom: "30px",

                width: "calc(100vw )", // Adjust the width by subtracting the desired left and right spacing
                // marginLeft: "10px", // Left spacing
                // marginRight: "10px", // Right spacing

                WebkitOverflowScrolling: "touch", // Enable smooth scrolling in iOS
                scrollbarWidth: "none", // Hide scrollbar for Firefox
                msOverflowStyle: "none", // Hide scrollbar for IE and Edge
                "&::-webkit-scrollbar": {
                  display: "none", // Hide scrollbar for WebKit browsers
                },
              },
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                paddingTop: "10px",
                paddingBottom: "40px",
                paddingLeft: "10px",
                paddingRight: "10px",
                position: "fixed",
                // top: 0,
                left: "10px",
                right: "10px",

                border: "1px solid grey",
                borderTop: "none",
                borderLeft: "none",
                borderRight: "none",
                backgroundColor: "#FFFFFF",
                borderTopRightRadius: "20px",
                borderTopLeftRadius: "20px",
                width: "100%",

                height: "40px",
                zIndex: "999",
              }}
              className="device-status-full-contain-sections"
            >
              <div style={{ marginTop: "10px" }}> Change Filters</div>
              <div
                onClick={handleCloseFilter}
                style={{ textAlign: "right" }}
                className="close-btns"
              >
                <div style={{ width: "35px", height: "35px" }}>
                  <img
                    src={closeIcon}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                    }}
                  />
                </div>
              </div>{" "}
              {/* Add onClick handler */}
            </div>

            <div
              className="device-status-full-contain-section"
              style={{ marginTop: "60px" }}
            >
              <div className="hyundai-content-sections">
                {/* ---------------------------------------------------------- */}
                <Accordion
                  expanded={accordionExpandeds}
                  onChange={handleDeviceAccordianChange}
                  className={`accordian-container ${
                    accordionExpandeds ? "expanded" : ""
                  }`}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    className={`accordian-summary ${
                      accordionExpandeds ? "expanded" : ""
                    }`}
                  >
                    <Typography>Device Type</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      {/* Render device type options */}
                      {allContentnames.map((regionName) => (
                        <div
                          key={regionName}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            paddingTop: "5px",
                          }}
                        >
                          <Checkbox
                            checked={
                              selectedContent.includes(regionName) ||
                              (regionName === "Select All" &&
                                contentareAllOptionsSelected())
                            }
                            onChange={(event) => handleContentChanges(event)} // Pass the event object
                            value={regionName}
                          />
                          {/* <div>{regionName}</div> */}
                          <div>
                            {regionName === "Kiosk"
                              ? "Digital Kiosk"
                              : regionName}
                          </div>
                        </div>
                      ))}
                    </div>
                  </AccordionDetails>
                </Accordion>

                <Accordion
                  expanded={ZoneAccordianExpanded}
                  onChange={handlezoneAccordianChange}
                  className={`accordian-container ${
                    ZoneAccordianExpanded ? "expanded" : ""
                  }`}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="zone-panel-content"
                    id="zone-panel-header"
                    className={`accordian-summary ${
                      ZoneAccordianExpanded ? "expanded" : ""
                    }`}
                  >
                    <Typography>Zone</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        overflowY: "scroll",
                        minHeight: "10px",
                      }}
                      className="heightprop-check"
                    >
                      {/* Conditionally render "Select All" checkbox */}
                      {allregionnames.length > 1 && (
                        <MenuItem key="select-all" value="select-all">
                          <Checkbox
                            checked={
                              selectedRegions.length === allregionnames.length
                            }
                            onChange={(event) =>
                              handleRegionChange(event, "select-all")
                            }
                            value="select-all"
                          />
                          <ListItemText primary="Select All" />
                        </MenuItem>
                      )}
                      {/* Individual region checkboxes */}
                      {allregionnames.map((regionName) => (
                        <MenuItem key={regionName} value={regionName}>
                          <Checkbox
                            checked={selectedRegions.includes(regionName)}
                            onChange={(event) =>
                              handleRegionChange(event, regionName)
                            }
                            value={regionName}
                          />
                          <ListItemText primary={regionName} />
                        </MenuItem>
                      ))}
                    </div>
                  </AccordionDetails>
                </Accordion>

                <Accordion
                  expanded={regionAccordionExpanded}
                  onChange={toggleRegionAccordion}
                  className={`accordian-container ${
                    regionAccordionExpanded ? "expanded" : ""
                  }`}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="region-panel-content"
                    id="region-panel-header"
                    className={`accordian-summary ${
                      regionAccordionExpanded ? "expanded" : ""
                    }`}
                  >
                    <Typography>Region</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="zone-flex-container">
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        {/* Conditionally render "Select All" checkbox */}
                        {Array.isArray(allZones) && allZones.length > 1 && (
                          <MenuItem key="select-all" value="select-all">
                            <Checkbox
                              checked={
                                Array.isArray(selectedZone) &&
                                selectedZone.length >= allZones.length
                              }
                              onChange={handleZoneChange}
                              value="select-all"
                            />
                            <ListItemText primary="Select All" />
                          </MenuItem>
                        )}
                        {allZones &&
                          allZones.map((zone) => (
                            <MenuItem key={zone} value={zone}>
                              <Checkbox
                                checked={selectedZone.includes(zone)}
                                onChange={(event) =>
                                  handleZoneChange(event, zone)
                                }
                                value={zone}
                              />
                              <ListItemText primary={zone} />
                            </MenuItem>
                          ))}
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>


                
                <div className="date-range-text-fexbox-container">
                {/* <div className="zone-div" style={{ marginBottom: "10px" }}>
                  Dealer Filter
                </div> */}
                <div style={{ width: '100%',height:"40px",margin: '0 auto'}}>
                  <Selects
                    value={selectedOption}
                    onChange={handleChange}
                    options={dealerCodes}
                    isSearchable
                    placeholder="Select a dealer code"
                    isClearable
                  />
                </div>
              </div>

                {/* -------------------------------------------------------------- */}

                {/* ------------------------------------------------------------------- */}

                {/* -------------------------------------------------------------------------------- */}

                {/* ------------------------------------------------------------------------------------------- */}

                {/* date range  */}
              </div>
              {/* --------------------------------------------------------- */}
            </div>
            <DialogContent></DialogContent>
          </Dialog>

          {/* --2nd accordian-- */}
        </>
      )}

      {/*-------- fetch deatials start------- */}

      {/* ----fetch details */}

      {/* ---end of fetch details--- */}

      {/* --------fetch detaaials end-------- */}

      {/* seaarch */}

      <div>
        <div className="seaarch-report-flexbox-container">
          {/* ------search ------ */}

          {/* ---download---- */}
          {isMobile ? (
            <div className="download-device-sections" onClick={DownloadReport}>
              <FileDownloadOutlinedIcon
                className="download-status-reporticon"
                style={{
                  width: "25px",
                  height: "25px",
                  margin: "auto",
                  display: "block",
                  textAlign: "center",
                  justifyContent: "center",
                  justifyItems: "center",
                  alignContent: "center",
                }}
              />
            </div>
          ) : (
            <div className="">{/* download */}</div>
          )}
        </div>
        <div className="download-report-mobile-container">
          {/* <div className='download-report-divs'  >

Download report
<span className='downloadd-icon'><FileDownloadOutlinedIcon  /></span>
</div> */}

          <div className="download-report-divs">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "2px",
                justifyContent: "center",
                alignItems: "Center",
              }}
              onClick={DownloadReport}
            >
              <div className="download-report-div">Download report</div>
              <div>
                <FileDownloadOutlinedIcon />
              </div>
            </div>
          </div>
        </div>
        {/* -------start of table------------- */}



        <div className="dealercode-table-section">
          

        <div >
      <TableContainer component={Paper} ref={tableContainerRef} style={{ overflowX: "auto" }}>
        <Table>
          <TableHead>
            <TableRow style={{ backgroundColor: "#003984", color: "#FFFFFF"}}>
              <TableCell className="dealer-sect">Zone</TableCell>
              <TableCell className="dealer-sect">Region</TableCell>
              <TableCell className="dealer-sect">City</TableCell>
              <TableCell className="dealer-sect">Dealer Code</TableCell>
              <TableCell className="dealer-sect">Dealer Name</TableCell>
              <TableCell className="dealer-sect">Top Model</TableCell>
              <TableCell className="dealer-sect">Bottom Model</TableCell>
              <TableCell className="dealer-sect">Top Section</TableCell>
              <TableCell className="dealer-sect">Bottom Section</TableCell>
              {deviceTypes.map((device) => (
                <React.Fragment key={device}>
                  <TableCell className="dealer-sect">{`${device} Content Update`}</TableCell>
                  <TableCell className="dealer-sect">{`${device} Activity Status`}</TableCell>
                </React.Fragment>
              ))}
            </TableRow>
          </TableHead>
          {processedTableData?.filter((row) =>
            selectedOption === null
              ? newZones.includes(row.zone)
              : row.dealer_code === String(selectedOption?.value)
          ).length === 0 && (
            <div style={{ width: "max-content", padding: '10px' }}>No data found</div>
          )}
          <TableBody>
            {Array.isArray(processedTableData) &&
              processedTableData.filter((row) =>
                selectedOption === null
                  ? newZones.includes(row.zone)
                  : row.dealer_code === String(selectedOption?.value)
              ).map((row) => (
                <TableRow key={row.dealer_code}>
                  <TableCell className="row-device-sec" style={{ cursor: "auto" }}>
                    {row.zone}
                  </TableCell>
                  <TableCell className="row-device-sec" style={{ cursor: "auto" }}>
                    {row.region}
                  </TableCell>
                  <TableCell className="row-device-sec" style={{ cursor: "auto" }}>
                    {row.city}
                  </TableCell>
                  <TableCell className="row-device-sec" style={{ cursor: "auto" }}>
                    {row.dealer_code}
                  </TableCell>
                  <TableCell className="row-device-sec" style={{ cursor: "auto" }}>
                    {row.dealer_name}
                  </TableCell>
                  <TableCell className="row-device-sec" style={{ cursor: "auto" }}>
                    {row.top_product}
                  </TableCell>
                  <TableCell className="row-device-sec" style={{ cursor: "auto" }}>
                    {row.bottom_product}
                  </TableCell>
                  <TableCell className="row-device-sec" style={{ cursor: "auto" }}>
                    {row.top_section}
                  </TableCell>
                  <TableCell className="row-device-sec" style={{ cursor: "auto" }}>
                    {row.bottom_section}
                  </TableCell>
                  {deviceTypes.map((device) => (
                    <React.Fragment key={device}>
                      <TableCell
                        className="row-device-sec"
                        style={{
                          cursor: "auto",
                          color: row.deviceDataMap[device]?.content_update_status === "updated"
                            ? "#40F400"
                            : row.deviceDataMap[device]?.content_update_status === "pending"
                              ? "#A81E1E"
                              : row.deviceDataMap[device]?.content_update_status === "NA"
                                ? "#000000"
                                : "#000000",
                        }}
                      >
                        {row.deviceDataMap[device]?.content_update_status}
                      </TableCell>
                      <TableCell
                        className="row-device-sec"
                        style={{
                          cursor: "auto",
                          color: row.deviceDataMap[device]?.activity_status === "online"
                            ? "#000000"
                            : row.deviceDataMap[device]?.activity_status === "offline"
                              ? "#000000"
                              : row.deviceDataMap[device]?.activity_status === "NA"
                                ? "#000000"
                                : "#000000",
                        }}
                      >
                        <div style={{ display: "flex", flexDirection: "row", gap: "5px", justifyContent: "center", alignContent: "center", alignItems: "center", textAlign: "center" }}>
                          <div>
                            {row.deviceDataMap[device]?.activity_status}
                          </div>
                          <div
                            className="circle"
                            style={{
                              backgroundColor: row.deviceDataMap[device]?.activity_status === "online"
                                ? "#40F400"
                                : row.deviceDataMap[device]?.activity_status === "offline"
                                  ? "red"
                                  : "white",
                            }}
                          ></div>
                        </div>
                      </TableCell>
                    </React.Fragment>
                  ))}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>


      

    
          
      <div style={{ position: "fixed", top: "80%", left: "10px", transform: "translateY(-50%)" }} className="side-arrows">
        <div onClick={() => scrollTable("left")} style={{border:"1px solid #003984",backgroundColor:"#003984",justifyContent:"center",textAlign:"center",alignContent:"center",alignItems:"center",borderRadius:"5px",cursor:"pointer",width:"100%",padding:"5px",color:"white"}}>
          &lt;
        </div>
      </div>
      <div style={{ position: "fixed", top: "80%", right: "10px", transform: "translateY(-50%)" }} className="side-arrows">
        <div  onClick={() => scrollTable("right")} style={{border:"1px solid #003984",backgroundColor:"#003984",justifyContent:"center",textAlign:"center",alignContent:"center",alignItems:"center",borderRadius:"5px",cursor:"pointer",width:"100%",padding:"5px",color:"white"}}>
          &gt;
        </div>
      </div>
    
       


    </div>


          {processedTableData?.filter((row) =>
                  selectedOption === null
                    ? newZones.includes(row.zone)
                    : row.dealer_code === String(selectedOption?.value)
                ).length > 0 && !dealerSelect && (
            <div className="paginattion-flex-device-status-report" style={{display:"flex",flexDirection:"row",gap:"5px",justifyContent:"right",marginTop:"10px",alignContent:"center",textAlign:"center",alignItems:"center"}}>
              <div>
                <Pagination
                  count={Math.ceil(totalRecords / recordsPerPage)}
                  page={currentPage}
                  onChange={handlePageChange}
                />
              </div>

              <div>
                page {currentPage} of {totalPages}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default UsageReport;
